html {
  font-size: $base-font-size;
  height: 100% !important;
  background: $page-background;
  overflow-x: hidden;
  -webkit-font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;

  * {
    font-family: $font-OS;
    outline: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }
}

@-ms-viewport {
  width: device-width;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  position: relative;
  min-height: 100%;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

body.Patterns {
  background-image: url(./../../images/background.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: contain;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

b {
  font-weight: 500;
}

.App {
  display: block;
  max-width: $page-width;
  margin: 0 auto;
  height: auto;
  min-height: 100vh;
  position: relative;

  .Main {
    display: flex;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .Frame {
      width: 100%;
      height: auto;
      background: white;
      min-height: calc(100vh - 40px);
      padding-top: 24px;

      .Page {
        width: 100%;
        min-height: 300px;
        padding: 0 24px 36px 24px;

        .Content {
          // padding-top: 12px;
          position: relative;
          .Block {
            padding: 24px 12px 0 12px;
          }
        }
      }
    }

    .Footer {
      height: 40px;
      width: 100%;
      background: white;
    }
  }
}

.Image {
  width: 96px;
  height: 96px;
  border-radius: 100%;
  margin: 60px 0 16px 0;
  @include css3-prefix("box-shadow", "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)");
}

select {
  background: white;
  border: 1px solid $light-gray;
  padding: 8px 12px;
  border-radius: 4px;
  color: $paragraphColor;
  @include font-size(14);
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.Time_Tip {
  background: #f9f9f9;
  border: 1px solid $light-gray;
  border-radius: 4px;
  padding: 2px 4px;
  position: absolute;
  top: 44px;
  left: 0px;
  width: 210px;
  text-align: center;

  p {
    margin: 0;
    font-size: 12px !important;
  }
}

.Command {
  font-family: monospace;
  color: rgb(204, 12, 76);
  margin-right: 4px;
}

.Popover {
  position: absolute;
  will-change: transform;
  width: 138px;
  @include css3-prefix("transform", scale(0));
  @include css3-prefix("transition", "all .3s cubic-bezier(0.4,0.0,0.2,1)");
  opacity: 0;
  transform-origin: top right;
  height: auto;
  background: rgba(18, 18, 18, 0.95);
  padding: 0;
  top: 48px;
  border-radius: 6px;
  display: block;
  border: 1px solid $light-gray;
  right: 0;
  @include css3-prefix("box-shadow", "0 0.125rem 0.625rem 0 rgba(18,18,18,.15)");
  z-index: 1;
  padding: 12px 0;

  .Popover__Item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    padding: 2px 16px;
    cursor: pointer;
    @include css3-prefix("user-select", "none");
    .Popover__Item__Text {
      @include font-size(14);
      color: $popover-gray;
      line-height: 1.9;
      width: 100%;
      text-decoration: none;
      margin: 0;
    }

    .Nav--Selected {
      font-weight: 500;
      color: white;
    }

    &:hover {
      background-color: hsla(0, 0%, 100%, 0.15);
    }
  }
}

.Popover.MessageMenu {
  width: 160px;
}

.DateTime__Container {
  position: relative;
  display: flex;
  @include text-truncate;
  flex-wrap: wrap;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-wrap: wrap;
  }
}

.InformationNotice {
  padding: 8px 8px 8px 36px;
  @include font-size(12);
  position: relative;
  line-height: 18px;

  svg {
    position: absolute;
    left: 10px;
    top: 8px;
  }
}

.Info {
  margin-top: 16px;
  padding: 8px 12px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 6px;
  background-color: $light-white;
  border: 1px solid $light-gray;
  border-left: 4px solid $dark-green !important;
  align-items: center;
  @include css3-prefix("box-shadow", "0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);");

  .ActionLink {
    text-decoration: none;
    cursor: pointer;
    line-height: 28px;
    border-radius: 4px;
    user-select: none;
    font-weight: 500;
    border: 1px solid $dark-green;
    padding: 4px 8px;
    @include css3-prefix("transition", "all 0.2s ease-in");

    &:hover,
    &:focus {
      background: $dark-green;
      color: $headingColor;
      border-color: #26ad7d;
    }
  }
  div {
    width: 100%;
  }
  .Info__Desc {
    p {
      @include font-size(13);
      margin: 0;
      &:not(.NoTextTruncate) {
        @include text-truncate;
      }
    }
    .SlackCommand {
      @include font-size(13);
    }
    .TextLoop {
      pointer-events: none;
      user-select: none;
    }
  }
  .Info__Actions {
    margin-left: auto;
  }
}

.Info--Error{
  margin: 0;
  margin-bottom: 16px;
  border-left-color: $errorColor !important;
}

q {
  @include font-size(22);
  padding: 4px;
  font-family: auto;
}

body::-webkit-scrollbar {
  width: 8px;
}

strong {
  font-weight: 500;
}

/* Track */
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(212, 212, 212);
  -webkit-box-shadow: inset 0 0 6px rgba(22, 22, 22, 0.5);
}

body::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(221, 221, 221);
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: $primaryBackgroundColor;
}

::selection {
  color: white;
  background: $primaryBackgroundColor;
}

.TabsPanel {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
  justify-content: center;

  &:before {
    border-top: 1px solid #e4e4e4;
    position: absolute;
    left: 0;
    z-index: 0;
    content: "";
    width: 100%;
    display: block;
  }

  .TabsPanel__Inner {
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .Tab {
      display: block;
      background: #f9f9f9;
      color: #586069;
      @include font-size(14);
      border: 1px solid #e4e4e4;
      border-left: none;
      padding: 6px 14px;
      margin: 0;
      cursor: pointer;
      user-select: none;

      &:hover,
      &:focus {
        background: white;
        border-color: rgb(225, 228, 232);
        color: rgb(88, 96, 105);
      }
    }

    .Tab__First {
      border-left: 1px solid #e4e4e4;
      border-top-left-radius: 160px;
      border-bottom-left-radius: 160px;
    }

    .Tab__Last {
      border-top-right-radius: 160px;
      border-bottom-right-radius: 160px;
    }

    .Tab.Selected {
      background: rgb(5, 29, 57);
      border-color: rgb(5, 29, 57);
      color: white;

      &:hover,
      &:focus {
        background: rgb(5, 29, 57);
        border-color: rgb(5, 29, 57);
        color: white;
      }
    }
  }
}

ul.Ticks {
  margin: 8px 0;
  padding: 0;
  li {
    @include font-size(14);
    list-style: none;
    color: $paragraphColor;
    margin: 14px 0;
    position: relative;
    padding-left: 24px;
    &:before {
      content: "✔";
      position: absolute;
      color: $primaryBackgroundColor;
      margin-right: 10px;
      left: 0px;
      top: 0;
      background: rgba(90, 68, 255, 0.07);
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      border-radius: 100%;
    }
  }
}

.Form {
  display: block;
  padding: 12px 0;
  width: 100%;

  .FormGroup {
    display: flex;
    flex-direction: column;
    .FormGroup__Label {
      width: 100%;
      display: block;
      text-align: left;
      @include font-size(14);
      font-weight: 500;
      margin: 0;
      margin: 4px 0 8px;
    }
    .FormGroup__Input {
      width: 100%;
      resize: none;
      min-height: 100px;
      border: 1px solid $light-gray;
      border-radius: 4px;
      padding: 8px;
      @include font-size(14);
    }
  }

  .SettingsSectionBlock {
    margin-bottom: 30px;
    .SettingsSubTitle {
      margin-top: 0;
      @include font-size(16);
    }
    label{
      font-size: 14px !important;
    }
  }

  h5{
    color: #545454;
    @include font-size(14);
    margin: 12px 0;
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #f5f5f5;
  margin: 16px 0;
  padding: 0;
}

.FullScreenProgressBar{
  position: fixed !important;
  top: 0 !important;
  margin: 0;
  z-index: 10;
  .Progress{
    margin: 0;
  }
}

@import "../_media-queries/_general";
