.Margin-Right--12 {
  margin-right: 12px !important;
}

.Margin-Bottom--12 {
  margin-bottom: 12px !important;
}

.Margin-Left--12 {
  margin-left: 12px !important;
}

.Margin-Top--12 {
  margin-top: 12px !important;
}

.Margin-Right--8 {
  margin-right: 8px !important;
}

.Margin-Bottom--8 {
  margin-bottom: 8px !important;
}

.Margin-Left--8 {
  margin-left: 8px !important;
}

.Margin-Top--8 {
  margin-top: 8px !important;
}

.Margin-Right--4 {
  margin-right: 4px !important;
}

.Margin-Bottom--4 {
  margin-bottom: 4px !important;
}

.Margin-Left--4 {
  margin-left: 4px !important;
}

.Margin-Top--4 {
  margin-top: 4px !important;
}

.Margin-Right--24 {
  margin-right: 24px !important;
}

.Margin-Bottom--24 {
  margin-bottom: 24px !important;
}

.Margin-Left--24 {
  margin-left: 24px !important;
}

.Margin-Top--24 {
  margin-top: 24px !important;
}

.Color--Red {
  color: $errorColor !important;
}

.Capitalize {
  text-transform: capitalize;
}

.Error {
  color: $errorColor !important;
  @include css3-prefix("animation-name", bounce);
  @include css3-prefix("animation-duration", 0.5s);
  @include css3-prefix("animation-delay", 0.25s);
}

.Paragraph--Normal {
  @include font-size(14);
  text-align: left;
  margin: 0;
}

.Font-Size--14{
  font-size: 1.4rem !important;
  font-size: 14px !important;
}

.Paragraph--Small {
  @include font-size(12);
  margin: 0;
}

.ProBadge {
  background: $proColor;
  border: 1px solid $orangeColor;
  padding: 1px 4px;
  border-radius: 16px;
  user-select: none;
}

.ProBadgeLight {
  color: $proColor;
  border: 1px solid $orangeColor;
  padding: 1px 4px;
  border-radius: 16px;
  user-select: none;
  @include font-size(10);
}

.Margin--0{
  margin: 0;
}

.Padding--0{
  padding: 0;
}

.Text-Align--Center {
  text-align: center;
}

.Z-Index--2 {
  z-index: 2 !important;
}

.Z-Index--0 {
  z-index: 0;
}

.Z-Index--5 {
  z-index: 5 !important;
}

.Vertical--Center {
  display: flex;
  align-items: center;
}

.Center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Light-Red {
  background-color: $light-red;
  &:hover {
    border: 1px solid $errorColor !important;
  }
}

.Light-Green {
  color: $light-green !important;
}

.Gradient--Purple {
  background: #9d50bb;
  background: -webkit-linear-gradient(to right, #6e48aa, #9d50bb);
  background: linear-gradient(to right, #6e48aa, #9d50bb);
}

.Border--Red {
  border: 1px solid $red;
}

.Gradient--Blue {
  background: $spinner;
}

.Tag {
  color: white;
  flex-wrap: wrap;
  width: auto;
  border-radius: 4px;
  padding: 4px 8px;
  @include font-size(12);
  svg {
    top: 4px;
    position: relative;
    margin-right: 6px;
  }
}

.Tag__Absolute {
  color: white;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  width: auto;
  border-radius: 4px;
  padding: 4px 8px;
  @include font-size(12);
  svg {
    position: relative;
    margin-right: 6px;
  }
}

.Inline--Link {
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.Link__Plain {
  text-decoration: none;
  color: initial;
  &:visited,
  &:focus {
    color: initial;
  }
}

.Line-Height--24 {
  line-height: 24px;
}

.Absolute {
  position: absolute;
  top: 0;
}

.Top--m12{
  top: -12px !important;
}

.Relative{
  position: relative;
}

.Font-Color--Red{
  color: $errorColor;
}

.Min__Height--40 {
  min-height: 40px !important;
}

.Min__Height--64 {
  min-height: 64px !important;
}

.Min__Width--88 {
  min-width: 88px !important;
}

.Underline {
  border-bottom: 1px dotted black;
}

.Grayscale {
  opacity: 0.4;
  @include css3-prefix("filter", grayscale(1));
}

.Disabled{
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
  @include css3-prefix("filter", grayscale(1));
}

.Grayscale--7 {
  opacity: 0.7;
  @include css3-prefix("filter", grayscale(1));
}

.Pill {
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  @include font-size(12);
  text-transform: capitalize;
  font-weight: 500;
}

.Margin--0 {
  margin: 0;
}

.Margin-Left--0{
  margin-left: 0 !important;
}

.Padding--0 {
  padding: 0 !important;
}

.PaddingLeft--0 {
  padding-left: 0 !important;
}

.PaddingLeft--24 {
  padding-left: 24px !important;
}

.PaddingLeft--36 {
  padding-left: 36px !important;
}

.PaddingTop--16 {
  padding-top: 16px !important;
}

.Left--0 {
  left: 0 !important;
}

.NoShadow {
  @include css3-prefix("box-shadow", "none !important");
}

.height--40 {
  height: 40px;
}

.Flex-Direction--Column {
  flex-direction: column;
}

.Color--Gray{
  color: $popover-gray;
}

.Underline{
  border-bottom: 1px dotted inherit;
}

.Button--Clear {
  user-select: none;
  @include css3-prefix("appearance", none);
  background: none;
  border: none;
  outline: none;
  height: auto;
}

.Pill--Green {
  border: 1px solid $dark-green;
  background-color: $lighter-green;
  color: black;
}

.Pill--Red {
  border: 1px solid $errorColor;
  background-color: $light-red;
  color: black;
}

.Pill--Orange {
  border: 1px solid $orangeColor;
  background-color: $light-orange;
  color: black;
}

.Font-Size--12{
  @include font-size(12);
}

.Tag--Success{
  border: 1px solid $darker-green;
  background-color: $dark-green;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 4px;
}

.Tag--Blank{
  border: 1px solid $spinner;
  background-color: white;
  color: $spinner;
  padding: 2px 4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 4px;
  @include css3-prefix("transition", "all 0.2s ease-in");
  &:hover, &:focus{
    border: 1px solid $spinner;
    background-color: $spinner;
    color: white;
  }
}

.Tag--Warning{
  border: 1px solid $darkerOrange;
  background-color: $darkOrange;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 4px;
}

.Tag--Danger{
  border: 1px solid $errorColor;
  background-color: $errorColor;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 4px;
}

.Display--Block {
  display: block;
}

.Flex-Align--Left {
  @include css3-prefix("align-self", "flex-start");
}

.SlashCommand {
  color: #cc0c4c;
  // font-family: monospace;
  background-color: #f9f9f9;
  border: 1px solid #e4e4e4;
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 4px;
  line-height: 2;
}

.Bold {
  font-weight: 500;
}

.Spinner--Small{
  width: 16px !important;
  height: 16px !important;
  border-width: 2px !important;  
  margin: 0;
}

.Center--Force{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ServerError {
  background-color: #fdfdfd;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  color: $paragraphColor;
  @include font-size(14);
  line-height: 2;

  .ErrorTitle {
      font-family: monospace;
      color: rgb(204, 12, 76);
      margin-right: 4px;
  }

}