.Footer {
    width: 100%;
    height: auto;    

    .Footer__Links{
      display: block;
      position: relative;     
      text-align: center;

      a{
        @include font-size(14);
        color: $secondaryForeColor;
        display: inline-block;
        text-decoration: none;
        user-select: none;
        cursor: pointer;
        line-height: 1.6;
        margin: 0 12px;
      
        &:focus, &:hover{
          text-decoration: underline;
        }
      }
     
    }
}

@import "../_media-queries/_footer";