.NoMessages {
  display: flex;
  width: 100%;
  padding: 24px;
  border: 1px solid $dark-green;
  border-radius: 8px;
  background: #eefff9;
  min-height: 200px;
  height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;

  .SendContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 12px;

    .SendContainer__Block {
      display: inline-block;
    }

    .Logo {
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      background-position: center;
      background-size: contain;
      background-image: url(../../images/logo_black_white_80.png);
    }

    .Avatar {
      width: 64px;
      height: 64px;
      border-radius: 8px;
      margin: 0 4px;
    }

    .ChannelName {
      @include font-size(20);
      @include text-truncate;
      max-width: 150px;
    }
  }

  .NoMessages__Image__Container {
    display: flex;
    flex: 1;
  }

  .NoMessages__Desc__Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .NoMessages__Logo {
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      background-size: contain;
      background-image: url(../../images/logo_black_white_80.png);
    }

    .NoMessages__Text {
      margin-top: 6px;
      text-align: center;
      max-width: 300px;
    }

    .NoMessages__Text.MaxWidth--100 {
      max-width: 100%;
    }

    .Text {
      text-align: center;
      @include font-size(16);
      width: 100%;

      span {
        @include font-size(16);
      }
    }
  }

  .NoMessages__Desc__Container.Left {
    align-items: flex-start;
  }
}

.NoMessages.Clear {
  border: none;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: white;
}

.NoMessages.Minimal {
  border: 1px solid $light-gray;
  background: $light-white;
}

.MessageContainer {
  padding: 0 2px;
  width: 100%;
  position: relative;

  .Message {
    display: flex;
    position: relative;
    flex-direction: row;
    background-color: $lighter-gray;
    border: 1px solid $light-gray;
    border-radius: 6px;
    width: 100%;
    @include css3-prefix("transition", "all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1)");
    padding: 4px;
    height: auto;
    margin-bottom: 10px;
    @include css3-prefix("box-shadow", "0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03)");

    &:last-child {
      margin-bottom: 0px;
    }

    &:hover,
    &:focus {
      background-color: #f3f3f3;

      .DeleteButton {
        display: block;
      }
    }

    .MessageProgressBar {
      width: 100%;
      position: absolute;
      top: -5px;
      left: 0;
    }

    .Message__Avatar {
      display: flex;
      flex-direction: column;
      padding: 16px 8px;

      &:empty {
        width: 48px;
        margin: 8px;
        height: 48px;
        background-color: rgb(223, 223, 223);
        border-radius: 8px;
        @include css3-prefix("animation", "fade 1000ms infinite");
      }

      .Message__Avatar--User {
        width: 48px;
        height: 48px;
        border-radius: 8px;
      }

      .Message__Avatar--Channel {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        @include font-size(32);
        color: $primaryForeColor;
        background: $headingColor;
      }
    }

    .Message__Body {
      flex: 1;
      padding: 16px 8px;

      .Message_To {
        color: $paragraphColor;
        text-align: left;
        @include font-size(14);
        font-weight: 500;
        margin-bottom: 6px;

        &:empty {
          border-radius: 16px;
          background-color: rgb(223, 223, 223);
          height: 12px;
          margin-top: 4px;
          width: 145px;
          @include css3-prefix("animation", "fade 1000ms infinite");
          @include css3-prefix("animation-delay", "200ms");
        }
      }

      .Message_Content {
        color: $paragraphColor;
        text-align: left;
        line-height: 1.5;
        word-break: break-word;
        @include font-size(14);
      }

      .MessageFileBlock {
        .FileBlock {
          width: 270px;
          margin-top: 10px;
          background: rgba(90, 68, 255, 0.06);
          border-color: rgba(189, 189, 189, 0.3);
        }
      }

      .Message_Attrs {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        padding-top: 10px;
        // border-top: 1px solid $separator-gray;

        .Message_Attrs--Section {
          max-width: 50%;
          width: 50%;

          &:first-child {
            padding: 0 16px 0 0;
          }

          &:last-child {
            padding: 0 0;
          }

          h5 {
            @include font-size(14);
            font-weight: 500;
            margin: 0px 0 6px;
            color: #444;
          }
          p {
            margin: 0 0 4px 0;
            // @include text-truncate;
            @include font-size(14);
          }
        }
      }

      .GroupIcon {
        display: inline-block;
        width: 30px;
        z-index: 1;
        height: 30px;
        margin-left: -8px;
        position: relative;
        @include css3-prefix("transition", "transform 0.1s ease-in");
        cursor: pointer;

        .GroupIcon__Avatar {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }

        .GroupIcon__Tooltip {
          display: none;
          position: absolute;
          bottom: -26px;
          left: -34px;
          width: 100px;
          font-weight: normal;
          text-align: center;
          color: $paragraphColor;
          background-color: rgba(255, 255, 255, 1);
          border-radius: 4px;
          @include font-size(10);
          @include text-truncate;
          @include css3-prefix("box-shadow", "0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03)");
          padding: 4px 8px;
        }

        &:first-child {
          margin-left: 0;
        }

        &:hover {
          .GroupIcon__Tooltip {
            display: block;
          }

          @include css3-prefix("transform", scale(1.2));
        }
      }

      .GroupIcon__More {
        padding-left: 8px;
        @include font-size(12);
      }

      .Message__Error__Notification{
        margin: 24px 0 8px 0;
        border-radius: 6px;
        border: 1px solid $light-gray;
        border-left: 4px solid $errorColor !important;
        @include font-size(10);
        @include css3-prefix("box-shadow", "0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03)");
        padding: 4px 8px;
      }

    }

    .Message__Paused {
      position: absolute;
      right: 40px;
      top: 13px;
      user-select: none;
      border: 1px solid $red;
      color: $red;
      svg {
        stroke: $red;
      }
    }

    .Message__Time {
      width: 100%;
      margin-top: 20px;
      @include font-size(13);
    }

    .OptionsButton {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-radius: 100%;
      @include css3-prefix("user-select", "none");
      top: 14px;
      padding: 2px;
      right: 12px;
      align-items: center;
      justify-content: center;
      @include css3-prefix("transition", "all 0.2s ease-in");
      cursor: pointer;

      svg {
        @include css3-prefix("user-select", "none");
      }

      .Popover {
        top: 24px;
      }

      &:hover,
      &:focus {
        background-color: rgb(224, 224, 224);
        .Popover {
          opacity: 1;
          @include css3-prefix("transform", scale(1));
        }
      }
    }
  }

  .Message.IsDeleting {
    @include css3-prefix("filter", grayscale(1));
    pointer-events: none !important;
  }

  .Message__Discard {
    height: 22px;
    width: 22px;
    position: absolute;
    right: -6px;
    top: -10px;
    min-width: 22px;
    stroke: $errorColor;
    fill: #fff;
    cursor: pointer;

    &:hover,
    &:focus {
      fill: $errorColor;
      stroke: white;
    }
  }
}

.Message_Content {
  p {
    @include font-size(15);
  }

  code {
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    @include font-size(12);
    line-height: 12px;
    white-space: normal;
    color: #e01e5a;
    padding: 2px 3px 1px;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    tab-size: 4;
    -moz-tab-size: 4;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
    -o-tab-size: 4;
    -webkit-tab-size: 4;
    border-radius: 3px;

    a {
      font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
      font-size: 12px !important;
      line-height: 12px !important;
      white-space: normal;
      text-decoration: none !important;
      color: #e01e5a !important;
      padding: 2px 3px 1px;
      -webkit-font-variant-ligatures: none;
      font-variant-ligatures: none;
      tab-size: 4;

      &:hover,
      &:visited {
        color: #e01e5a !important;
      }

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  pre {
    border-radius: 4px;
    @include font-size(12);
    line-height: 18px;
    white-space: pre-wrap;
    word-break: normal;
    margin: 8px 0 4px 0;
    user-select: text;
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: block;
    tab-size: 4;
    background: #f8f8f8;
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    padding: 8px;

    a {
      font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
      font-size: 12px !important;
      line-height: 12px !important;
      white-space: normal;
      text-decoration: none !important;
      color: rgb(51, 51, 51) !important;
      padding: 2px 3px 1px;
      -webkit-font-variant-ligatures: none;
      font-variant-ligatures: none;
      tab-size: 4;

      &:hover,
      &:visited {
        color: rgb(51, 51, 51) !important;
      }

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  blockquote {
    position: relative;
    margin: 0;
    padding: 0 0 0 16px;
    line-height: 22px;
    border: none;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 4px;
      background: #e3e4e6;
      content: "";
    }
  }

  .Link,
  .Channel {
    overflow-wrap: break-word;
    color: #08c;
    text-decoration: none;
    user-select: text;
    line-height: 22px;
    @include font-size(15);
    cursor: pointer;

    &:visited {
      color: #1264a3;
    }

    &:hover {
      color: #0b4c8c;
      text-decoration: underline;
    }
  }

  .User {
    background: #e8f5fa;
    color: #1264a3;
    padding: 0 2px 1px;
    border-radius: 3px;
    text-decoration: none;
    line-height: 22px;
    position: relative;
    @include font-size(15);
    cursor: pointer;

    &:hover {
      background: #ddf0f8;
      color: #0b4c8c;

      .UserNameTooltip {
        @include css3-prefix("transform", scale(1));
        opacity: 1;
      }
    }

    .UserNameTooltip {
      opacity: 0;
      @include css3-prefix("transform", scale(0));
      position: absolute;
      top: -44px;
      right: -50px;
      width: 160px;
      z-index: 2;
      font-weight: 500;
      height: 36px;
      text-align: center;
      color: $paragraphColor;
      background-color: rgba(0, 0, 0, 0.9);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      @include text-truncate;
      @include css3-prefix("box-shadow", "0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03)");
      @include css3-prefix("transition", "all 0.1s ease-in");
      padding: 8px 8px;

      img {
        width: 24px;
        height: 24px;
        min-width: 24px;
        border-radius: 8px;
        margin-right: 4px;
      }

      span {
        @include text-truncate;
        width: 180px;
      }
    }
  }

  .Markdown--Br {
    display: block;
    height: 2px;
  }

  .Emoji {
    display: inline-block;
  }

  b {
    font-weight: 500;
  }

  .SlackCommand {
    background: #fcf5de;
    font-weight: 500;
    padding: 0 2px 1px;
    line-height: 22px;
    @include font-size(15);
    color: #1d1c1d;
    border-radius: 3px;
  }
}

.IsUsersLoading {
  .User {
    visibility: hidden !important;
  }
}

.MessageComposerParent {
  display: block;
  width: 100%;

  .FileBlock {
    width: 100%;
    min-height: 40px;
    height: auto;
    display: flex;
    border: 1px solid #ccc;
    margin-bottom: 12px;
    border-radius: 4px;
    padding: 8px;

    .MessageProgressBlock {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;

      .MessageProgressBlock__Progress {
        width: 40px;
        height: 40px;
        color: $spinner;
        @include font-size(16);
        align-items: center;
        justify-content: center;
        display: flex;
        min-width: 40px;
      }

      .MessageProgressBlock__Desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 280px;
        margin-right: 6px;

        .MessageProgressBar {
          border-radius: 60px;
          overflow: hidden;
          margin: 6px 4px 6px 8px;

          span {
            display: block;
          }

          .Infinite {
            animation-name: alternator;
            animation-duration: 750ms;
            animation-direction: alternate;
            animation-iteration-count: infinite;
          }

          .MessageBar {
            background: rgba(0, 0, 0, 0.075);

            .MessageProgress {
              width: 0%;
              @include css3-prefix("transition", "all 0.5s ease-in-out");
              background: #75b800;
              color: #fff;
              padding: 4px;
            }
          }
        }

        .MessageProgressBlock__Desc--Title {
          @include font-size(14);
          color: $paragraphColor;
          font-weight: 500;
          @include text-truncate;
          margin: 0 4px 0px 8px;
          text-align: left;
        }

        .MessageProgressBlock__Desc--Message {
          @include font-size(14);
          color: $paragraphColor;
          margin: 4px 4px 4px 8px;
          text-align: left;
        }
      }

      .MessageProgressBlock__Close {
        height: 22px;
        width: 22px;
        min-width: 22px;
        margin-left: auto;
        stroke: $errorColor;
        fill: #fff;
        cursor: pointer;

        &:hover,
        &:focus {
          fill: $errorColor;
          stroke: white;
        }
      }
    }

    .MessageProgressBlock.MessageProgressBlock--Error {
      align-items: flex-start;
      .MessageProgressBlock__Desc--Title {
        margin: 8px 4px 0px 8px;
      }
      .Button {
        margin-left: 8px;
      }
    }

    .MessageCompletedBlock {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;

      .MessageCompletedBlock__Icon {
        width: 36px;
        height: 36px;
        min-width: 36px;
      }

      .MessageCompletedBlock__Desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 200px;
        width: 100%;

        .MessageCompletedBlock__Desc--Title {
          @include font-size(14);
          color: $paragraphColor;
          font-weight: 500;
          @include text-truncate;
          margin: 0 0px 0px 8px;
          text-align: left;
        }

        .MessageCompletedBlock__Desc--SubTitle {
          @include font-size(12);
          color: $paragraphColor;
          @include text-truncate;
          text-align: left;
          margin: 0 0px 0 8px;
        }
      }

      .MessageCompletedBlock__Close {
        height: 22px;
        width: 22px;
        min-width: 22px;
        margin-left: auto;
        stroke: $errorColor;
        fill: #fff;
        cursor: pointer;

        &:hover,
        &:focus {
          fill: $errorColor;
          stroke: white;
        }
      }
    }
  }

  .FileBlock--Error {
    border-color: $errorColor;
    background-color: $errorBackgroundColor !important;
  }

  .MessageComposer {
    position: relative;
    // z-index: 10;
    display: flex;
    flex-direction: column;
    width: 100%;

    &.IsFilesSupported {
      .ql-editor {
        padding-left: 38px;
      }
    }

    .UserSelect {
      position: absolute;
      height: auto;
      bottom: 100%;
      border-radius: 4px;
      left: 0;
      width: 100%;
      overflow: hidden;
      @include css3-prefix("box-shadow", "0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15)");

      .UserListDescription {
        @include font-size(12);
        color: $paragraphColor;
        padding: 4px 16px;
        background: rgb(235, 235, 235);
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .UserList {
        list-style: none;
        margin: 0;
        padding: 0%;
        width: 100%;
        max-height: 250px;
        overflow: auto;
        border: none;
        height: auto;

        .UserItem {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 6px 16px;
          background: white;
          cursor: pointer;
          border-bottom: 1px solid rgb(236, 236, 236);
          color: $paragraphColor;
          @include font-size(14);

          .MainItem {
            @include text-truncate;
            min-width: 0;
          }

          &:hover {
            background: $darklight-blue;
          }

          &:last-child {
            border-bottom: none;
          }

          img {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            margin-right: 12px;
          }

          .SecondaryLabel {
            color: rgb(182, 182, 182);
            padding-left: 12px;
            @include text-truncate;
            min-width: 0;
          }

          .AppTag {
            background: #f1f1f1;
            color: #908f8f;
            border-radius: 4px;
            font-size: 10px;
            padding: 2px 4px;
            margin-left: 4px;
            font-weight: 500;
          }
        }

        .UserItem.Selected {
          background-color: $spinner;
          color: white;

          .SecondaryLabel {
            color: rgb(230, 230, 230);
          }

          .AppTag {
            background: #7c6bf7;
            color: #c7c7c7;
            border-radius: 4px;
            font-size: 10px;
            padding: 2px 4px;
            margin-left: 4px;
            font-weight: 500;
          }
        }
      }
    }

    .FileSelect {
      position: absolute;
      height: auto;
      bottom: 100%;
      border-radius: 4px;
      left: 0;
      width: 100%;
      overflow: hidden;
      @include css3-prefix("box-shadow", "0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15)");

      .FileListDescription {
        @include font-size(12);
        color: $paragraphColor;
        padding: 4px 16px;
        background: rgb(235, 235, 235);
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .FileList {
        list-style: none;
        margin: 0;
        padding: 0%;
        width: 100%;
        max-height: 250px;
        overflow: auto;
        border: none;
        height: auto;

        .FileItem {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 6px 8px;
          position: relative;
          user-select: none;
          background: white;
          cursor: pointer;
          border-bottom: 1px solid rgb(236, 236, 236);
          color: $paragraphColor;
          @include font-size(14);
          overflow: hidden;

          svg {
            margin-right: 8px;
          }

          &:hover {
            background: $darklight-blue;
          }

          input[type="file"] {
            left: 0;
            top: 0;
            height: 32px;
            width: 100%;
            cursor: pointer;
            position: absolute;
            opacity: 0;
            user-select: none;
          }
        }
      }

      .Notice {
        background: $message-notice-background;
        padding: 8px 8px 8px 36px;
        @include font-size(11);
        position: relative;
        line-height: 14px;

        svg {
          position: absolute;
          left: 10px;
          top: 5px;
        }
      }
    }

    .ql-editor {
      padding-right: 38px;
    }

    .quill {
      width: 100%;
    }

    .MessageComposer__At {
      height: 100%;
      width: 38px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      right: 0;

      button {
        min-height: 40px;
        line-height: 22px;
        cursor: pointer;
        color: $hover-gray;
        @include css3-prefix("transition", all 0.1s ease-in);
        @include css3-prefix("transform-origin", bottom center);
        user-select: none;
        @include font-size(22);

        &:hover {
          color: $primaryBackgroundColor;
          @include css3-prefix("transform", scale(1.1));
        }
      }
    }

    .MessageComposer__Files {
      height: 100%;
      width: 38px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      left: 0;
      z-index: 1;

      button {
        min-height: 36px;
        cursor: pointer;
        color: $hover-gray;
        @include css3-prefix("transition", all 0.1s ease-in);
        @include css3-prefix("transform-origin", bottom center);
        user-select: none;

        svg {
          @include css3-prefix("transform", rotate(-45deg));
        }

        &:hover {
          svg {
            stroke: $primaryBackgroundColor;
          }

          @include css3-prefix("transform", scale(1.1));
        }
      }
    }
  }

  .MessageComposer__Help {
    display: block;
    pointer-events: none;
    user-select: none;
    @include font-size(11);
    width: 100%;
    text-align: right;
    color: #7c7c7c;

    strong {
      font-weight: 500;
    }

    line-height: 11px;
    margin: 8px 0;

    .Block {
      background-color: #f7f7f9;
      border: 1px solid #e1e1e8;
      border-radius: 2px;
      margin-right: 3px;
    }
  }
}

.PageProgressBar {
  position: absolute;
  top: -4px;
  width: 100%;
  left: 0;
}

@import "../_media-queries/_messages";
