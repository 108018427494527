@charset "UTF-8";
/* Authored Dependencies */
.Margin-Right--12 {
  margin-right: 12px !important; }

.Margin-Bottom--12 {
  margin-bottom: 12px !important; }

.Margin-Left--12 {
  margin-left: 12px !important; }

.Margin-Top--12 {
  margin-top: 12px !important; }

.Margin-Right--8 {
  margin-right: 8px !important; }

.Margin-Bottom--8 {
  margin-bottom: 8px !important; }

.Margin-Left--8 {
  margin-left: 8px !important; }

.Margin-Top--8 {
  margin-top: 8px !important; }

.Margin-Right--4 {
  margin-right: 4px !important; }

.Margin-Bottom--4 {
  margin-bottom: 4px !important; }

.Margin-Left--4 {
  margin-left: 4px !important; }

.Margin-Top--4 {
  margin-top: 4px !important; }

.Margin-Right--24 {
  margin-right: 24px !important; }

.Margin-Bottom--24 {
  margin-bottom: 24px !important; }

.Margin-Left--24 {
  margin-left: 24px !important; }

.Margin-Top--24 {
  margin-top: 24px !important; }

.Color--Red {
  color: #ed143d !important; }

.Capitalize {
  text-transform: capitalize; }

.Error {
  color: #ed143d !important;
  -webkit-animation-name: bounce;
  -khtml-animation-name: bounce;
  -moz-animation-name: bounce;
  -ms-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-duration: 0.5s;
  -khtml-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.25s;
  -khtml-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -ms-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s; }

.Paragraph--Normal, .Popup__Overlay .Popup__Container .ProPricingDetails p, .Subscription__Profile .Subscription__Profile_Details .Subscription__Profile_Details--Name {
  font-size: 14px;
  font-size: 1.4rem;
  text-align: left;
  margin: 0; }

.Font-Size--14 {
  font-size: 1.4rem !important;
  font-size: 14px !important; }

.Paragraph--Small {
  font-size: 12px;
  font-size: 1.2rem;
  margin: 0; }

.ProBadge {
  background: rgba(255, 214, 68, 0.9);
  border: 1px solid #fda73f;
  padding: 1px 4px;
  border-radius: 16px;
  user-select: none; }

.ProBadgeLight {
  color: rgba(255, 214, 68, 0.9);
  border: 1px solid #fda73f;
  padding: 1px 4px;
  border-radius: 16px;
  user-select: none;
  font-size: 10px;
  font-size: 1rem; }

.Margin--0 {
  margin: 0; }

.Padding--0 {
  padding: 0; }

.Text-Align--Center {
  text-align: center; }

.Z-Index--2 {
  z-index: 2 !important; }

.Z-Index--0 {
  z-index: 0; }

.Z-Index--5 {
  z-index: 5 !important; }

.Vertical--Center {
  display: flex;
  align-items: center; }

.Center {
  width: 100%;
  display: flex;
  justify-content: center; }

.Light-Red {
  background-color: #fff1f1; }
  .Light-Red:hover {
    border: 1px solid #ed143d !important; }

.Light-Green {
  color: #3ae2a6 !important; }

.Gradient--Purple {
  background: #9d50bb;
  background: -webkit-linear-gradient(to right, #6e48aa, #9d50bb);
  background: linear-gradient(to right, #6e48aa, #9d50bb); }

.Border--Red {
  border: 1px solid #ea384d; }

.Gradient--Blue {
  background: #5a44ff; }

.Tag {
  color: white;
  flex-wrap: wrap;
  width: auto;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  font-size: 1.2rem; }
  .Tag svg {
    top: 4px;
    position: relative;
    margin-right: 6px; }

.Tag__Absolute {
  color: white;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  width: auto;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  font-size: 1.2rem; }
  .Tag__Absolute svg {
    position: relative;
    margin-right: 6px; }

.Inline--Link {
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer; }
  .Inline--Link:hover {
    text-decoration: underline; }

.Link__Plain {
  text-decoration: none;
  color: initial; }
  .Link__Plain:visited, .Link__Plain:focus {
    color: initial; }

.Line-Height--24 {
  line-height: 24px; }

.Absolute {
  position: absolute;
  top: 0; }

.Top--m12 {
  top: -12px !important; }

.Relative {
  position: relative; }

.Font-Color--Red {
  color: #ed143d; }

.Min__Height--40 {
  min-height: 40px !important; }

.Min__Height--64 {
  min-height: 64px !important; }

.Min__Width--88 {
  min-width: 88px !important; }

.Underline {
  border-bottom: 1px dotted black; }

.Grayscale {
  opacity: 0.4;
  -webkit-filter: grayscale(1);
  -khtml-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
  filter: grayscale(1); }

.Disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
  -webkit-filter: grayscale(1);
  -khtml-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
  filter: grayscale(1); }

.Grayscale--7 {
  opacity: 0.7;
  -webkit-filter: grayscale(1);
  -khtml-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
  filter: grayscale(1); }

.Pill {
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: 500; }

.Margin--0 {
  margin: 0; }

.Margin-Left--0 {
  margin-left: 0 !important; }

.Padding--0 {
  padding: 0 !important; }

.PaddingLeft--0 {
  padding-left: 0 !important; }

.PaddingLeft--24 {
  padding-left: 24px !important; }

.PaddingLeft--36 {
  padding-left: 36px !important; }

.PaddingTop--16 {
  padding-top: 16px !important; }

.Left--0 {
  left: 0 !important; }

.NoShadow {
  -webkit-box-shadow: none !important;
  -khtml-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.height--40 {
  height: 40px; }

.Flex-Direction--Column {
  flex-direction: column; }

.Color--Gray {
  color: #888; }

.Underline {
  border-bottom: 1px dotted inherit; }

.Button--Clear {
  user-select: none;
  -webkit-appearance: none;
  -khtml-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  height: auto; }

.Pill--Green {
  border: 1px solid #3be2a7;
  background-color: #eefff9;
  color: black; }

.Pill--Red {
  border: 1px solid #ed143d;
  background-color: #fff1f1;
  color: black; }

.Pill--Orange {
  border: 1px solid #fda73f;
  background-color: #f9ebd1;
  color: black; }

.Font-Size--12 {
  font-size: 12px;
  font-size: 1.2rem; }

.Tag--Success {
  border: 1px solid #34bf8e;
  background-color: #3be2a7;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 4px; }

.Tag--Blank {
  border: 1px solid #5a44ff;
  background-color: white;
  color: #5a44ff;
  padding: 2px 4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in;
  -khtml-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in; }
  .Tag--Blank:hover, .Tag--Blank:focus {
    border: 1px solid #5a44ff;
    background-color: #5a44ff;
    color: white; }

.Tag--Warning {
  border: 1px solid #e49637;
  background-color: #fda73f;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 4px; }

.Tag--Danger {
  border: 1px solid #ed143d;
  background-color: #ed143d;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 4px; }

.Display--Block {
  display: block; }

.Flex-Align--Left {
  -webkit-align-self: flex-start;
  -khtml-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-align-self: flex-start;
  -o-align-self: flex-start;
  align-self: flex-start; }

.SlashCommand {
  color: #cc0c4c;
  background-color: #f9f9f9;
  border: 1px solid #e4e4e4;
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 4px;
  line-height: 2; }

.Bold {
  font-weight: 500; }

.Spinner--Small {
  width: 16px !important;
  height: 16px !important;
  border-width: 2px !important;
  margin: 0; }

.Center--Force {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important; }

.ServerError {
  background-color: #fdfdfd;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  color: #444;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2; }
  .ServerError .ErrorTitle {
    font-family: monospace;
    color: #cc0c4c;
    margin-right: 4px; }

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Karla"), local("Karla-Regular"), url(https://fonts.gstatic.com/s/karla/v8/qkBbXvYC6trAT7RbLtyG5Q.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Karla"), local("Karla-Regular"), url(https://fonts.gstatic.com/s/karla/v8/qkBbXvYC6trAT7RVLtw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Karla Bold"), local("Karla-Bold"), url(https://fonts.gstatic.com/s/karla/v8/qkBWXvYC6trAT7zuC8m3xLt3mQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Karla Bold"), local("Karla-Bold"), url(https://fonts.gstatic.com/s/karla/v8/qkBWXvYC6trAT7zuC8m5xLs.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

h1 {
  font-weight: 500;
  line-height: 32px;
  color: #121212;
  font-size: 32px;
  font-size: 3.2rem;
  margin: 12px 0;
  padding: 0; }

h3 {
  font-weight: 500;
  line-height: 24px;
  color: #121212;
  font-size: 24px;
  font-size: 2.4rem;
  margin: 12px 0;
  padding: 0; }

h4 {
  font-weight: 500;
  line-height: 1.5;
  color: #121212;
  font-size: 18px;
  font-size: 1.8rem;
  margin: 12px 0;
  padding: 0; }

p {
  font-weight: 400;
  line-height: 1.5;
  color: #444;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 0; }

/* Components */
.Button {
  display: inline-block;
  padding: 8px 24px;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  text-decoration: none;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 0;
  min-width: 100px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: background-color .15s,box-shadow .15s,color .15s,opacity .15s;
  -khtml-transition: background-color .15s,box-shadow .15s,color .15s,opacity .15s;
  -moz-transition: background-color .15s,box-shadow .15s,color .15s,opacity .15s;
  -ms-transition: background-color .15s,box-shadow .15s,color .15s,opacity .15s;
  -o-transition: background-color .15s,box-shadow .15s,color .15s,opacity .15s;
  transition: background-color .15s,box-shadow .15s,color .15s,opacity .15s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-size: 1.6rem; }

.Button:disabled {
  cursor: not-allowed;
  opacity: .75;
  pointer-events: none; }

button:disabled {
  cursor: not-allowed;
  opacity: .6;
  pointer-events: none; }

.Button--Primary {
  background-color: #5a44ff;
  color: #fff; }
  .Button--Primary:hover, .Button--Primary:focus {
    -webkit-box-shadow: inset 0 -6.25rem 0 hsla(0,0%,100%,.1);
    -khtml-box-shadow: inset 0 -6.25rem 0 hsla(0,0%,100%,.1);
    -moz-box-shadow: inset 0 -6.25rem 0 hsla(0,0%,100%,.1);
    -ms-box-shadow: inset 0 -6.25rem 0 hsla(0,0%,100%,.1);
    -o-box-shadow: inset 0 -6.25rem 0 hsla(0,0%,100%,.1);
    box-shadow: inset 0 -6.25rem 0 hsla(0,0%,100%,.1); }
  .Button--Primary .ButtonLoader {
    border: 2px solid #f1f0ff;
    border-right-color: transparent; }

.Button--Secondary {
  background-color: #fff;
  color: #444;
  border: 1px solid #e4e4e4; }
  .Button--Secondary:hover, .Button--Secondary:focus {
    -webkit-box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(234, 234, 234, 0.32);
    -khtml-box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(234, 234, 234, 0.32);
    -moz-box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(234, 234, 234, 0.32);
    -ms-box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(234, 234, 234, 0.32);
    -o-box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(234, 234, 234, 0.32);
    box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(234, 234, 234, 0.32); }

.Button--Dangerous {
  background-color: #fff;
  color: #ed143d;
  border: 1px solid #ed143d; }
  .Button--Dangerous:hover, .Button--Dangerous:focus {
    -webkit-box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(255, 179, 193, 0.32);
    -khtml-box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(255, 179, 193, 0.32);
    -moz-box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(255, 179, 193, 0.32);
    -ms-box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(255, 179, 193, 0.32);
    -o-box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(255, 179, 193, 0.32);
    box-shadow: inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(255, 179, 193, 0.32); }
  .Button--Dangerous .ButtonLoader {
    border: 2px solid #ed143d !important;
    border-right-color: transparent !important; }

.Group__Button {
  background-color: transparent;
  font-weight: 500;
  border: 1px solid #e4e4e4;
  color: #929292;
  font-size: 14px;
  font-size: 1.4rem;
  padding: 10px 12px; }
  .Group__Button:hover, .Group__Button:focus {
    border: 1px solid #5a44ff;
    color: #5a44ff; }

.Loader__Wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }

.Group__Button.Selected {
  background-color: #f1f0ff;
  font-weight: 500;
  border: 1px solid #5a44ff;
  color: #5a44ff; }

.Button--Light {
  font-size: 15px;
  font-weight: normal; }

.ButtonLoader {
  -webkit-animation-duration: 1.2s;
  -khtml-animation-duration: 1.2s;
  -moz-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
  -khtml-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate-forever;
  -khtml-animation-name: rotate-forever;
  -moz-animation-name: rotate-forever;
  -ms-animation-name: rotate-forever;
  -o-animation-name: rotate-forever;
  animation-name: rotate-forever;
  -webkit-animation-timing-function: linear;
  -khtml-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  height: 16px;
  width: 16px;
  margin-right: 6px;
  border: 2px solid #5a44ff;
  border-right-color: transparent;
  border-radius: 100%; }

.Header {
  width: 100%;
  height: auto;
  position: sticky;
  top: 0px;
  z-index: 2;
  padding-top: 12px;
  background-color: rgba(255, 255, 255, 0.96); }
  .Header .Header__Controls {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .Header .Header__Controls .NewMessage {
      -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
      -khtml-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
      -moz-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
      -ms-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
      -o-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
      -webkit-font-smoothing: antialiased;
      font-size: 16px;
      font-size: 1.6rem;
      align-items: center;
      background-color: #fff;
      background-image: none;
      user-select: none;
      -webkit-transition: box-shadow .08s linear, min-width .15s cubic-bezier(0.4,0.0,0.2,1);
      -khtml-transition: box-shadow .08s linear, min-width .15s cubic-bezier(0.4,0.0,0.2,1);
      -moz-transition: box-shadow .08s linear, min-width .15s cubic-bezier(0.4,0.0,0.2,1);
      -ms-transition: box-shadow .08s linear, min-width .15s cubic-bezier(0.4,0.0,0.2,1);
      -o-transition: box-shadow .08s linear, min-width .15s cubic-bezier(0.4,0.0,0.2,1);
      transition: box-shadow .08s linear, min-width .15s cubic-bezier(0.4,0.0,0.2,1);
      border-radius: 24px;
      color: #3c4043;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      font-weight: 500;
      height: 48px;
      min-width: 48px;
      padding: 0 24px 0 0;
      cursor: pointer;
      text-transform: none; }
      .Header .Header__Controls .NewMessage:before {
        background-image: url(./../../images/plus_icon.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 32px;
        content: "";
        display: block;
        height: 48px;
        min-width: 48px; }
      .Header .Header__Controls .NewMessage:hover, .Header .Header__Controls .NewMessage:focus {
        -webkit-box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
        -khtml-box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
        -moz-box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
        -ms-box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
        -o-box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
        box-shadow: 0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149);
        background-color: #fafafb; }
    .Header .Header__Controls .Profile {
      height: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      position: relative;
      margin-left: auto;
      padding: 0; }
      .Header .Header__Controls .Profile .Profile__Image__Wrapper {
        position: relative;
        width: 48px;
        height: 48px;
        -webkit-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -khtml-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
        .Header .Header__Controls .Profile .Profile__Image__Wrapper .Profile__Image {
          width: 48px;
          height: 48px;
          z-index: 2;
          border-radius: 8px; }
        .Header .Header__Controls .Profile .Profile__Image__Wrapper .PlanTag {
          padding: 2px 4px;
          border-radius: 8px;
          font-weight: 500;
          letter-spacing: 2px;
          position: absolute;
          width: 100%;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          text-align: center;
          bottom: 0;
          font-size: 10px;
          font-size: 1rem; }
        .Header .Header__Controls .Profile .Profile__Image__Wrapper .PlanTag.Free {
          background: rgba(59, 226, 167, 0.9);
          color: #444; }
        .Header .Header__Controls .Profile .Profile__Image__Wrapper .PlanTag.Pro {
          background: rgba(255, 214, 68, 0.9);
          color: #444; }
      .Header .Header__Controls .Profile .Down__Arrow {
        z-index: 2;
        -webkit-transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -khtml-transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
      .Header .Header__Controls .Profile .Profile_Details {
        display: flex;
        z-index: 2;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 0 12px; }
        .Header .Header__Controls .Profile .Profile_Details .Profile_Details--Name {
          font-size: 16px;
          font-size: 1.6rem;
          line-height: 1.5;
          color: #121212;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 140px;
          font-weight: 500;
          user-select: none;
          -webkit-transition: color 0.3s linear;
          -khtml-transition: color 0.3s linear;
          -moz-transition: color 0.3s linear;
          -ms-transition: color 0.3s linear;
          -o-transition: color 0.3s linear;
          transition: color 0.3s linear; }
        .Header .Header__Controls .Profile .Profile_Details .Profile_Details--Plan {
          font-size: 14px;
          font-size: 1.4rem;
          line-height: 1.5;
          max-width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          user-select: none;
          color: #444;
          text-transform: capitalize;
          -webkit-transition: color 0.3s linear;
          -khtml-transition: color 0.3s linear;
          -moz-transition: color 0.3s linear;
          -ms-transition: color 0.3s linear;
          -o-transition: color 0.3s linear;
          transition: color 0.3s linear; }
      .Header .Header__Controls .Profile:hover .Profile__Image__Wrapper {
        opacity: 0.6; }
      .Header .Header__Controls .Profile:hover .Profile_Details--Name {
        color: #929292; }
      .Header .Header__Controls .Profile:hover .Profile_Details--Plan {
        color: #929292; }
      .Header .Header__Controls .Profile:hover .Down__Arrow {
        -webkit-transform: rotate(-180deg);
        -khtml-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        transform: rotate(-180deg); }
      .Header .Header__Controls .Profile:hover .Popover {
        opacity: 1;
        -webkit-transform: scale(1);
        -khtml-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1); }
  .Header .Navigation {
    display: flex;
    position: relative;
    padding: 30px 0 18px 0; }
    .Header .Navigation a {
      font-size: 16px;
      font-size: 1.6rem;
      color: #929292;
      text-transform: capitalize;
      text-decoration: none;
      user-select: none;
      cursor: pointer;
      margin: 0 12px;
      display: flex;
      align-items: center; }
      .Header .Navigation a:first-child {
        margin-left: 0; }
      .Header .Navigation a:hover {
        text-decoration: underline; }
      .Header .Navigation a svg {
        stroke: #929292;
        margin-right: 4px; }
    .Header .Navigation a.Nav--Selected {
      color: #121212;
      font-weight: 500; }
      .Header .Navigation a.Nav--Selected svg {
        stroke: #121212;
        stroke-width: 2px; }
    .Header .Navigation .SettingsLink {
      margin-left: auto;
      margin-right: 0; }

@media all and (max-width: 490px) {
  .Header .Header__Controls .NewMessage {
    width: 48px;
    color: transparent;
    min-width: 48px; }
  .Header .Header__Controls .Profile_Details--Name {
    font-size: 14px !important; }
  .Header .Header__Controls .Profile_Details--Plan {
    font-size: 12px !important; } }

@media all and (max-width: 416px) {
  .Navigation {
    text-align: center; }
    .Navigation a {
      font-size: 14px !important;
      margin: 0 6px; }
      .Navigation a:first-child {
        margin: 0 6px; } }

@media all and (max-width: 370px) {
  .Navigation {
    text-align: center; }
    .Navigation a {
      font-size: 14px !important; }
      .Navigation a:first-child {
        margin: 0 6px 0 0 !important; } }

.Footer {
  width: 100%;
  height: auto; }
  .Footer .Footer__Links {
    display: block;
    position: relative;
    text-align: center; }
    .Footer .Footer__Links a {
      font-size: 14px;
      font-size: 1.4rem;
      color: #444;
      display: inline-block;
      text-decoration: none;
      user-select: none;
      cursor: pointer;
      line-height: 1.6;
      margin: 0 12px; }
      .Footer .Footer__Links a:focus, .Footer .Footer__Links a:hover {
        text-decoration: underline; }

@media all and (max-width: 520px) {
  .Footer .Footer__Links a {
    font-size: 13px;
    font-size: 1.3rem;
    margin: 0 6px; } }

@media all and (max-width: 430px) {
  .Footer .Footer__Links a {
    font-size: 12px;
    font-size: 1.2rem;
    margin: 0 4px; } }

.FullScreenLoader {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  padding-top: 110px; }
  .FullScreenLoader .FullScreenLoader__Container {
    max-width: 584px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    width: 100%; }

.LoadingSpinner {
  -webkit-animation-duration: 1.2s;
  -khtml-animation-duration: 1.2s;
  -moz-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
  -khtml-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate-forever;
  -khtml-animation-name: rotate-forever;
  -moz-animation-name: rotate-forever;
  -ms-animation-name: rotate-forever;
  -o-animation-name: rotate-forever;
  animation-name: rotate-forever;
  -webkit-animation-timing-function: linear;
  -khtml-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  height: 34px;
  width: 34px;
  border: 8px solid #5a44ff;
  border-right-color: transparent;
  border-radius: 100%; }

.FullScreenErrorPopup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  background: white;
  display: flex;
  justify-content: center; }
  .FullScreenErrorPopup .FullScreenErrorPopup__Container {
    max-width: 584px;
    padding: 110px 24px 0 24px; }

.Popup__Overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  visibility: hidden;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
  -khtml-transition: all 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
  -moz-transition: all 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
  -ms-transition: all 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
  -o-transition: all 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
  transition: all 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 5;
  overflow-y: auto; }
  .Popup__Overlay .Popup__Container {
    max-width: 544px;
    margin: 30px 0;
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
    -khtml-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
    -moz-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
    -ms-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
    -o-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
    width: 100%;
    -webkit-transform: scale(0);
    -khtml-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: transform 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
    -khtml-transition: transform 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
    -moz-transition: transform 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
    -ms-transition: transform 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
    -o-transition: transform 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
    transition: transform 0.3s cubic-bezier(0.12, 0.64, 0.58, 1);
    height: auto;
    background: white;
    border-radius: 8px;
    padding: 16px; }
    .Popup__Overlay .Popup__Container .Popup__Head {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 16px 0; }
      .Popup__Overlay .Popup__Container .Popup__Head .New__Message {
        background-image: url(./../../images/plus_icon.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 32px;
        height: 22px;
        min-width: 22px;
        margin-right: 10px; }
      .Popup__Overlay .Popup__Container .Popup__Head h3 {
        margin: 0;
        font-size: 20px;
        font-size: 2rem; }
    .Popup__Overlay .Popup__Container .Popup__Body {
      min-height: 300px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 48px;
      max-width: 402px;
      width: 100%; }
      .Popup__Overlay .Popup__Container .Popup__Body .To_Whom,
      .Popup__Overlay .Popup__Container .Popup__Body .When {
        width: 100%; }
      .Popup__Overlay .Popup__Container .Popup__Body .Item {
        margin-top: 16px;
        width: 100%; }
      .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        position: relative; }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .Button__Panel {
          padding-left: 16px;
          width: 100%;
          -webkit-transition: all 0.3s ease-out;
          -khtml-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out; }
          .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .Button__Panel .ScheduleButton__ProBadge {
            position: absolute;
            left: 2px;
            z-index: 2;
            top: -6px; }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .Button__Panel.Hide {
          opacity: 0;
          -webkit-transform: scale(0);
          -khtml-transform: scale(0);
          -moz-transform: scale(0);
          -ms-transform: scale(0);
          -o-transform: scale(0);
          transform: scale(0); }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .Button__Panel.Show {
          opacity: 1;
          -webkit-transform: scale(1);
          -khtml-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          transform: scale(1); }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .Popup__Action__Item__Title {
          -webkit-transition: color 0.3s ease-out;
          -khtml-transition: color 0.3s ease-out;
          -moz-transition: color 0.3s ease-out;
          -ms-transition: color 0.3s ease-out;
          -o-transition: color 0.3s ease-out;
          transition: color 0.3s ease-out;
          font-size: 16px;
          font-size: 1.6rem;
          font-weight: 500;
          color: #444;
          user-select: none;
          padding: 6px 0; }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .Popup__Action__Item__Content {
          padding: 4px 0;
          -webkit-transition: all 0.3s ease-out;
          -khtml-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
          position: relative;
          width: 100%; }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item:first-child {
          margin-top: 0; }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .MessageArea {
          border: 1px solid #e4e4e4;
          border-radius: 4px;
          resize: none;
          width: 100%;
          min-width: 100%;
          width: 100%;
          font-family: "Karla", sans-serif;
          font-weight: 400;
          line-height: 1.5;
          padding: 10px;
          height: 70px;
          color: #444;
          font-size: 14px;
          font-size: 1.4rem; }
    .Popup__Overlay .Popup__Container .Popup__Body.Alert {
      min-height: auto !important;
      max-width: 100% !important; }
    .Popup__Overlay .Popup__Container .Popup__Body.Modal {
      margin: 0;
      padding: 16px 24px;
      width: 100%;
      max-width: 100%; }
    .Popup__Overlay .Popup__Container .ProPricingDetails {
      margin: 12px 24px;
      padding-left: 12px;
      border-left: 4px solid #3be2a7; }
      .Popup__Overlay .Popup__Container .ProPricingDetails p {
        margin: 2px 0; }
    .Popup__Overlay .Popup__Container .Popup__Body.Hide {
      display: none !important; }
    .Popup__Overlay .Popup__Container .Popup__Body.Summary {
      max-width: 100% !important;
      min-height: 220px !important;
      padding: 0 24px; }
    .Popup__Overlay .Popup__Container .Popup__Footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 30px;
      width: 100%; }
  .Popup__Overlay .SubscriptionCancelPopup {
    max-width: 450px; }
    .Popup__Overlay .SubscriptionCancelPopup .Popup__Body {
      min-height: 150px; }
      .Popup__Overlay .SubscriptionCancelPopup .Popup__Body .SubscriptionCancelPopup__SubTitle {
        width: 100%;
        text-align: left; }

.Input__Group {
  display: flex;
  justify-content: center;
  align-content: flex-start; }
  .Input__Group .Left__Radius {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .Input__Group .Clear__Icon {
    height: 39px;
    width: 40px;
    margin-left: 6px;
    stroke: #5a44ff;
    cursor: pointer; }
    .Input__Group .Clear__Icon:hover, .Input__Group .Clear__Icon:focus {
      fill: #5a44ff;
      stroke: white; }

.Time__Search,
.TimeZone,
.Middle__Select,
.LeftEdge__Select,
.RightEdge__Select,
.Repeat__Select,
.User__Search {
  border: 1px solid #5a44ff;
  padding: 4px 8px;
  color: #444;
  font-size: 14px;
  font-size: 1.4rem;
  border-top-right-radius: 4px;
  border-left: none;
  border-bottom-right-radius: 4px;
  min-height: 40px;
  height: 40px;
  width: 100%; }
  .Time__Search .select__control,
  .TimeZone .select__control,
  .Middle__Select .select__control,
  .LeftEdge__Select .select__control,
  .RightEdge__Select .select__control,
  .Repeat__Select .select__control,
  .User__Search .select__control {
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
    .Time__Search .select__control:hover, .Time__Search .select__control:focus,
    .TimeZone .select__control:hover,
    .TimeZone .select__control:focus,
    .Middle__Select .select__control:hover,
    .Middle__Select .select__control:focus,
    .LeftEdge__Select .select__control:hover,
    .LeftEdge__Select .select__control:focus,
    .RightEdge__Select .select__control:hover,
    .RightEdge__Select .select__control:focus,
    .Repeat__Select .select__control:hover,
    .Repeat__Select .select__control:focus,
    .User__Search .select__control:hover,
    .User__Search .select__control:focus {
      border: none;
      border-radius: 0;
      border-color: #5a44ff;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }
  .Time__Search .select__menu,
  .TimeZone .select__menu,
  .Middle__Select .select__menu,
  .LeftEdge__Select .select__menu,
  .RightEdge__Select .select__menu,
  .Repeat__Select .select__menu,
  .User__Search .select__menu {
    width: 100%;
    right: 0px; }
    .Time__Search .select__menu .select__option,
    .TimeZone .select__menu .select__option,
    .Middle__Select .select__menu .select__option,
    .LeftEdge__Select .select__menu .select__option,
    .RightEdge__Select .select__menu .select__option,
    .Repeat__Select .select__menu .select__option,
    .User__Search .select__menu .select__option {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-size: 1.4rem;
      width: 100%; }
    .Time__Search .select__menu .select__option--is-focused,
    .TimeZone .select__menu .select__option--is-focused,
    .Middle__Select .select__menu .select__option--is-focused,
    .LeftEdge__Select .select__menu .select__option--is-focused,
    .RightEdge__Select .select__menu .select__option--is-focused,
    .Repeat__Select .select__menu .select__option--is-focused,
    .User__Search .select__menu .select__option--is-focused {
      background-color: #ebe9ff; }
    .Time__Search .select__menu .select__option--is-selected,
    .TimeZone .select__menu .select__option--is-selected,
    .Middle__Select .select__menu .select__option--is-selected,
    .LeftEdge__Select .select__menu .select__option--is-selected,
    .RightEdge__Select .select__menu .select__option--is-selected,
    .Repeat__Select .select__menu .select__option--is-selected,
    .User__Search .select__menu .select__option--is-selected {
      background-color: #5a44ff;
      color: white; }

.User__Filter {
  min-height: 40px;
  height: auto; }

.User__Search,
.Middle__Select,
.LeftEdge__Select,
.RightEdge__Select,
.Repeat__Select {
  padding: 0;
  width: 100%; }

.LeftEdge__Select {
  border-top-left-radius: 4px;
  border-left: 1px solid #5a44ff;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }
  .LeftEdge__Select .select__control {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
    .LeftEdge__Select .select__control:hover, .LeftEdge__Select .select__control:focus {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }

.TimeZone {
  border-top-left-radius: 4px;
  border-left: 1px solid #5a44ff;
  border-bottom-left-radius: 4px;
  padding: 0; }
  .TimeZone .select__control {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
    .TimeZone .select__control:hover, .TimeZone .select__control:focus {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }

.Middle__Select {
  border-top-right-radius: 0px;
  border-left: none;
  border-bottom-right-radius: 0px;
  padding: 0; }
  .Middle__Select .select__control {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
    .Middle__Select .select__control:hover, .Middle__Select .select__control:focus {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px; }

.Popup__Overlay.Open {
  opacity: 1;
  visibility: visible; }
  .Popup__Overlay.Open .Popup__Container {
    -webkit-transform: scale(1);
    -khtml-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }

.Model--Open {
  overflow-y: hidden; }

@media all and (max-width: 452px) {
  .Popup__Overlay {
    padding: 0; }
    .Popup__Overlay .Popup__Container {
      margin: 12px; }
      .Popup__Overlay .Popup__Container .Popup__Head h3 {
        margin: 0;
        font-size: 18px !important; }
      .Popup__Overlay .Popup__Container .Popup__Body {
        padding: 0px !important;
        min-height: 200px !important; }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .MessageArea {
          min-width: 314px !important; }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .Button {
          font-size: 13px !important;
          padding: 12px !important;
          min-width: 100px; }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .Popup__Action__Item__Title {
          font-size: 14px !important; }
        .Popup__Overlay .Popup__Container .Popup__Body .Margin-Right--12 {
          margin-right: 6px !important; }
        .Popup__Overlay .Popup__Container .Popup__Body .SingleDatePickerInput .DateInput .DateInput_input {
          font-size: 13px !important; }
      .Popup__Overlay .Popup__Container .Popup__Footer .Button {
        font-size: 14px !important;
        padding: 8px !important; } }

@media all and (max-width: 570px) {
  .Popup__Overlay {
    padding: 0; }
    .Popup__Overlay .Popup__Container {
      margin: 12px; }
      .Popup__Overlay .Popup__Container .Popup__Head h3 {
        margin: 0;
        font-size: 18px !important; }
      .Popup__Overlay .Popup__Container .Popup__Body {
        padding-left: 12px; }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .MessageArea {
          min-width: 347px; }
        .Popup__Overlay .Popup__Container .Popup__Body .Popup__Action__Item .Popup__Action__Item__Title {
          font-size: 15px !important; } }

@-webkit-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -khtml-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -khtml-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -khtml-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -khtml-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -khtml-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -khtml-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.SlideIn-appear {
  opacity: 0;
  -webkit-transform: translateX(-10px);
  -khtml-transform: translateX(-10px);
  -moz-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  -o-transform: translateX(-10px);
  transform: translateX(-10px); }

.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -khtml-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  -webkit-transition: opacity .4s cubic-bezier(0.12, 0.64, 0.58, 1), transform .4s cubic-bezier(0.12, 0.64, 0.58, 1);
  -khtml-transition: opacity .4s cubic-bezier(0.12, 0.64, 0.58, 1), transform .4s cubic-bezier(0.12, 0.64, 0.58, 1);
  -moz-transition: opacity .4s cubic-bezier(0.12, 0.64, 0.58, 1), transform .4s cubic-bezier(0.12, 0.64, 0.58, 1);
  -ms-transition: opacity .4s cubic-bezier(0.12, 0.64, 0.58, 1), transform .4s cubic-bezier(0.12, 0.64, 0.58, 1);
  -o-transition: opacity .4s cubic-bezier(0.12, 0.64, 0.58, 1), transform .4s cubic-bezier(0.12, 0.64, 0.58, 1);
  transition: opacity .4s cubic-bezier(0.12, 0.64, 0.58, 1), transform .4s cubic-bezier(0.12, 0.64, 0.58, 1); }

.SlideIn-enter {
  opacity: 0;
  -webkit-transform: translateX(-10px);
  -khtml-transform: translateX(-10px);
  -moz-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  -o-transform: translateX(-10px);
  transform: translateX(-10px); }

.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -khtml-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  -webkit-transition: opacity .9s cubic-bezier(0.12, 0.64, 0.58, 1), transform .9s cubic-bezier(0.12, 0.64, 0.58, 1);
  -khtml-transition: opacity .9s cubic-bezier(0.12, 0.64, 0.58, 1), transform .9s cubic-bezier(0.12, 0.64, 0.58, 1);
  -moz-transition: opacity .9s cubic-bezier(0.12, 0.64, 0.58, 1), transform .9s cubic-bezier(0.12, 0.64, 0.58, 1);
  -ms-transition: opacity .9s cubic-bezier(0.12, 0.64, 0.58, 1), transform .9s cubic-bezier(0.12, 0.64, 0.58, 1);
  -o-transition: opacity .9s cubic-bezier(0.12, 0.64, 0.58, 1), transform .9s cubic-bezier(0.12, 0.64, 0.58, 1);
  transition: opacity .9s cubic-bezier(0.12, 0.64, 0.58, 1), transform .9s cubic-bezier(0.12, 0.64, 0.58, 1); }

.SlideIn-leave {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -khtml-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px); }

.SlideIn-leave.SlideIn-leave-active {
  opacity: 0;
  -webkit-transform: translateX(-10px);
  -khtml-transform: translateX(-10px);
  -moz-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  -o-transform: translateX(-10px);
  transform: translateX(-10px);
  -webkit-transition: transform .9s cubic-bezier(0.12, 0.64, 0.58, 1);
  -khtml-transition: transform .9s cubic-bezier(0.12, 0.64, 0.58, 1);
  -moz-transition: transform .9s cubic-bezier(0.12, 0.64, 0.58, 1);
  -ms-transition: transform .9s cubic-bezier(0.12, 0.64, 0.58, 1);
  -o-transition: transform .9s cubic-bezier(0.12, 0.64, 0.58, 1);
  transition: transform .9s cubic-bezier(0.12, 0.64, 0.58, 1); }

.FadeIn-appear {
  opacity: 0; }

.FadeIn-appear.FadeIn-appear-active {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in;
  -khtml-transition: opacity 0.3s ease-in;
  -moz-transition: opacity 0.3s ease-in;
  -ms-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in; }

.FadeIn-leave {
  opacity: 1; }

.FadeIn-leave.FadeIn-leave-active {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -khtml-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

/* This approximates the ease-in-out-bounce animation from easings.net, which would require a plug-in to use*/
@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in; }
  37% {
    transform: translateX(5px);
    timing-function: ease-out; }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in; }
  73% {
    transform: translateX(4px);
    timing-function: ease-out; }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in; }
  91% {
    transform: translateX(2px);
    timing-function: ease-out; }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in; }
  100% {
    transform: translateX(0px);
    timing-function: ease-in; } }

@-webkit-keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in; }
  37% {
    transform: translateX(5px);
    timing-function: ease-out; }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in; }
  73% {
    transform: translateX(4px);
    timing-function: ease-out; }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in; }
  91% {
    transform: translateX(2px);
    timing-function: ease-out; }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in; }
  100% {
    transform: translateX(0px);
    timing-function: ease-in; } }

@-moz-keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in; }
  37% {
    transform: translateX(5px);
    timing-function: ease-out; }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in; }
  73% {
    transform: translateX(4px);
    timing-function: ease-out; }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in; }
  91% {
    transform: translateX(2px);
    timing-function: ease-out; }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in; }
  100% {
    transform: translateX(0px);
    timing-function: ease-in; } }

@-webkit-keyframes fade {
  from {
    opacity: 1.0; }
  50% {
    opacity: 0.4; }
  to {
    opacity: 1.0; } }

@-moz-keyframes fade {
  from {
    opacity: 1.0; }
  50% {
    opacity: 0.4; }
  to {
    opacity: 1.0; } }

@keyframes fade {
  from {
    opacity: 1.0; }
  50% {
    opacity: 0.4; }
  to {
    opacity: 1.0; } }

.Progress {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #ebe9ff;
  border-radius: 2px;
  background-clip: padding-box;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden; }

.Progress .Indeterminate {
  background-color: #5a44ff; }

.Progress .Indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }

.Progress .Indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s; }

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

@keyframes alternator {
  0% {
    width: 0; }
  100% {
    width: 100%;
    margin-left: 100%; } }

.em, .em-svg {
  height: 1.5em;
  width: 1.5em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle; }

.em---1, .em---1, .em-thumbsup {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44d.png"); }

.em---1.em-svg, .em---1.em-svg, .em-thumbsup.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f44d.svg"), none; }

.em--1, .em-thumbsdown {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44e.png"); }

.em--1.em-svg, .em-thumbsdown.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f44e.svg"), none; }

.em-100 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4af.png"); }

.em-100.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4af.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4af.svg"), none; }

.em-1234 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f522.png"); }

.em-1234.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f522.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f522.svg"), none; }

.em-8ball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b1.png"); }

.em-8ball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3b1.svg"), none; }

.em-a {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f170.png"); }

.em-a.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f170.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f170.svg"), none; }

.em-ab {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f18e.png"); }

.em-ab.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f18e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f18e.svg"), none; }

.em-abc {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f524.png"); }

.em-abc.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f524.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f524.svg"), none; }

.em-abcd {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f521.png"); }

.em-abcd.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f521.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f521.svg"), none; }

.em-accept {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f251.png"); }

.em-accept.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f251.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f251.svg"), none; }

.em-admission_tickets {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f39f.png"); }

.em-admission_tickets.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f39f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f39f.svg"), none; }

.em-adult {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d1.png"); }

.em-adult.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d1.svg"), none; }

.em-aerial_tramway {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a1.png"); }

.em-aerial_tramway.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a1.svg"), none; }

.em-airplane {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2708.png"); }

.em-airplane.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2708.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2708.svg"), none; }

.em-airplane_arriving {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ec.png"); }

.em-airplane_arriving.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6ec.svg"), none; }

.em-airplane_departure {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6eb.png"); }

.em-airplane_departure.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6eb.svg"), none; }

.em-alarm_clock {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f0.png"); }

.em-alarm_clock.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23f0.svg"), none; }

.em-alembic {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2697.png"); }

.em-alembic.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2697.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2697.svg"), none; }

.em-alien {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47d.png"); }

.em-alien.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f47d.svg"), none; }

.em-ambulance {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f691.png"); }

.em-ambulance.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f691.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f691.svg"), none; }

.em-amphora {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3fa.png"); }

.em-amphora.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3fa.svg"), none; }

.em-anchor {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2693.png"); }

.em-anchor.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2693.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2693.svg"), none; }

.em-angel {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47c.png"); }

.em-angel.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f47c.svg"), none; }

.em-anger {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a2.png"); }

.em-anger.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4a2.svg"), none; }

.em-angry {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f620.png"); }

.em-angry.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f620.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f620.svg"), none; }

.em-anguished {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f627.png"); }

.em-anguished.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f627.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f627.svg"), none; }

.em-ant {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41c.png"); }

.em-ant.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f41c.svg"), none; }

.em-apple {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34e.png"); }

.em-apple.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f34e.svg"), none; }

.em-aquarius {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2652.png"); }

.em-aquarius.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2652.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2652.svg"), none; }

.em-aries {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2648.png"); }

.em-aries.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2648.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2648.svg"), none; }

.em-arrow_backward {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25c0.png"); }

.em-arrow_backward.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25c0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/25c0.svg"), none; }

.em-arrow_double_down {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23ec.png"); }

.em-arrow_double_down.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23ec.svg"), none; }

.em-arrow_double_up {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23eb.png"); }

.em-arrow_double_up.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23eb.svg"), none; }

.em-arrow_down {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b07.png"); }

.em-arrow_down.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b07.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2b07.svg"), none; }

.em-arrow_down_small {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f53d.png"); }

.em-arrow_down_small.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f53d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f53d.svg"), none; }

.em-arrow_forward {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25b6.png"); }

.em-arrow_forward.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25b6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/25b6.svg"), none; }

.em-arrow_heading_down {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2935.png"); }

.em-arrow_heading_down.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2935.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2935.svg"), none; }

.em-arrow_heading_up {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2934.png"); }

.em-arrow_heading_up.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2934.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2934.svg"), none; }

.em-arrow_left {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b05.png"); }

.em-arrow_left.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b05.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2b05.svg"), none; }

.em-arrow_lower_left {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2199.png"); }

.em-arrow_lower_left.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2199.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2199.svg"), none; }

.em-arrow_lower_right {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2198.png"); }

.em-arrow_lower_right.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2198.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2198.svg"), none; }

.em-arrow_right {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/27a1.png"); }

.em-arrow_right.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/27a1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/27a1.svg"), none; }

.em-arrow_right_hook {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/21aa.png"); }

.em-arrow_right_hook.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/21aa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/21aa.svg"), none; }

.em-arrow_up {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b06.png"); }

.em-arrow_up.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b06.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2b06.svg"), none; }

.em-arrow_up_down {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2195.png"); }

.em-arrow_up_down.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2195.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2195.svg"), none; }

.em-arrow_up_small {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f53c.png"); }

.em-arrow_up_small.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f53c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f53c.svg"), none; }

.em-arrow_upper_left {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2196.png"); }

.em-arrow_upper_left.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2196.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2196.svg"), none; }

.em-arrow_upper_right {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2197.png"); }

.em-arrow_upper_right.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2197.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2197.svg"), none; }

.em-arrows_clockwise {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f503.png"); }

.em-arrows_clockwise.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f503.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f503.svg"), none; }

.em-arrows_counterclockwise {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f504.png"); }

.em-arrows_counterclockwise.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f504.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f504.svg"), none; }

.em-art {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a8.png"); }

.em-art.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3a8.svg"), none; }

.em-articulated_lorry {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69b.png"); }

.em-articulated_lorry.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f69b.svg"), none; }

.em-astonished {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f632.png"); }

.em-astonished.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f632.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f632.svg"), none; }

.em-athletic_shoe {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45f.png"); }

.em-athletic_shoe.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f45f.svg"), none; }

.em-atm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e7.png"); }

.em-atm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3e7.svg"), none; }

.em-atom_symbol {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/269b.png"); }

.em-atom_symbol.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/269b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/269b.svg"), none; }

.em-avocado {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f951.png"); }

.em-avocado.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f951.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f951.svg"), none; }

.em-b {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f171.png"); }

.em-b.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f171.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f171.svg"), none; }

.em-baby {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f476.png"); }

.em-baby.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f476.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f476.svg"), none; }

.em-baby_bottle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37c.png"); }

.em-baby_bottle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f37c.svg"), none; }

.em-baby_chick {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f424.png"); }

.em-baby_chick.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f424.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f424.svg"), none; }

.em-baby_symbol {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6bc.png"); }

.em-baby_symbol.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6bc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6bc.svg"), none; }

.em-back {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f519.png"); }

.em-back.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f519.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f519.svg"), none; }

.em-bacon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f953.png"); }

.em-bacon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f953.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f953.svg"), none; }

.em-badminton_racquet_and_shuttlecock {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f8.png"); }

.em-badminton_racquet_and_shuttlecock.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3f8.svg"), none; }

.em-baggage_claim {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c4.png"); }

.em-baggage_claim.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6c4.svg"), none; }

.em-baguette_bread {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f956.png"); }

.em-baguette_bread.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f956.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f956.svg"), none; }

.em-balloon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f388.png"); }

.em-balloon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f388.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f388.svg"), none; }

.em-ballot_box_with_ballot {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5f3.png"); }

.em-ballot_box_with_ballot.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5f3.svg"), none; }

.em-ballot_box_with_check {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2611.png"); }

.em-ballot_box_with_check.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2611.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2611.svg"), none; }

.em-bamboo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38d.png"); }

.em-bamboo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f38d.svg"), none; }

.em-banana {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34c.png"); }

.em-banana.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f34c.svg"), none; }

.em-bangbang {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/203c.png"); }

.em-bangbang.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/203c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/203c.svg"), none; }

.em-bank {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e6.png"); }

.em-bank.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3e6.svg"), none; }

.em-bar_chart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ca.png"); }

.em-bar_chart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ca.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ca.svg"), none; }

.em-barber {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f488.png"); }

.em-barber.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f488.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f488.svg"), none; }

.em-barely_sunny, .em-sun_behind_cloud {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f325.png"); }

.em-barely_sunny.em-svg, .em-sun_behind_cloud.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f325.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f325.svg"), none; }

.em-baseball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26be.png"); }

.em-baseball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26be.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26be.svg"), none; }

.em-basketball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c0.png"); }

.em-basketball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c0.svg"), none; }

.em-bat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f987.png"); }

.em-bat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f987.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f987.svg"), none; }

.em-bath {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c0.png"); }

.em-bath.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6c0.svg"), none; }

.em-bathtub {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c1.png"); }

.em-bathtub.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6c1.svg"), none; }

.em-battery {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50b.png"); }

.em-battery.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f50b.svg"), none; }

.em-beach_with_umbrella {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d6.png"); }

.em-beach_with_umbrella.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3d6.svg"), none; }

.em-bear {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43b.png"); }

.em-bear.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f43b.svg"), none; }

.em-bearded_person {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d4.png"); }

.em-bearded_person.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d4.svg"), none; }

.em-bed {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6cf.png"); }

.em-bed.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6cf.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6cf.svg"), none; }

.em-bee, .em-honeybee {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41d.png"); }

.em-bee.em-svg, .em-honeybee.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f41d.svg"), none; }

.em-beer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37a.png"); }

.em-beer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f37a.svg"), none; }

.em-beers {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37b.png"); }

.em-beers.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f37b.svg"), none; }

.em-beetle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41e.png"); }

.em-beetle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f41e.svg"), none; }

.em-beginner {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f530.png"); }

.em-beginner.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f530.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f530.svg"), none; }

.em-bell {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f514.png"); }

.em-bell.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f514.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f514.svg"), none; }

.em-bellhop_bell {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ce.png"); }

.em-bellhop_bell.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ce.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6ce.svg"), none; }

.em-bento {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f371.png"); }

.em-bento.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f371.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f371.svg"), none; }

.em-bicyclist {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b4.png"); }

.em-bicyclist.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b4.svg"), none; }

.em-bike {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b2.png"); }

.em-bike.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b2.svg"), none; }

.em-bikini {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f459.png"); }

.em-bikini.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f459.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f459.svg"), none; }

.em-billed_cap {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e2.png"); }

.em-billed_cap.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9e2.svg"), none; }

.em-biohazard_sign {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2623.png"); }

.em-biohazard_sign.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2623.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2623.svg"), none; }

.em-bird {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f426.png"); }

.em-bird.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f426.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f426.svg"), none; }

.em-birthday {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f382.png"); }

.em-birthday.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f382.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f382.svg"), none; }

.em-black_circle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26ab.png"); }

.em-black_circle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26ab.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26ab.svg"), none; }

.em-black_circle_for_record {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23fa.png"); }

.em-black_circle_for_record.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23fa.svg"), none; }

.em-black_heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5a4.png"); }

.em-black_heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5a4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5a4.svg"), none; }

.em-black_joker {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f0cf.png"); }

.em-black_joker.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f0cf.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f0cf.svg"), none; }

.em-black_large_square {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b1b.png"); }

.em-black_large_square.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b1b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2b1b.svg"), none; }

.em-black_left_pointing_double_triangle_with_vertical_bar {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23ee.png"); }

.em-black_left_pointing_double_triangle_with_vertical_bar.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23ee.svg"), none; }

.em-black_medium_small_square {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25fe.png"); }

.em-black_medium_small_square.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/25fe.svg"), none; }

.em-black_medium_square {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25fc.png"); }

.em-black_medium_square.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/25fc.svg"), none; }

.em-black_nib {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2712.png"); }

.em-black_nib.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2712.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2712.svg"), none; }

.em-black_right_pointing_double_triangle_with_vertical_bar {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23ed.png"); }

.em-black_right_pointing_double_triangle_with_vertical_bar.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23ed.svg"), none; }

.em-black_right_pointing_triangle_with_double_vertical_bar {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23ef.png"); }

.em-black_right_pointing_triangle_with_double_vertical_bar.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23ef.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23ef.svg"), none; }

.em-black_small_square {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25aa.png"); }

.em-black_small_square.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25aa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/25aa.svg"), none; }

.em-black_square_button {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f532.png"); }

.em-black_square_button.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f532.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f532.svg"), none; }

.em-black_square_for_stop {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f9.png"); }

.em-black_square_for_stop.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23f9.svg"), none; }

.em-blond-haired-man {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f471-200d-2642-fe0f.png"); }

.em-blond-haired-man.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f471-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f471-200d-2642-fe0f.svg"), none; }

.em-blond-haired-woman {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f471-200d-2640-fe0f.png"); }

.em-blond-haired-woman.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f471-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f471-200d-2640-fe0f.svg"), none; }

.em-blossom {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33c.png"); }

.em-blossom.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f33c.svg"), none; }

.em-blowfish {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f421.png"); }

.em-blowfish.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f421.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f421.svg"), none; }

.em-blue_book {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d8.png"); }

.em-blue_book.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4d8.svg"), none; }

.em-blue_car {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f699.png"); }

.em-blue_car.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f699.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f699.svg"), none; }

.em-blue_heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f499.png"); }

.em-blue_heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f499.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f499.svg"), none; }

.em-blush {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60a.png"); }

.em-blush.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f60a.svg"), none; }

.em-boar {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f417.png"); }

.em-boar.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f417.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f417.svg"), none; }

.em-boat, .em-sailboat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f5.png"); }

.em-boat.em-svg, .em-sailboat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26f5.svg"), none; }

.em-bomb {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a3.png"); }

.em-bomb.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4a3.svg"), none; }

.em-book, .em-open_book {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d6.png"); }

.em-book.em-svg, .em-open_book.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4d6.svg"), none; }

.em-bookmark {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f516.png"); }

.em-bookmark.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f516.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f516.svg"), none; }

.em-bookmark_tabs {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d1.png"); }

.em-bookmark_tabs.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4d1.svg"), none; }

.em-books {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4da.png"); }

.em-books.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4da.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4da.svg"), none; }

.em-boom, .em-collision {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a5.png"); }

.em-boom.em-svg, .em-collision.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4a5.svg"), none; }

.em-boot {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f462.png"); }

.em-boot.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f462.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f462.svg"), none; }

.em-bouquet {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f490.png"); }

.em-bouquet.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f490.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f490.svg"), none; }

.em-bow {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f647.png"); }

.em-bow.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f647.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f647.svg"), none; }

.em-bow_and_arrow {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f9.png"); }

.em-bow_and_arrow.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3f9.svg"), none; }

.em-bowl_with_spoon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f963.png"); }

.em-bowl_with_spoon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f963.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f963.svg"), none; }

.em-bowling {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b3.png"); }

.em-bowling.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3b3.svg"), none; }

.em-boxing_glove {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f94a.png"); }

.em-boxing_glove.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f94a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f94a.svg"), none; }

.em-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f466.png"); }

.em-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f466.svg"), none; }

.em-brain {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e0.png"); }

.em-brain.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9e0.svg"), none; }

.em-bread {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35e.png"); }

.em-bread.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f35e.svg"), none; }

.em-breast-feeding {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f931.png"); }

.em-breast-feeding.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f931.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f931.svg"), none; }

.em-bride_with_veil {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f470.png"); }

.em-bride_with_veil.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f470.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f470.svg"), none; }

.em-bridge_at_night {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f309.png"); }

.em-bridge_at_night.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f309.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f309.svg"), none; }

.em-briefcase {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4bc.png"); }

.em-briefcase.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4bc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4bc.svg"), none; }

.em-broccoli {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f966.png"); }

.em-broccoli.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f966.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f966.svg"), none; }

.em-broken_heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f494.png"); }

.em-broken_heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f494.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f494.svg"), none; }

.em-bug {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41b.png"); }

.em-bug.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f41b.svg"), none; }

.em-building_construction {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d7.png"); }

.em-building_construction.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3d7.svg"), none; }

.em-bulb {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a1.png"); }

.em-bulb.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4a1.svg"), none; }

.em-bullettrain_front {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f685.png"); }

.em-bullettrain_front.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f685.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f685.svg"), none; }

.em-bullettrain_side {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f684.png"); }

.em-bullettrain_side.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f684.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f684.svg"), none; }

.em-burrito {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32f.png"); }

.em-burrito.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f32f.svg"), none; }

.em-bus {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68c.png"); }

.em-bus.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f68c.svg"), none; }

.em-busstop {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68f.png"); }

.em-busstop.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f68f.svg"), none; }

.em-bust_in_silhouette {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f464.png"); }

.em-bust_in_silhouette.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f464.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f464.svg"), none; }

.em-busts_in_silhouette {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f465.png"); }

.em-busts_in_silhouette.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f465.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f465.svg"), none; }

.em-butterfly {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98b.png"); }

.em-butterfly.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f98b.svg"), none; }

.em-cactus {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f335.png"); }

.em-cactus.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f335.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f335.svg"), none; }

.em-cake {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f370.png"); }

.em-cake.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f370.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f370.svg"), none; }

.em-calendar {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c6.png"); }

.em-calendar.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4c6.svg"), none; }

.em-call_me_hand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f919.png"); }

.em-call_me_hand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f919.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f919.svg"), none; }

.em-calling {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f2.png"); }

.em-calling.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4f2.svg"), none; }

.em-camel {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42b.png"); }

.em-camel.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f42b.svg"), none; }

.em-camera {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f7.png"); }

.em-camera.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4f7.svg"), none; }

.em-camera_with_flash {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f8.png"); }

.em-camera_with_flash.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4f8.svg"), none; }

.em-camping {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d5.png"); }

.em-camping.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3d5.svg"), none; }

.em-cancer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264b.png"); }

.em-cancer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/264b.svg"), none; }

.em-candle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f56f.png"); }

.em-candle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f56f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f56f.svg"), none; }

.em-candy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36c.png"); }

.em-candy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f36c.svg"), none; }

.em-canned_food {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f96b.png"); }

.em-canned_food.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f96b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f96b.svg"), none; }

.em-canoe {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f6.png"); }

.em-canoe.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6f6.svg"), none; }

.em-capital_abcd {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f520.png"); }

.em-capital_abcd.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f520.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f520.svg"), none; }

.em-capricorn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2651.png"); }

.em-capricorn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2651.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2651.svg"), none; }

.em-car, .em-red_car {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f697.png"); }

.em-car.em-svg, .em-red_car.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f697.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f697.svg"), none; }

.em-card_file_box {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5c3.png"); }

.em-card_file_box.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5c3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5c3.svg"), none; }

.em-card_index {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c7.png"); }

.em-card_index.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4c7.svg"), none; }

.em-card_index_dividers {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5c2.png"); }

.em-card_index_dividers.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5c2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5c2.svg"), none; }

.em-carousel_horse {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a0.png"); }

.em-carousel_horse.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3a0.svg"), none; }

.em-carrot {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f955.png"); }

.em-carrot.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f955.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f955.svg"), none; }

.em-cat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f431.png"); }

.em-cat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f431.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f431.svg"), none; }

.em-cat2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f408.png"); }

.em-cat2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f408.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f408.svg"), none; }

.em-cd {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4bf.png"); }

.em-cd.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4bf.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4bf.svg"), none; }

.em-chains {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26d3.png"); }

.em-chains.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26d3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26d3.svg"), none; }

.em-champagne {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37e.png"); }

.em-champagne.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f37e.svg"), none; }

.em-chart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b9.png"); }

.em-chart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4b9.svg"), none; }

.em-chart_with_downwards_trend {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c9.png"); }

.em-chart_with_downwards_trend.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4c9.svg"), none; }

.em-chart_with_upwards_trend {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c8.png"); }

.em-chart_with_upwards_trend.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4c8.svg"), none; }

.em-checkered_flag {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c1.png"); }

.em-checkered_flag.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c1.svg"), none; }

.em-cheese_wedge {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9c0.png"); }

.em-cheese_wedge.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9c0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9c0.svg"), none; }

.em-cherries {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f352.png"); }

.em-cherries.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f352.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f352.svg"), none; }

.em-cherry_blossom {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f338.png"); }

.em-cherry_blossom.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f338.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f338.svg"), none; }

.em-chestnut {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f330.png"); }

.em-chestnut.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f330.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f330.svg"), none; }

.em-chicken {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f414.png"); }

.em-chicken.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f414.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f414.svg"), none; }

.em-child {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d2.png"); }

.em-child.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d2.svg"), none; }

.em-children_crossing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b8.png"); }

.em-children_crossing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b8.svg"), none; }

.em-chipmunk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43f.png"); }

.em-chipmunk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f43f.svg"), none; }

.em-chocolate_bar {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36b.png"); }

.em-chocolate_bar.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f36b.svg"), none; }

.em-chopsticks {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f962.png"); }

.em-chopsticks.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f962.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f962.svg"), none; }

.em-christmas_tree {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f384.png"); }

.em-christmas_tree.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f384.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f384.svg"), none; }

.em-church {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26ea.png"); }

.em-church.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26ea.svg"), none; }

.em-cinema {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a6.png"); }

.em-cinema.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3a6.svg"), none; }

.em-circus_tent {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3aa.png"); }

.em-circus_tent.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3aa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3aa.svg"), none; }

.em-city_sunrise {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f307.png"); }

.em-city_sunrise.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f307.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f307.svg"), none; }

.em-city_sunset {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f306.png"); }

.em-city_sunset.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f306.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f306.svg"), none; }

.em-cityscape {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d9.png"); }

.em-cityscape.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3d9.svg"), none; }

.em-cl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f191.png"); }

.em-cl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f191.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f191.svg"), none; }

.em-clap {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44f.png"); }

.em-clap.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f44f.svg"), none; }

.em-clapper {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ac.png"); }

.em-clapper.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ac.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ac.svg"), none; }

.em-classical_building {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3db.png"); }

.em-classical_building.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3db.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3db.svg"), none; }

.em-clinking_glasses {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f942.png"); }

.em-clinking_glasses.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f942.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f942.svg"), none; }

.em-clipboard {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4cb.png"); }

.em-clipboard.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4cb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4cb.svg"), none; }

.em-clock1 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f550.png"); }

.em-clock1.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f550.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f550.svg"), none; }

.em-clock10 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f559.png"); }

.em-clock10.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f559.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f559.svg"), none; }

.em-clock1030 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f565.png"); }

.em-clock1030.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f565.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f565.svg"), none; }

.em-clock11 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55a.png"); }

.em-clock11.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f55a.svg"), none; }

.em-clock1130 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f566.png"); }

.em-clock1130.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f566.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f566.svg"), none; }

.em-clock12 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55b.png"); }

.em-clock12.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f55b.svg"), none; }

.em-clock1230 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f567.png"); }

.em-clock1230.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f567.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f567.svg"), none; }

.em-clock130 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55c.png"); }

.em-clock130.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f55c.svg"), none; }

.em-clock2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f551.png"); }

.em-clock2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f551.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f551.svg"), none; }

.em-clock230 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55d.png"); }

.em-clock230.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f55d.svg"), none; }

.em-clock3 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f552.png"); }

.em-clock3.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f552.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f552.svg"), none; }

.em-clock330 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55e.png"); }

.em-clock330.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f55e.svg"), none; }

.em-clock4 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f553.png"); }

.em-clock4.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f553.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f553.svg"), none; }

.em-clock430 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55f.png"); }

.em-clock430.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f55f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f55f.svg"), none; }

.em-clock5 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f554.png"); }

.em-clock5.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f554.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f554.svg"), none; }

.em-clock530 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f560.png"); }

.em-clock530.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f560.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f560.svg"), none; }

.em-clock6 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f555.png"); }

.em-clock6.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f555.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f555.svg"), none; }

.em-clock630 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f561.png"); }

.em-clock630.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f561.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f561.svg"), none; }

.em-clock7 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f556.png"); }

.em-clock7.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f556.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f556.svg"), none; }

.em-clock730 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f562.png"); }

.em-clock730.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f562.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f562.svg"), none; }

.em-clock8 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f557.png"); }

.em-clock8.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f557.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f557.svg"), none; }

.em-clock830 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f563.png"); }

.em-clock830.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f563.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f563.svg"), none; }

.em-clock9 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f558.png"); }

.em-clock9.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f558.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f558.svg"), none; }

.em-clock930 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f564.png"); }

.em-clock930.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f564.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f564.svg"), none; }

.em-closed_book {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d5.png"); }

.em-closed_book.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4d5.svg"), none; }

.em-closed_lock_with_key {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f510.png"); }

.em-closed_lock_with_key.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f510.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f510.svg"), none; }

.em-closed_umbrella {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f302.png"); }

.em-closed_umbrella.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f302.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f302.svg"), none; }

.em-cloud {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2601.png"); }

.em-cloud.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2601.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2601.svg"), none; }

.em-clown_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f921.png"); }

.em-clown_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f921.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f921.svg"), none; }

.em-clubs {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2663.png"); }

.em-clubs.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2663.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2663.svg"), none; }

.em-cn, .em-flag-cn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f3.png"); }

.em-cn.em-svg, .em-flag-cn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f3.svg"), none; }

.em-coat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e5.png"); }

.em-coat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9e5.svg"), none; }

.em-cocktail {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f378.png"); }

.em-cocktail.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f378.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f378.svg"), none; }

.em-coconut {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f965.png"); }

.em-coconut.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f965.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f965.svg"), none; }

.em-coffee {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2615.png"); }

.em-coffee.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2615.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2615.svg"), none; }

.em-coffin {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26b0.png"); }

.em-coffin.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26b0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26b0.svg"), none; }

.em-cold_sweat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f630.png"); }

.em-cold_sweat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f630.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f630.svg"), none; }

.em-comet {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2604.png"); }

.em-comet.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2604.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2604.svg"), none; }

.em-compression {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5dc.png"); }

.em-compression.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5dc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5dc.svg"), none; }

.em-computer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4bb.png"); }

.em-computer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4bb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4bb.svg"), none; }

.em-confetti_ball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38a.png"); }

.em-confetti_ball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f38a.svg"), none; }

.em-confounded {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f616.png"); }

.em-confounded.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f616.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f616.svg"), none; }

.em-confused {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f615.png"); }

.em-confused.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f615.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f615.svg"), none; }

.em-congratulations {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/3297.png"); }

.em-congratulations.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/3297.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/3297.svg"), none; }

.em-construction {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a7.png"); }

.em-construction.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a7.svg"), none; }

.em-construction_worker {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f477.png"); }

.em-construction_worker.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f477.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f477.svg"), none; }

.em-control_knobs {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f39b.png"); }

.em-control_knobs.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f39b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f39b.svg"), none; }

.em-convenience_store {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ea.png"); }

.em-convenience_store.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ea.svg"), none; }

.em-cookie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36a.png"); }

.em-cookie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f36a.svg"), none; }

.em-cool {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f192.png"); }

.em-cool.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f192.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f192.svg"), none; }

.em-cop {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46e.png"); }

.em-cop.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f46e.svg"), none; }

.em-copyright {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/a9.png"); }

.em-copyright.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/a9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/a9.svg"), none; }

.em-corn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33d.png"); }

.em-corn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f33d.svg"), none; }

.em-couch_and_lamp {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6cb.png"); }

.em-couch_and_lamp.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6cb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6cb.svg"), none; }

.em-couple, .em-man_and_woman_holding_hands {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46b.png"); }

.em-couple.em-svg, .em-man_and_woman_holding_hands.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f46b.svg"), none; }

.em-couple_with_heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f491.png"); }

.em-couple_with_heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f491.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f491.svg"), none; }

.em-couplekiss {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48f.png"); }

.em-couplekiss.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f48f.svg"), none; }

.em-cow {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42e.png"); }

.em-cow.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f42e.svg"), none; }

.em-cow2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f404.png"); }

.em-cow2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f404.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f404.svg"), none; }

.em-crab {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f980.png"); }

.em-crab.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f980.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f980.svg"), none; }

.em-credit_card {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b3.png"); }

.em-credit_card.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4b3.svg"), none; }

.em-crescent_moon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f319.png"); }

.em-crescent_moon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f319.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f319.svg"), none; }

.em-cricket {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f997.png"); }

.em-cricket.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f997.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f997.svg"), none; }

.em-cricket_bat_and_ball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cf.png"); }

.em-cricket_bat_and_ball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cf.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3cf.svg"), none; }

.em-crocodile {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40a.png"); }

.em-crocodile.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f40a.svg"), none; }

.em-croissant {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f950.png"); }

.em-croissant.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f950.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f950.svg"), none; }

.em-crossed_fingers, .em-hand_with_index_and_middle_fingers_crossed {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91e.png"); }

.em-crossed_fingers.em-svg, .em-hand_with_index_and_middle_fingers_crossed.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f91e.svg"), none; }

.em-crossed_flags {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38c.png"); }

.em-crossed_flags.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f38c.svg"), none; }

.em-crossed_swords {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2694.png"); }

.em-crossed_swords.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2694.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2694.svg"), none; }

.em-crown {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f451.png"); }

.em-crown.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f451.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f451.svg"), none; }

.em-cry {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f622.png"); }

.em-cry.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f622.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f622.svg"), none; }

.em-crying_cat_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63f.png"); }

.em-crying_cat_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f63f.svg"), none; }

.em-crystal_ball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52e.png"); }

.em-crystal_ball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f52e.svg"), none; }

.em-cucumber {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f952.png"); }

.em-cucumber.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f952.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f952.svg"), none; }

.em-cup_with_straw {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f964.png"); }

.em-cup_with_straw.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f964.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f964.svg"), none; }

.em-cupid {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f498.png"); }

.em-cupid.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f498.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f498.svg"), none; }

.em-curling_stone {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f94c.png"); }

.em-curling_stone.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f94c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f94c.svg"), none; }

.em-curly_loop {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/27b0.png"); }

.em-curly_loop.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/27b0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/27b0.svg"), none; }

.em-currency_exchange {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b1.png"); }

.em-currency_exchange.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4b1.svg"), none; }

.em-curry {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35b.png"); }

.em-curry.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f35b.svg"), none; }

.em-custard {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36e.png"); }

.em-custard.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f36e.svg"), none; }

.em-customs {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c3.png"); }

.em-customs.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6c3.svg"), none; }

.em-cut_of_meat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f969.png"); }

.em-cut_of_meat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f969.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f969.svg"), none; }

.em-cyclone {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f300.png"); }

.em-cyclone.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f300.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f300.svg"), none; }

.em-dagger_knife {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5e1.png"); }

.em-dagger_knife.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5e1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5e1.svg"), none; }

.em-dancer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f483.png"); }

.em-dancer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f483.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f483.svg"), none; }

.em-dancers {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46f.png"); }

.em-dancers.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f46f.svg"), none; }

.em-dango {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f361.png"); }

.em-dango.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f361.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f361.svg"), none; }

.em-dark_sunglasses {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f576.png"); }

.em-dark_sunglasses.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f576.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f576.svg"), none; }

.em-dart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3af.png"); }

.em-dart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3af.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3af.svg"), none; }

.em-dash {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a8.png"); }

.em-dash.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4a8.svg"), none; }

.em-date {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c5.png"); }

.em-date.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4c5.svg"), none; }

.em-de, .em-flag-de {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1ea.png"); }

.em-de.em-svg, .em-flag-de.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ea.svg"), none; }

.em-deciduous_tree {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f333.png"); }

.em-deciduous_tree.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f333.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f333.svg"), none; }

.em-deer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98c.png"); }

.em-deer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f98c.svg"), none; }

.em-department_store {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ec.png"); }

.em-department_store.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ec.svg"), none; }

.em-derelict_house_building {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3da.png"); }

.em-derelict_house_building.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3da.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3da.svg"), none; }

.em-desert {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3dc.png"); }

.em-desert.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3dc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3dc.svg"), none; }

.em-desert_island {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3dd.png"); }

.em-desert_island.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3dd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3dd.svg"), none; }

.em-desktop_computer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5a5.png"); }

.em-desktop_computer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5a5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5a5.svg"), none; }

.em-diamond_shape_with_a_dot_inside {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a0.png"); }

.em-diamond_shape_with_a_dot_inside.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4a0.svg"), none; }

.em-diamonds {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2666.png"); }

.em-diamonds.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2666.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2666.svg"), none; }

.em-disappointed {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61e.png"); }

.em-disappointed.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f61e.svg"), none; }

.em-disappointed_relieved {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f625.png"); }

.em-disappointed_relieved.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f625.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f625.svg"), none; }

.em-dizzy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ab.png"); }

.em-dizzy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ab.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ab.svg"), none; }

.em-dizzy_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f635.png"); }

.em-dizzy_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f635.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f635.svg"), none; }

.em-do_not_litter {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6af.png"); }

.em-do_not_litter.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6af.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6af.svg"), none; }

.em-dog {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f436.png"); }

.em-dog.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f436.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f436.svg"), none; }

.em-dog2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f415.png"); }

.em-dog2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f415.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f415.svg"), none; }

.em-dollar {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b5.png"); }

.em-dollar.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4b5.svg"), none; }

.em-dolls {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38e.png"); }

.em-dolls.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f38e.svg"), none; }

.em-dolphin, .em-flipper {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42c.png"); }

.em-dolphin.em-svg, .em-flipper.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f42c.svg"), none; }

.em-door {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6aa.png"); }

.em-door.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6aa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6aa.svg"), none; }

.em-double_vertical_bar {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f8.png"); }

.em-double_vertical_bar.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23f8.svg"), none; }

.em-doughnut {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f369.png"); }

.em-doughnut.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f369.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f369.svg"), none; }

.em-dove_of_peace {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f54a.png"); }

.em-dove_of_peace.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f54a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f54a.svg"), none; }

.em-dragon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f409.png"); }

.em-dragon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f409.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f409.svg"), none; }

.em-dragon_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f432.png"); }

.em-dragon_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f432.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f432.svg"), none; }

.em-dress {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f457.png"); }

.em-dress.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f457.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f457.svg"), none; }

.em-dromedary_camel {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42a.png"); }

.em-dromedary_camel.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f42a.svg"), none; }

.em-drooling_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f924.png"); }

.em-drooling_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f924.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f924.svg"), none; }

.em-droplet {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a7.png"); }

.em-droplet.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4a7.svg"), none; }

.em-drum_with_drumsticks {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f941.png"); }

.em-drum_with_drumsticks.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f941.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f941.svg"), none; }

.em-duck {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f986.png"); }

.em-duck.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f986.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f986.svg"), none; }

.em-dumpling {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95f.png"); }

.em-dumpling.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f95f.svg"), none; }

.em-dvd {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c0.png"); }

.em-dvd.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4c0.svg"), none; }

.em-e-mail {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e7.png"); }

.em-e-mail.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4e7.svg"), none; }

.em-eagle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f985.png"); }

.em-eagle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f985.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f985.svg"), none; }

.em-ear {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f442.png"); }

.em-ear.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f442.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f442.svg"), none; }

.em-ear_of_rice {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33e.png"); }

.em-ear_of_rice.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f33e.svg"), none; }

.em-earth_africa {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30d.png"); }

.em-earth_africa.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f30d.svg"), none; }

.em-earth_americas {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30e.png"); }

.em-earth_americas.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f30e.svg"), none; }

.em-earth_asia {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30f.png"); }

.em-earth_asia.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f30f.svg"), none; }

.em-egg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95a.png"); }

.em-egg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f95a.svg"), none; }

.em-eggplant {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f346.png"); }

.em-eggplant.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f346.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f346.svg"), none; }

.em-eight {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/38-20e3.png"); }

.em-eight.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/38-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/38-20e3.svg"), none; }

.em-eight_pointed_black_star {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2734.png"); }

.em-eight_pointed_black_star.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2734.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2734.svg"), none; }

.em-eight_spoked_asterisk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2733.png"); }

.em-eight_spoked_asterisk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2733.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2733.svg"), none; }

.em-eject {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23cf.png"); }

.em-eject.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23cf.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23cf.svg"), none; }

.em-electric_plug {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50c.png"); }

.em-electric_plug.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f50c.svg"), none; }

.em-elephant {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f418.png"); }

.em-elephant.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f418.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f418.svg"), none; }

.em-elf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dd.png"); }

.em-elf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9dd.svg"), none; }

.em-email, .em-envelope {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2709.png"); }

.em-email.em-svg, .em-envelope.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2709.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2709.svg"), none; }

.em-end {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51a.png"); }

.em-end.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f51a.svg"), none; }

.em-envelope_with_arrow {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e9.png"); }

.em-envelope_with_arrow.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4e9.svg"), none; }

.em-es, .em-flag-es {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1f8.png"); }

.em-es.em-svg, .em-flag-es.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f8.svg"), none; }

.em-euro {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b6.png"); }

.em-euro.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4b6.svg"), none; }

.em-european_castle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f0.png"); }

.em-european_castle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3f0.svg"), none; }

.em-european_post_office {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e4.png"); }

.em-european_post_office.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3e4.svg"), none; }

.em-evergreen_tree {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f332.png"); }

.em-evergreen_tree.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f332.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f332.svg"), none; }

.em-exclamation, .em-heavy_exclamation_mark {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2757.png"); }

.em-exclamation.em-svg, .em-heavy_exclamation_mark.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2757.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2757.svg"), none; }

.em-exploding_head, .em-shocked_face_with_exploding_head {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92f.png"); }

.em-exploding_head.em-svg, .em-shocked_face_with_exploding_head.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f92f.svg"), none; }

.em-expressionless {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f611.png"); }

.em-expressionless.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f611.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f611.svg"), none; }

.em-eye {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f441.png"); }

.em-eye.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f441.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f441.svg"), none; }

.em-eyeglasses {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f453.png"); }

.em-eyeglasses.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f453.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f453.svg"), none; }

.em-eyes {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f440.png"); }

.em-eyes.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f440.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f440.svg"), none; }

.em-face_palm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f926.png"); }

.em-face_palm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f926.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f926.svg"), none; }

.em-face_vomiting, .em-face_with_open_mouth_vomiting {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92e.png"); }

.em-face_vomiting.em-svg, .em-face_with_open_mouth_vomiting.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f92e.svg"), none; }

.em-face_with_cowboy_hat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f920.png"); }

.em-face_with_cowboy_hat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f920.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f920.svg"), none; }

.em-face_with_hand_over_mouth, .em-smiling_face_with_smiling_eyes_and_hand_covering_mouth {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92d.png"); }

.em-face_with_hand_over_mouth.em-svg, .em-smiling_face_with_smiling_eyes_and_hand_covering_mouth.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f92d.svg"), none; }

.em-face_with_head_bandage {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f915.png"); }

.em-face_with_head_bandage.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f915.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f915.svg"), none; }

.em-face_with_monocle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d0.png"); }

.em-face_with_monocle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d0.svg"), none; }

.em-face_with_raised_eyebrow, .em-face_with_one_eyebrow_raised {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f928.png"); }

.em-face_with_raised_eyebrow.em-svg, .em-face_with_one_eyebrow_raised.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f928.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f928.svg"), none; }

.em-face_with_rolling_eyes {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f644.png"); }

.em-face_with_rolling_eyes.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f644.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f644.svg"), none; }

.em-face_with_symbols_on_mouth, .em-serious_face_with_symbols_covering_mouth {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92c.png"); }

.em-face_with_symbols_on_mouth.em-svg, .em-serious_face_with_symbols_covering_mouth.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f92c.svg"), none; }

.em-face_with_thermometer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f912.png"); }

.em-face_with_thermometer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f912.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f912.svg"), none; }

.em-facepunch, .em-punch {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44a.png"); }

.em-facepunch.em-svg, .em-punch.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f44a.svg"), none; }

.em-factory {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ed.png"); }

.em-factory.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ed.svg"), none; }

.em-fairy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9da.png"); }

.em-fairy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9da.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9da.svg"), none; }

.em-fallen_leaf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f342.png"); }

.em-fallen_leaf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f342.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f342.svg"), none; }

.em-family, .em-man-woman-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46a.png"); }

.em-family.em-svg, .em-man-woman-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f46a.svg"), none; }

.em-fast_forward {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23e9.png"); }

.em-fast_forward.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23e9.svg"), none; }

.em-fax {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e0.png"); }

.em-fax.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4e0.svg"), none; }

.em-fearful {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f628.png"); }

.em-fearful.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f628.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f628.svg"), none; }

.em-feet, .em-paw_prints {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43e.png"); }

.em-feet.em-svg, .em-paw_prints.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f43e.svg"), none; }

.em-female-artist {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f3a8.png"); }

.em-female-artist.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f3a8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f3a8.svg"), none; }

.em-female-astronaut {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f680.png"); }

.em-female-astronaut.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f680.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f680.svg"), none; }

.em-female-construction-worker {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f477-200d-2640-fe0f.png"); }

.em-female-construction-worker.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f477-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f477-200d-2640-fe0f.svg"), none; }

.em-female-cook {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f373.png"); }

.em-female-cook.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f373.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f373.svg"), none; }

.em-female-detective {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f575-fe0f-200d-2640-fe0f.png"); }

.em-female-detective.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f575-fe0f-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f575-fe0f-200d-2640-fe0f.svg"), none; }

.em-female-doctor {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2695-fe0f.png"); }

.em-female-doctor.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2695-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-2695-fe0f.svg"), none; }

.em-female-factory-worker {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f3ed.png"); }

.em-female-factory-worker.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f3ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f3ed.svg"), none; }

.em-female-farmer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f33e.png"); }

.em-female-farmer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f33e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f33e.svg"), none; }

.em-female-firefighter {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f692.png"); }

.em-female-firefighter.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f692.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f692.svg"), none; }

.em-female-guard {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f482-200d-2640-fe0f.png"); }

.em-female-guard.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f482-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f482-200d-2640-fe0f.svg"), none; }

.em-female-judge {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2696-fe0f.png"); }

.em-female-judge.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2696-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-2696-fe0f.svg"), none; }

.em-female-mechanic {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f527.png"); }

.em-female-mechanic.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f527.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f527.svg"), none; }

.em-female-office-worker {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f4bc.png"); }

.em-female-office-worker.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f4bc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f4bc.svg"), none; }

.em-female-pilot {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2708-fe0f.png"); }

.em-female-pilot.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2708-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-2708-fe0f.svg"), none; }

.em-female-police-officer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46e-200d-2640-fe0f.png"); }

.em-female-police-officer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46e-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f46e-200d-2640-fe0f.svg"), none; }

.em-female-scientist {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f52c.png"); }

.em-female-scientist.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f52c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f52c.svg"), none; }

.em-female-singer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f3a4.png"); }

.em-female-singer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f3a4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f3a4.svg"), none; }

.em-female-student {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f393.png"); }

.em-female-student.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f393.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f393.svg"), none; }

.em-female-teacher {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f3eb.png"); }

.em-female-teacher.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f3eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f3eb.svg"), none; }

.em-female-technologist {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f4bb.png"); }

.em-female-technologist.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f4bb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f4bb.svg"), none; }

.em-female_elf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dd-200d-2640-fe0f.png"); }

.em-female_elf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dd-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9dd-200d-2640-fe0f.svg"), none; }

.em-female_fairy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9da-200d-2640-fe0f.png"); }

.em-female_fairy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9da-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9da-200d-2640-fe0f.svg"), none; }

.em-female_genie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9de-200d-2640-fe0f.png"); }

.em-female_genie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9de-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9de-200d-2640-fe0f.svg"), none; }

.em-female_mage {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d9-200d-2640-fe0f.png"); }

.em-female_mage.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d9-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d9-200d-2640-fe0f.svg"), none; }

.em-female_sign {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2640.png"); }

.em-female_sign.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2640.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2640.svg"), none; }

.em-female_vampire {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9db-200d-2640-fe0f.png"); }

.em-female_vampire.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9db-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9db-200d-2640-fe0f.svg"), none; }

.em-female_zombie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9df-200d-2640-fe0f.png"); }

.em-female_zombie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9df-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9df-200d-2640-fe0f.svg"), none; }

.em-fencer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93a.png"); }

.em-fencer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f93a.svg"), none; }

.em-ferris_wheel {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a1.png"); }

.em-ferris_wheel.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3a1.svg"), none; }

.em-ferry {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f4.png"); }

.em-ferry.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26f4.svg"), none; }

.em-field_hockey_stick_and_ball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d1.png"); }

.em-field_hockey_stick_and_ball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3d1.svg"), none; }

.em-file_cabinet {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5c4.png"); }

.em-file_cabinet.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5c4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5c4.svg"), none; }

.em-file_folder {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c1.png"); }

.em-file_folder.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4c1.svg"), none; }

.em-film_frames {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f39e.png"); }

.em-film_frames.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f39e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f39e.svg"), none; }

.em-film_projector {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4fd.png"); }

.em-film_projector.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4fd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4fd.svg"), none; }

.em-fire {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f525.png"); }

.em-fire.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f525.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f525.svg"), none; }

.em-fire_engine {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f692.png"); }

.em-fire_engine.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f692.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f692.svg"), none; }

.em-fireworks {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f386.png"); }

.em-fireworks.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f386.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f386.svg"), none; }

.em-first_place_medal {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f947.png"); }

.em-first_place_medal.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f947.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f947.svg"), none; }

.em-first_quarter_moon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f313.png"); }

.em-first_quarter_moon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f313.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f313.svg"), none; }

.em-first_quarter_moon_with_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31b.png"); }

.em-first_quarter_moon_with_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f31b.svg"), none; }

.em-fish {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41f.png"); }

.em-fish.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f41f.svg"), none; }

.em-fish_cake {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f365.png"); }

.em-fish_cake.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f365.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f365.svg"), none; }

.em-fishing_pole_and_fish {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a3.png"); }

.em-fishing_pole_and_fish.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3a3.svg"), none; }

.em-fist {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/270a.png"); }

.em-fist.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/270a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/270a.svg"), none; }

.em-five {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/35-20e3.png"); }

.em-five.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/35-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/35-20e3.svg"), none; }

.em-flag-ac {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1e8.png"); }

.em-flag-ac.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1e8.svg"), none; }

.em-flag-ad {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1e9.png"); }

.em-flag-ad.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1e9.svg"), none; }

.em-flag-ae {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1ea.png"); }

.em-flag-ae.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ea.svg"), none; }

.em-flag-af {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1eb.png"); }

.em-flag-af.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1eb.svg"), none; }

.em-flag-ag {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1ec.png"); }

.em-flag-ag.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ec.svg"), none; }

.em-flag-ai {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1ee.png"); }

.em-flag-ai.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ee.svg"), none; }

.em-flag-al {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f1.png"); }

.em-flag-al.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f1.svg"), none; }

.em-flag-am {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f2.png"); }

.em-flag-am.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f2.svg"), none; }

.em-flag-ao {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f4.png"); }

.em-flag-ao.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f4.svg"), none; }

.em-flag-aq {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f6.png"); }

.em-flag-aq.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f6.svg"), none; }

.em-flag-ar {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f7.png"); }

.em-flag-ar.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f7.svg"), none; }

.em-flag-as {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f8.png"); }

.em-flag-as.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f8.svg"), none; }

.em-flag-at {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f9.png"); }

.em-flag-at.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1f9.svg"), none; }

.em-flag-au {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1fa.png"); }

.em-flag-au.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fa.svg"), none; }

.em-flag-aw {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1fc.png"); }

.em-flag-aw.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fc.svg"), none; }

.em-flag-ax {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1fd.png"); }

.em-flag-ax.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1fd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1fd.svg"), none; }

.em-flag-az {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1ff.png"); }

.em-flag-az.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e6-1f1ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e6-1f1ff.svg"), none; }

.em-flag-ba {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1e6.png"); }

.em-flag-ba.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e6.svg"), none; }

.em-flag-bb {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1e7.png"); }

.em-flag-bb.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1e7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e7.svg"), none; }

.em-flag-bd {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1e9.png"); }

.em-flag-bd.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1e9.svg"), none; }

.em-flag-be {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ea.png"); }

.em-flag-be.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ea.svg"), none; }

.em-flag-bf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1eb.png"); }

.em-flag-bf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1eb.svg"), none; }

.em-flag-bg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ec.png"); }

.em-flag-bg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ec.svg"), none; }

.em-flag-bh {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ed.png"); }

.em-flag-bh.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ed.svg"), none; }

.em-flag-bi {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ee.png"); }

.em-flag-bi.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ee.svg"), none; }

.em-flag-bj {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ef.png"); }

.em-flag-bj.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ef.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ef.svg"), none; }

.em-flag-bl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f1.png"); }

.em-flag-bl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f1.svg"), none; }

.em-flag-bm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f2.png"); }

.em-flag-bm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f2.svg"), none; }

.em-flag-bn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f3.png"); }

.em-flag-bn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f3.svg"), none; }

.em-flag-bo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f4.png"); }

.em-flag-bo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f4.svg"), none; }

.em-flag-bq {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f6.png"); }

.em-flag-bq.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f6.svg"), none; }

.em-flag-br {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f7.png"); }

.em-flag-br.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f7.svg"), none; }

.em-flag-bs {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f8.png"); }

.em-flag-bs.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f8.svg"), none; }

.em-flag-bt {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f9.png"); }

.em-flag-bt.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1f9.svg"), none; }

.em-flag-bv {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1fb.png"); }

.em-flag-bv.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1fb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fb.svg"), none; }

.em-flag-bw {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1fc.png"); }

.em-flag-bw.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fc.svg"), none; }

.em-flag-by {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1fe.png"); }

.em-flag-by.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1fe.svg"), none; }

.em-flag-bz {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ff.png"); }

.em-flag-bz.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e7-1f1ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e7-1f1ff.svg"), none; }

.em-flag-ca {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1e6.png"); }

.em-flag-ca.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e6.svg"), none; }

.em-flag-cc {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1e8.png"); }

.em-flag-cc.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e8.svg"), none; }

.em-flag-cd {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1e9.png"); }

.em-flag-cd.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1e9.svg"), none; }

.em-flag-cf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1eb.png"); }

.em-flag-cf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1eb.svg"), none; }

.em-flag-cg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1ec.png"); }

.em-flag-cg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ec.svg"), none; }

.em-flag-ch {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1ed.png"); }

.em-flag-ch.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ed.svg"), none; }

.em-flag-ci {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1ee.png"); }

.em-flag-ci.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ee.svg"), none; }

.em-flag-ck {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f0.png"); }

.em-flag-ck.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f0.svg"), none; }

.em-flag-cl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f1.png"); }

.em-flag-cl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f1.svg"), none; }

.em-flag-cm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f2.png"); }

.em-flag-cm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f2.svg"), none; }

.em-flag-co {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f4.png"); }

.em-flag-co.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f4.svg"), none; }

.em-flag-cp {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f5.png"); }

.em-flag-cp.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f5.svg"), none; }

.em-flag-cr {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f7.png"); }

.em-flag-cr.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1f7.svg"), none; }

.em-flag-cu {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1fa.png"); }

.em-flag-cu.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fa.svg"), none; }

.em-flag-cv {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1fb.png"); }

.em-flag-cv.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1fb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fb.svg"), none; }

.em-flag-cw {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1fc.png"); }

.em-flag-cw.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fc.svg"), none; }

.em-flag-cx {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1fd.png"); }

.em-flag-cx.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1fd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fd.svg"), none; }

.em-flag-cy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1fe.png"); }

.em-flag-cy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1fe.svg"), none; }

.em-flag-cz {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1ff.png"); }

.em-flag-cz.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e8-1f1ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e8-1f1ff.svg"), none; }

.em-flag-dg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1ec.png"); }

.em-flag-dg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ec.svg"), none; }

.em-flag-dj {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1ef.png"); }

.em-flag-dj.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1ef.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ef.svg"), none; }

.em-flag-dk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1f0.png"); }

.em-flag-dk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f0.svg"), none; }

.em-flag-dm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1f2.png"); }

.em-flag-dm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f2.svg"), none; }

.em-flag-do {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1f4.png"); }

.em-flag-do.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1f4.svg"), none; }

.em-flag-dz {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1ff.png"); }

.em-flag-dz.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1e9-1f1ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1e9-1f1ff.svg"), none; }

.em-flag-ea {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1e6.png"); }

.em-flag-ea.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1e6.svg"), none; }

.em-flag-ec {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1e8.png"); }

.em-flag-ec.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1e8.svg"), none; }

.em-flag-ee {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1ea.png"); }

.em-flag-ee.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ea.svg"), none; }

.em-flag-eg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1ec.png"); }

.em-flag-eg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ec.svg"), none; }

.em-flag-eh {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1ed.png"); }

.em-flag-eh.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1ed.svg"), none; }

.em-flag-england {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.png"); }

.em-flag-england.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.svg"), none; }

.em-flag-er {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1f7.png"); }

.em-flag-er.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f7.svg"), none; }

.em-flag-et {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1f9.png"); }

.em-flag-et.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1f9.svg"), none; }

.em-flag-eu {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1fa.png"); }

.em-flag-eu.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ea-1f1fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ea-1f1fa.svg"), none; }

.em-flag-fi {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1ee.png"); }

.em-flag-fi.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1ee.svg"), none; }

.em-flag-fj {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1ef.png"); }

.em-flag-fj.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1ef.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1ef.svg"), none; }

.em-flag-fk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1f0.png"); }

.em-flag-fk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f0.svg"), none; }

.em-flag-fm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1f2.png"); }

.em-flag-fm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f2.svg"), none; }

.em-flag-fo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1f4.png"); }

.em-flag-fo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f4.svg"), none; }

.em-flag-ga {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1e6.png"); }

.em-flag-ga.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e6.svg"), none; }

.em-flag-gd {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1e9.png"); }

.em-flag-gd.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e9.svg"), none; }

.em-flag-ge {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1ea.png"); }

.em-flag-ge.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ea.svg"), none; }

.em-flag-gf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1eb.png"); }

.em-flag-gf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1eb.svg"), none; }

.em-flag-gg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1ec.png"); }

.em-flag-gg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ec.svg"), none; }

.em-flag-gh {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1ed.png"); }

.em-flag-gh.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ed.svg"), none; }

.em-flag-gi {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1ee.png"); }

.em-flag-gi.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1ee.svg"), none; }

.em-flag-gl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f1.png"); }

.em-flag-gl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f1.svg"), none; }

.em-flag-gm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f2.png"); }

.em-flag-gm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f2.svg"), none; }

.em-flag-gn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f3.png"); }

.em-flag-gn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f3.svg"), none; }

.em-flag-gp {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f5.png"); }

.em-flag-gp.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f5.svg"), none; }

.em-flag-gq {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f6.png"); }

.em-flag-gq.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f6.svg"), none; }

.em-flag-gr {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f7.png"); }

.em-flag-gr.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f7.svg"), none; }

.em-flag-gs {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f8.png"); }

.em-flag-gs.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f8.svg"), none; }

.em-flag-gt {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f9.png"); }

.em-flag-gt.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1f9.svg"), none; }

.em-flag-gu {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1fa.png"); }

.em-flag-gu.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fa.svg"), none; }

.em-flag-gw {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1fc.png"); }

.em-flag-gw.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fc.svg"), none; }

.em-flag-gy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1fe.png"); }

.em-flag-gy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1fe.svg"), none; }

.em-flag-hk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1f0.png"); }

.em-flag-hk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f0.svg"), none; }

.em-flag-hm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1f2.png"); }

.em-flag-hm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f2.svg"), none; }

.em-flag-hn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1f3.png"); }

.em-flag-hn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f3.svg"), none; }

.em-flag-hr {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1f7.png"); }

.em-flag-hr.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f7.svg"), none; }

.em-flag-ht {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1f9.png"); }

.em-flag-ht.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1f9.svg"), none; }

.em-flag-hu {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1fa.png"); }

.em-flag-hu.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ed-1f1fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ed-1f1fa.svg"), none; }

.em-flag-ic {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1e8.png"); }

.em-flag-ic.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1e8.svg"), none; }

.em-flag-id {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1e9.png"); }

.em-flag-id.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1e9.svg"), none; }

.em-flag-ie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1ea.png"); }

.em-flag-ie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1ea.svg"), none; }

.em-flag-il {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f1.png"); }

.em-flag-il.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f1.svg"), none; }

.em-flag-im {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f2.png"); }

.em-flag-im.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f2.svg"), none; }

.em-flag-in {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f3.png"); }

.em-flag-in.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f3.svg"), none; }

.em-flag-io {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f4.png"); }

.em-flag-io.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f4.svg"), none; }

.em-flag-iq {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f6.png"); }

.em-flag-iq.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f6.svg"), none; }

.em-flag-ir {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f7.png"); }

.em-flag-ir.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f7.svg"), none; }

.em-flag-is {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f8.png"); }

.em-flag-is.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f8.svg"), none; }

.em-flag-je {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ef-1f1ea.png"); }

.em-flag-je.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ef-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1ea.svg"), none; }

.em-flag-jm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ef-1f1f2.png"); }

.em-flag-jm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ef-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f2.svg"), none; }

.em-flag-jo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ef-1f1f4.png"); }

.em-flag-jo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ef-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f4.svg"), none; }

.em-flag-ke {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1ea.png"); }

.em-flag-ke.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ea.svg"), none; }

.em-flag-kg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1ec.png"); }

.em-flag-kg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ec.svg"), none; }

.em-flag-kh {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1ed.png"); }

.em-flag-kh.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ed.svg"), none; }

.em-flag-ki {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1ee.png"); }

.em-flag-ki.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ee.svg"), none; }

.em-flag-km {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1f2.png"); }

.em-flag-km.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f2.svg"), none; }

.em-flag-kn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1f3.png"); }

.em-flag-kn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f3.svg"), none; }

.em-flag-kp {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1f5.png"); }

.em-flag-kp.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1f5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f5.svg"), none; }

.em-flag-kw {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1fc.png"); }

.em-flag-kw.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1fc.svg"), none; }

.em-flag-ky {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1fe.png"); }

.em-flag-ky.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1fe.svg"), none; }

.em-flag-kz {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1ff.png"); }

.em-flag-kz.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1ff.svg"), none; }

.em-flag-la {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1e6.png"); }

.em-flag-la.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e6.svg"), none; }

.em-flag-lb {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1e7.png"); }

.em-flag-lb.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1e7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e7.svg"), none; }

.em-flag-lc {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1e8.png"); }

.em-flag-lc.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1e8.svg"), none; }

.em-flag-li {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1ee.png"); }

.em-flag-li.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1ee.svg"), none; }

.em-flag-lk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1f0.png"); }

.em-flag-lk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f0.svg"), none; }

.em-flag-lr {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1f7.png"); }

.em-flag-lr.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f7.svg"), none; }

.em-flag-ls {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1f8.png"); }

.em-flag-ls.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f8.svg"), none; }

.em-flag-lt {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1f9.png"); }

.em-flag-lt.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1f9.svg"), none; }

.em-flag-lu {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1fa.png"); }

.em-flag-lu.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fa.svg"), none; }

.em-flag-lv {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1fb.png"); }

.em-flag-lv.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1fb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fb.svg"), none; }

.em-flag-ly {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1fe.png"); }

.em-flag-ly.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f1-1f1fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f1-1f1fe.svg"), none; }

.em-flag-ma {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1e6.png"); }

.em-flag-ma.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e6.svg"), none; }

.em-flag-mc {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1e8.png"); }

.em-flag-mc.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e8.svg"), none; }

.em-flag-md {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1e9.png"); }

.em-flag-md.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1e9.svg"), none; }

.em-flag-me {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1ea.png"); }

.em-flag-me.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ea.svg"), none; }

.em-flag-mf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1eb.png"); }

.em-flag-mf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1eb.svg"), none; }

.em-flag-mg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1ec.png"); }

.em-flag-mg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ec.svg"), none; }

.em-flag-mh {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1ed.png"); }

.em-flag-mh.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ed.svg"), none; }

.em-flag-mk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f0.png"); }

.em-flag-mk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f0.svg"), none; }

.em-flag-ml {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f1.png"); }

.em-flag-ml.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f1.svg"), none; }

.em-flag-mm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f2.png"); }

.em-flag-mm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f2.svg"), none; }

.em-flag-mn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f3.png"); }

.em-flag-mn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f3.svg"), none; }

.em-flag-mo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f4.png"); }

.em-flag-mo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f4.svg"), none; }

.em-flag-mp {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f5.png"); }

.em-flag-mp.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f5.svg"), none; }

.em-flag-mq {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f6.png"); }

.em-flag-mq.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f6.svg"), none; }

.em-flag-mr {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f7.png"); }

.em-flag-mr.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f7.svg"), none; }

.em-flag-ms {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f8.png"); }

.em-flag-ms.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f8.svg"), none; }

.em-flag-mt {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f9.png"); }

.em-flag-mt.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1f9.svg"), none; }

.em-flag-mu {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1fa.png"); }

.em-flag-mu.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fa.svg"), none; }

.em-flag-mv {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1fb.png"); }

.em-flag-mv.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1fb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fb.svg"), none; }

.em-flag-mw {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1fc.png"); }

.em-flag-mw.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fc.svg"), none; }

.em-flag-mx {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1fd.png"); }

.em-flag-mx.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1fd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fd.svg"), none; }

.em-flag-my {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1fe.png"); }

.em-flag-my.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1fe.svg"), none; }

.em-flag-mz {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1ff.png"); }

.em-flag-mz.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f2-1f1ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f2-1f1ff.svg"), none; }

.em-flag-na {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1e6.png"); }

.em-flag-na.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1e6.svg"), none; }

.em-flag-nc {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1e8.png"); }

.em-flag-nc.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1e8.svg"), none; }

.em-flag-ne {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1ea.png"); }

.em-flag-ne.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ea.svg"), none; }

.em-flag-nf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1eb.png"); }

.em-flag-nf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1eb.svg"), none; }

.em-flag-ng {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1ec.png"); }

.em-flag-ng.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ec.svg"), none; }

.em-flag-ni {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1ee.png"); }

.em-flag-ni.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ee.svg"), none; }

.em-flag-nl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1f1.png"); }

.em-flag-nl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f1.svg"), none; }

.em-flag-no {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1f4.png"); }

.em-flag-no.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f4.svg"), none; }

.em-flag-np {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1f5.png"); }

.em-flag-np.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1f5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f5.svg"), none; }

.em-flag-nr {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1f7.png"); }

.em-flag-nr.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1f7.svg"), none; }

.em-flag-nu {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1fa.png"); }

.em-flag-nu.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1fa.svg"), none; }

.em-flag-nz {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1ff.png"); }

.em-flag-nz.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f3-1f1ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f3-1f1ff.svg"), none; }

.em-flag-om {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f4-1f1f2.png"); }

.em-flag-om.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f4-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f4-1f1f2.svg"), none; }

.em-flag-pa {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1e6.png"); }

.em-flag-pa.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1e6.svg"), none; }

.em-flag-pe {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1ea.png"); }

.em-flag-pe.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ea.svg"), none; }

.em-flag-pf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1eb.png"); }

.em-flag-pf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1eb.svg"), none; }

.em-flag-pg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1ec.png"); }

.em-flag-pg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ec.svg"), none; }

.em-flag-ph {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1ed.png"); }

.em-flag-ph.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1ed.svg"), none; }

.em-flag-pk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f0.png"); }

.em-flag-pk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f0.svg"), none; }

.em-flag-pl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f1.png"); }

.em-flag-pl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f1.svg"), none; }

.em-flag-pm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f2.png"); }

.em-flag-pm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f2.svg"), none; }

.em-flag-pn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f3.png"); }

.em-flag-pn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f3.svg"), none; }

.em-flag-pr {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f7.png"); }

.em-flag-pr.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f7.svg"), none; }

.em-flag-ps {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f8.png"); }

.em-flag-ps.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f8.svg"), none; }

.em-flag-pt {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f9.png"); }

.em-flag-pt.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1f9.svg"), none; }

.em-flag-pw {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1fc.png"); }

.em-flag-pw.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1fc.svg"), none; }

.em-flag-py {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1fe.png"); }

.em-flag-py.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f5-1f1fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f5-1f1fe.svg"), none; }

.em-flag-qa {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f6-1f1e6.png"); }

.em-flag-qa.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f6-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f6-1f1e6.svg"), none; }

.em-flag-re {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f7-1f1ea.png"); }

.em-flag-re.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f7-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1ea.svg"), none; }

.em-flag-ro {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f7-1f1f4.png"); }

.em-flag-ro.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f7-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1f4.svg"), none; }

.em-flag-rs {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f7-1f1f8.png"); }

.em-flag-rs.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f7-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1f8.svg"), none; }

.em-flag-rw {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f7-1f1fc.png"); }

.em-flag-rw.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f7-1f1fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1fc.svg"), none; }

.em-flag-sa {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1e6.png"); }

.em-flag-sa.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e6.svg"), none; }

.em-flag-sb {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1e7.png"); }

.em-flag-sb.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1e7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e7.svg"), none; }

.em-flag-sc {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1e8.png"); }

.em-flag-sc.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e8.svg"), none; }

.em-flag-scotland {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.png"); }

.em-flag-scotland.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.svg"), none; }

.em-flag-sd {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1e9.png"); }

.em-flag-sd.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1e9.svg"), none; }

.em-flag-se {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ea.png"); }

.em-flag-se.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ea.svg"), none; }

.em-flag-sg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ec.png"); }

.em-flag-sg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ec.svg"), none; }

.em-flag-sh {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ed.png"); }

.em-flag-sh.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ed.svg"), none; }

.em-flag-si {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ee.png"); }

.em-flag-si.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ee.svg"), none; }

.em-flag-sj {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ef.png"); }

.em-flag-sj.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ef.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ef.svg"), none; }

.em-flag-sk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f0.png"); }

.em-flag-sk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f0.svg"), none; }

.em-flag-sl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f1.png"); }

.em-flag-sl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f1.svg"), none; }

.em-flag-sm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f2.png"); }

.em-flag-sm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f2.svg"), none; }

.em-flag-sn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f3.png"); }

.em-flag-sn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f3.svg"), none; }

.em-flag-so {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f4.png"); }

.em-flag-so.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f4.svg"), none; }

.em-flag-sr {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f7.png"); }

.em-flag-sr.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f7.svg"), none; }

.em-flag-ss {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f8.png"); }

.em-flag-ss.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f8.svg"), none; }

.em-flag-st {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f9.png"); }

.em-flag-st.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1f9.svg"), none; }

.em-flag-sv {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1fb.png"); }

.em-flag-sv.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1fb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fb.svg"), none; }

.em-flag-sx {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1fd.png"); }

.em-flag-sx.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1fd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fd.svg"), none; }

.em-flag-sy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1fe.png"); }

.em-flag-sy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1fe.svg"), none; }

.em-flag-sz {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ff.png"); }

.em-flag-sz.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f8-1f1ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f8-1f1ff.svg"), none; }

.em-flag-ta {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1e6.png"); }

.em-flag-ta.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1e6.svg"), none; }

.em-flag-tc {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1e8.png"); }

.em-flag-tc.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1e8.svg"), none; }

.em-flag-td {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1e9.png"); }

.em-flag-td.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1e9.svg"), none; }

.em-flag-tf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1eb.png"); }

.em-flag-tf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1eb.svg"), none; }

.em-flag-tg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1ec.png"); }

.em-flag-tg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ec.svg"), none; }

.em-flag-th {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1ed.png"); }

.em-flag-th.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ed.svg"), none; }

.em-flag-tj {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1ef.png"); }

.em-flag-tj.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1ef.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ef.svg"), none; }

.em-flag-tk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f0.png"); }

.em-flag-tk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f0.svg"), none; }

.em-flag-tl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f1.png"); }

.em-flag-tl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f1.svg"), none; }

.em-flag-tm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f2.png"); }

.em-flag-tm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f2.svg"), none; }

.em-flag-tn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f3.png"); }

.em-flag-tn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f3.svg"), none; }

.em-flag-to {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f4.png"); }

.em-flag-to.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f4.svg"), none; }

.em-flag-tr {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f7.png"); }

.em-flag-tr.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f7.svg"), none; }

.em-flag-tt {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f9.png"); }

.em-flag-tt.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1f9.svg"), none; }

.em-flag-tv {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1fb.png"); }

.em-flag-tv.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1fb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1fb.svg"), none; }

.em-flag-tw {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1fc.png"); }

.em-flag-tw.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1fc.svg"), none; }

.em-flag-tz {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1ff.png"); }

.em-flag-tz.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f9-1f1ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f9-1f1ff.svg"), none; }

.em-flag-ua {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1e6.png"); }

.em-flag-ua.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1e6.svg"), none; }

.em-flag-ug {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1ec.png"); }

.em-flag-ug.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1ec.svg"), none; }

.em-flag-um {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1f2.png"); }

.em-flag-um.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f2.svg"), none; }

.em-flag-un {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1f3.png"); }

.em-flag-un.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f3.svg"), none; }

.em-flag-uy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1fe.png"); }

.em-flag-uy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1fe.svg"), none; }

.em-flag-uz {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1ff.png"); }

.em-flag-uz.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1ff.svg"), none; }

.em-flag-va {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1e6.png"); }

.em-flag-va.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1e6.svg"), none; }

.em-flag-vc {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1e8.png"); }

.em-flag-vc.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1e8.svg"), none; }

.em-flag-ve {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1ea.png"); }

.em-flag-ve.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ea.svg"), none; }

.em-flag-vg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1ec.png"); }

.em-flag-vg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ec.svg"), none; }

.em-flag-vi {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1ee.png"); }

.em-flag-vi.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1ee.svg"), none; }

.em-flag-vn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1f3.png"); }

.em-flag-vn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1f3.svg"), none; }

.em-flag-vu {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1fa.png"); }

.em-flag-vu.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fb-1f1fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fb-1f1fa.svg"), none; }

.em-flag-wales {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.png"); }

.em-flag-wales.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.svg"), none; }

.em-flag-wf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fc-1f1eb.png"); }

.em-flag-wf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fc-1f1eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fc-1f1eb.svg"), none; }

.em-flag-ws {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fc-1f1f8.png"); }

.em-flag-ws.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fc-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fc-1f1f8.svg"), none; }

.em-flag-xk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fd-1f1f0.png"); }

.em-flag-xk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fd-1f1f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fd-1f1f0.svg"), none; }

.em-flag-ye {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fe-1f1ea.png"); }

.em-flag-ye.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fe-1f1ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fe-1f1ea.svg"), none; }

.em-flag-yt {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fe-1f1f9.png"); }

.em-flag-yt.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fe-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fe-1f1f9.svg"), none; }

.em-flag-za {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ff-1f1e6.png"); }

.em-flag-za.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ff-1f1e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1e6.svg"), none; }

.em-flag-zm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ff-1f1f2.png"); }

.em-flag-zm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ff-1f1f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1f2.svg"), none; }

.em-flag-zw {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ff-1f1fc.png"); }

.em-flag-zw.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ff-1f1fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ff-1f1fc.svg"), none; }

.em-flags {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38f.png"); }

.em-flags.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f38f.svg"), none; }

.em-flashlight {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f526.png"); }

.em-flashlight.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f526.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f526.svg"), none; }

.em-fleur_de_lis {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/269c.png"); }

.em-fleur_de_lis.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/269c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/269c.svg"), none; }

.em-floppy_disk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4be.png"); }

.em-floppy_disk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4be.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4be.svg"), none; }

.em-flower_playing_cards {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b4.png"); }

.em-flower_playing_cards.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3b4.svg"), none; }

.em-flushed {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f633.png"); }

.em-flushed.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f633.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f633.svg"), none; }

.em-flying_saucer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f8.png"); }

.em-flying_saucer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6f8.svg"), none; }

.em-fog {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32b.png"); }

.em-fog.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f32b.svg"), none; }

.em-foggy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f301.png"); }

.em-foggy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f301.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f301.svg"), none; }

.em-football {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c8.png"); }

.em-football.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c8.svg"), none; }

.em-footprints {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f463.png"); }

.em-footprints.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f463.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f463.svg"), none; }

.em-fork_and_knife {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f374.png"); }

.em-fork_and_knife.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f374.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f374.svg"), none; }

.em-fortune_cookie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f960.png"); }

.em-fortune_cookie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f960.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f960.svg"), none; }

.em-fountain {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f2.png"); }

.em-fountain.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26f2.svg"), none; }

.em-four {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/34-20e3.png"); }

.em-four.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/34-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/34-20e3.svg"), none; }

.em-four_leaf_clover {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f340.png"); }

.em-four_leaf_clover.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f340.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f340.svg"), none; }

.em-fox_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98a.png"); }

.em-fox_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f98a.svg"), none; }

.em-fr, .em-flag-fr {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1f7.png"); }

.em-fr.em-svg, .em-flag-fr.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1eb-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1eb-1f1f7.svg"), none; }

.em-frame_with_picture {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5bc.png"); }

.em-frame_with_picture.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5bc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5bc.svg"), none; }

.em-free {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f193.png"); }

.em-free.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f193.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f193.svg"), none; }

.em-fried_egg, .em-cooking {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f373.png"); }

.em-fried_egg.em-svg, .em-cooking.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f373.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f373.svg"), none; }

.em-fried_shrimp {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f364.png"); }

.em-fried_shrimp.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f364.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f364.svg"), none; }

.em-fries {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35f.png"); }

.em-fries.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f35f.svg"), none; }

.em-frog {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f438.png"); }

.em-frog.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f438.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f438.svg"), none; }

.em-frowning {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f626.png"); }

.em-frowning.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f626.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f626.svg"), none; }

.em-fuelpump {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26fd.png"); }

.em-fuelpump.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26fd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26fd.svg"), none; }

.em-full_moon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f315.png"); }

.em-full_moon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f315.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f315.svg"), none; }

.em-full_moon_with_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31d.png"); }

.em-full_moon_with_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f31d.svg"), none; }

.em-funeral_urn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26b1.png"); }

.em-funeral_urn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26b1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26b1.svg"), none; }

.em-game_die {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b2.png"); }

.em-game_die.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3b2.svg"), none; }

.em-gb, .em-uk, .em-flag-gb {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1e7.png"); }

.em-gb.em-svg, .em-uk.em-svg, .em-flag-gb.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ec-1f1e7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ec-1f1e7.svg"), none; }

.em-gear {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2699.png"); }

.em-gear.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2699.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2699.svg"), none; }

.em-gem {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48e.png"); }

.em-gem.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f48e.svg"), none; }

.em-gemini {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264a.png"); }

.em-gemini.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/264a.svg"), none; }

.em-genie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9de.png"); }

.em-genie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9de.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9de.svg"), none; }

.em-ghost {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47b.png"); }

.em-ghost.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f47b.svg"), none; }

.em-gift {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f381.png"); }

.em-gift.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f381.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f381.svg"), none; }

.em-gift_heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49d.png"); }

.em-gift_heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f49d.svg"), none; }

.em-giraffe_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f992.png"); }

.em-giraffe_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f992.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f992.svg"), none; }

.em-girl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f467.png"); }

.em-girl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f467.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f467.svg"), none; }

.em-glass_of_milk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95b.png"); }

.em-glass_of_milk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f95b.svg"), none; }

.em-globe_with_meridians {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f310.png"); }

.em-globe_with_meridians.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f310.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f310.svg"), none; }

.em-gloves {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e4.png"); }

.em-gloves.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9e4.svg"), none; }

.em-goal_net {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f945.png"); }

.em-goal_net.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f945.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f945.svg"), none; }

.em-goat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f410.png"); }

.em-goat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f410.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f410.svg"), none; }

.em-golf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f3.png"); }

.em-golf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26f3.svg"), none; }

.em-golfer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cc.png"); }

.em-golfer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3cc.svg"), none; }

.em-gorilla {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98d.png"); }

.em-gorilla.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f98d.svg"), none; }

.em-grapes {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f347.png"); }

.em-grapes.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f347.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f347.svg"), none; }

.em-green_apple {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34f.png"); }

.em-green_apple.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f34f.svg"), none; }

.em-green_book {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d7.png"); }

.em-green_book.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4d7.svg"), none; }

.em-green_heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49a.png"); }

.em-green_heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f49a.svg"), none; }

.em-green_salad {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f957.png"); }

.em-green_salad.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f957.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f957.svg"), none; }

.em-grey_exclamation {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2755.png"); }

.em-grey_exclamation.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2755.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2755.svg"), none; }

.em-grey_question {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2754.png"); }

.em-grey_question.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2754.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2754.svg"), none; }

.em-grimacing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62c.png"); }

.em-grimacing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f62c.svg"), none; }

.em-grin {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f601.png"); }

.em-grin.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f601.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f601.svg"), none; }

.em-grinning {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f600.png"); }

.em-grinning.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f600.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f600.svg"), none; }

.em-guardsman {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f482.png"); }

.em-guardsman.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f482.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f482.svg"), none; }

.em-guitar {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b8.png"); }

.em-guitar.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3b8.svg"), none; }

.em-gun {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52b.png"); }

.em-gun.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f52b.svg"), none; }

.em-haircut {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f487.png"); }

.em-haircut.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f487.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f487.svg"), none; }

.em-hamburger {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f354.png"); }

.em-hamburger.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f354.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f354.svg"), none; }

.em-hammer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f528.png"); }

.em-hammer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f528.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f528.svg"), none; }

.em-hammer_and_pick {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2692.png"); }

.em-hammer_and_pick.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2692.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2692.svg"), none; }

.em-hammer_and_wrench {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e0.png"); }

.em-hammer_and_wrench.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6e0.svg"), none; }

.em-hamster {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f439.png"); }

.em-hamster.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f439.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f439.svg"), none; }

.em-hand, .em-raised_hand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/270b.png"); }

.em-hand.em-svg, .em-raised_hand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/270b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/270b.svg"), none; }

.em-handbag {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45c.png"); }

.em-handbag.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f45c.svg"), none; }

.em-handball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93e.png"); }

.em-handball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f93e.svg"), none; }

.em-handshake {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91d.png"); }

.em-handshake.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f91d.svg"), none; }

.em-hankey, .em-poop, .em-shit {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a9.png"); }

.em-hankey.em-svg, .em-poop.em-svg, .em-shit.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4a9.svg"), none; }

.em-hash {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23-20e3.png"); }

.em-hash.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23-20e3.svg"), none; }

.em-hatched_chick {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f425.png"); }

.em-hatched_chick.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f425.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f425.svg"), none; }

.em-hatching_chick {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f423.png"); }

.em-hatching_chick.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f423.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f423.svg"), none; }

.em-headphones {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a7.png"); }

.em-headphones.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3a7.svg"), none; }

.em-hear_no_evil {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f649.png"); }

.em-hear_no_evil.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f649.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f649.svg"), none; }

.em-heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2764.png"); }

.em-heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2764.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2764.svg"), none; }

.em-heart_decoration {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49f.png"); }

.em-heart_decoration.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f49f.svg"), none; }

.em-heart_eyes {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60d.png"); }

.em-heart_eyes.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f60d.svg"), none; }

.em-heart_eyes_cat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63b.png"); }

.em-heart_eyes_cat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f63b.svg"), none; }

.em-heartbeat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f493.png"); }

.em-heartbeat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f493.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f493.svg"), none; }

.em-heartpulse {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f497.png"); }

.em-heartpulse.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f497.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f497.svg"), none; }

.em-hearts {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2665.png"); }

.em-hearts.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2665.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2665.svg"), none; }

.em-heavy_check_mark {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2714.png"); }

.em-heavy_check_mark.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2714.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2714.svg"), none; }

.em-heavy_division_sign {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2797.png"); }

.em-heavy_division_sign.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2797.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2797.svg"), none; }

.em-heavy_dollar_sign {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b2.png"); }

.em-heavy_dollar_sign.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4b2.svg"), none; }

.em-heavy_heart_exclamation_mark_ornament {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2763.png"); }

.em-heavy_heart_exclamation_mark_ornament.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2763.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2763.svg"), none; }

.em-heavy_minus_sign {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2796.png"); }

.em-heavy_minus_sign.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2796.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2796.svg"), none; }

.em-heavy_multiplication_x {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2716.png"); }

.em-heavy_multiplication_x.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2716.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2716.svg"), none; }

.em-heavy_plus_sign {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2795.png"); }

.em-heavy_plus_sign.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2795.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2795.svg"), none; }

.em-hedgehog {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f994.png"); }

.em-hedgehog.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f994.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f994.svg"), none; }

.em-helicopter {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f681.png"); }

.em-helicopter.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f681.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f681.svg"), none; }

.em-helmet_with_white_cross {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26d1.png"); }

.em-helmet_with_white_cross.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26d1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26d1.svg"), none; }

.em-herb {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33f.png"); }

.em-herb.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f33f.svg"), none; }

.em-hibiscus {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33a.png"); }

.em-hibiscus.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f33a.svg"), none; }

.em-high_brightness {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f506.png"); }

.em-high_brightness.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f506.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f506.svg"), none; }

.em-high_heel {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f460.png"); }

.em-high_heel.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f460.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f460.svg"), none; }

.em-hocho, .em-knife {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52a.png"); }

.em-hocho.em-svg, .em-knife.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f52a.svg"), none; }

.em-hole {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f573.png"); }

.em-hole.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f573.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f573.svg"), none; }

.em-honey_pot {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36f.png"); }

.em-honey_pot.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f36f.svg"), none; }

.em-horse {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f434.png"); }

.em-horse.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f434.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f434.svg"), none; }

.em-horse_racing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c7.png"); }

.em-horse_racing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c7.svg"), none; }

.em-hospital {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e5.png"); }

.em-hospital.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3e5.svg"), none; }

.em-hot_pepper {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f336.png"); }

.em-hot_pepper.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f336.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f336.svg"), none; }

.em-hotdog {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32d.png"); }

.em-hotdog.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f32d.svg"), none; }

.em-hotel {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e8.png"); }

.em-hotel.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3e8.svg"), none; }

.em-hotsprings {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2668.png"); }

.em-hotsprings.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2668.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2668.svg"), none; }

.em-hourglass {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/231b.png"); }

.em-hourglass.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/231b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/231b.svg"), none; }

.em-hourglass_flowing_sand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f3.png"); }

.em-hourglass_flowing_sand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23f3.svg"), none; }

.em-house {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e0.png"); }

.em-house.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3e0.svg"), none; }

.em-house_buildings {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d8.png"); }

.em-house_buildings.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3d8.svg"), none; }

.em-house_with_garden {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e1.png"); }

.em-house_with_garden.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3e1.svg"), none; }

.em-hugging_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f917.png"); }

.em-hugging_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f917.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f917.svg"), none; }

.em-hushed {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62f.png"); }

.em-hushed.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f62f.svg"), none; }

.em-i_love_you_hand_sign {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91f.png"); }

.em-i_love_you_hand_sign.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f91f.svg"), none; }

.em-ice_cream {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f368.png"); }

.em-ice_cream.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f368.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f368.svg"), none; }

.em-ice_hockey_stick_and_puck {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d2.png"); }

.em-ice_hockey_stick_and_puck.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3d2.svg"), none; }

.em-ice_skate {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f8.png"); }

.em-ice_skate.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26f8.svg"), none; }

.em-icecream {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f366.png"); }

.em-icecream.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f366.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f366.svg"), none; }

.em-id {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f194.png"); }

.em-id.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f194.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f194.svg"), none; }

.em-ideograph_advantage {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f250.png"); }

.em-ideograph_advantage.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f250.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f250.svg"), none; }

.em-imp {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47f.png"); }

.em-imp.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f47f.svg"), none; }

.em-inbox_tray {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e5.png"); }

.em-inbox_tray.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4e5.svg"), none; }

.em-incoming_envelope {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e8.png"); }

.em-incoming_envelope.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4e8.svg"), none; }

.em-information_desk_person {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f481.png"); }

.em-information_desk_person.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f481.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f481.svg"), none; }

.em-information_source {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2139.png"); }

.em-information_source.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2139.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2139.svg"), none; }

.em-innocent {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f607.png"); }

.em-innocent.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f607.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f607.svg"), none; }

.em-interrobang {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2049.png"); }

.em-interrobang.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2049.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2049.svg"), none; }

.em-iphone {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f1.png"); }

.em-iphone.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4f1.svg"), none; }

.em-it, .em-flag-it {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f9.png"); }

.em-it.em-svg, .em-flag-it.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ee-1f1f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ee-1f1f9.svg"), none; }

.em-izakaya_lantern, .em-lantern {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ee.png"); }

.em-izakaya_lantern.em-svg, .em-lantern.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ee.svg"), none; }

.em-jack_o_lantern {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f383.png"); }

.em-jack_o_lantern.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f383.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f383.svg"), none; }

.em-japan {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5fe.png"); }

.em-japan.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5fe.svg"), none; }

.em-japanese_castle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ef.png"); }

.em-japanese_castle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ef.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ef.svg"), none; }

.em-japanese_goblin {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47a.png"); }

.em-japanese_goblin.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f47a.svg"), none; }

.em-japanese_ogre {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f479.png"); }

.em-japanese_ogre.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f479.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f479.svg"), none; }

.em-jeans {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f456.png"); }

.em-jeans.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f456.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f456.svg"), none; }

.em-joy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f602.png"); }

.em-joy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f602.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f602.svg"), none; }

.em-joy_cat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f639.png"); }

.em-joy_cat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f639.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f639.svg"), none; }

.em-joystick {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f579.png"); }

.em-joystick.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f579.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f579.svg"), none; }

.em-jp, .em-flag-jp {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ef-1f1f5.png"); }

.em-jp.em-svg, .em-flag-jp.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1ef-1f1f5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1ef-1f1f5.svg"), none; }

.em-juggling {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f939.png"); }

.em-juggling.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f939.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f939.svg"), none; }

.em-kaaba {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f54b.png"); }

.em-kaaba.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f54b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f54b.svg"), none; }

.em-key {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f511.png"); }

.em-key.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f511.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f511.svg"), none; }

.em-keyboard {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2328.png"); }

.em-keyboard.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2328.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2328.svg"), none; }

.em-keycap_star {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2a-20e3.png"); }

.em-keycap_star.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2a-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2a-20e3.svg"), none; }

.em-keycap_ten {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51f.png"); }

.em-keycap_ten.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f51f.svg"), none; }

.em-kimono {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f458.png"); }

.em-kimono.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f458.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f458.svg"), none; }

.em-kiss {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48b.png"); }

.em-kiss.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f48b.svg"), none; }

.em-kissing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f617.png"); }

.em-kissing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f617.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f617.svg"), none; }

.em-kissing_cat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63d.png"); }

.em-kissing_cat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f63d.svg"), none; }

.em-kissing_closed_eyes {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61a.png"); }

.em-kissing_closed_eyes.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f61a.svg"), none; }

.em-kissing_heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f618.png"); }

.em-kissing_heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f618.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f618.svg"), none; }

.em-kissing_smiling_eyes {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f619.png"); }

.em-kissing_smiling_eyes.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f619.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f619.svg"), none; }

.em-kiwifruit {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95d.png"); }

.em-kiwifruit.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f95d.svg"), none; }

.em-knife_fork_plate {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37d.png"); }

.em-knife_fork_plate.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f37d.svg"), none; }

.em-koala {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f428.png"); }

.em-koala.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f428.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f428.svg"), none; }

.em-koko {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f201.png"); }

.em-koko.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f201.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f201.svg"), none; }

.em-kr, .em-flag-kr {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1f7.png"); }

.em-kr.em-svg, .em-flag-kr.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f0-1f1f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f0-1f1f7.svg"), none; }

.em-label {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f7.png"); }

.em-label.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3f7.svg"), none; }

.em-large_blue_circle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f535.png"); }

.em-large_blue_circle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f535.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f535.svg"), none; }

.em-large_blue_diamond {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f537.png"); }

.em-large_blue_diamond.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f537.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f537.svg"), none; }

.em-large_orange_diamond {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f536.png"); }

.em-large_orange_diamond.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f536.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f536.svg"), none; }

.em-last_quarter_moon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f317.png"); }

.em-last_quarter_moon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f317.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f317.svg"), none; }

.em-last_quarter_moon_with_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31c.png"); }

.em-last_quarter_moon_with_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f31c.svg"), none; }

.em-latin_cross {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/271d.png"); }

.em-latin_cross.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/271d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/271d.svg"), none; }

.em-laughing, .em-satisfied {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f606.png"); }

.em-laughing.em-svg, .em-satisfied.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f606.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f606.svg"), none; }

.em-leaves {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f343.png"); }

.em-leaves.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f343.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f343.svg"), none; }

.em-ledger {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d2.png"); }

.em-ledger.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4d2.svg"), none; }

.em-left-facing_fist {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91b.png"); }

.em-left-facing_fist.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f91b.svg"), none; }

.em-left_luggage {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c5.png"); }

.em-left_luggage.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6c5.svg"), none; }

.em-left_right_arrow {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2194.png"); }

.em-left_right_arrow.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2194.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2194.svg"), none; }

.em-left_speech_bubble {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5e8.png"); }

.em-left_speech_bubble.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5e8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5e8.svg"), none; }

.em-leftwards_arrow_with_hook {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/21a9.png"); }

.em-leftwards_arrow_with_hook.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/21a9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/21a9.svg"), none; }

.em-lemon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34b.png"); }

.em-lemon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f34b.svg"), none; }

.em-leo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264c.png"); }

.em-leo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/264c.svg"), none; }

.em-leopard {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f406.png"); }

.em-leopard.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f406.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f406.svg"), none; }

.em-level_slider {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f39a.png"); }

.em-level_slider.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f39a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f39a.svg"), none; }

.em-libra {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264e.png"); }

.em-libra.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/264e.svg"), none; }

.em-light_rail {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f688.png"); }

.em-light_rail.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f688.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f688.svg"), none; }

.em-lightning, .em-lightning_cloud {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f329.png"); }

.em-lightning.em-svg, .em-lightning_cloud.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f329.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f329.svg"), none; }

.em-link {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f517.png"); }

.em-link.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f517.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f517.svg"), none; }

.em-linked_paperclips {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f587.png"); }

.em-linked_paperclips.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f587.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f587.svg"), none; }

.em-lion_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f981.png"); }

.em-lion_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f981.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f981.svg"), none; }

.em-lips {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f444.png"); }

.em-lips.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f444.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f444.svg"), none; }

.em-lipstick {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f484.png"); }

.em-lipstick.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f484.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f484.svg"), none; }

.em-lizard {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98e.png"); }

.em-lizard.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f98e.svg"), none; }

.em-lock {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f512.png"); }

.em-lock.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f512.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f512.svg"), none; }

.em-lock_with_ink_pen {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50f.png"); }

.em-lock_with_ink_pen.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f50f.svg"), none; }

.em-lollipop {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36d.png"); }

.em-lollipop.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f36d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f36d.svg"), none; }

.em-loop {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/27bf.png"); }

.em-loop.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/27bf.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/27bf.svg"), none; }

.em-loud_sound {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50a.png"); }

.em-loud_sound.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f50a.svg"), none; }

.em-loudspeaker {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e2.png"); }

.em-loudspeaker.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4e2.svg"), none; }

.em-love_hotel {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e9.png"); }

.em-love_hotel.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3e9.svg"), none; }

.em-love_letter {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48c.png"); }

.em-love_letter.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f48c.svg"), none; }

.em-low_brightness {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f505.png"); }

.em-low_brightness.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f505.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f505.svg"), none; }

.em-lower_left_ballpoint_pen {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f58a.png"); }

.em-lower_left_ballpoint_pen.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f58a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f58a.svg"), none; }

.em-lower_left_crayon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f58d.png"); }

.em-lower_left_crayon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f58d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f58d.svg"), none; }

.em-lower_left_fountain_pen {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f58b.png"); }

.em-lower_left_fountain_pen.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f58b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f58b.svg"), none; }

.em-lower_left_paintbrush {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f58c.png"); }

.em-lower_left_paintbrush.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f58c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f58c.svg"), none; }

.em-lying_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f925.png"); }

.em-lying_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f925.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f925.svg"), none; }

.em-m {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/24c2.png"); }

.em-m.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/24c2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/24c2.svg"), none; }

.em-mag {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50d.png"); }

.em-mag.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f50d.svg"), none; }

.em-mag_right {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50e.png"); }

.em-mag_right.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f50e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f50e.svg"), none; }

.em-mage {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d9.png"); }

.em-mage.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d9.svg"), none; }

.em-mahjong {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f004.png"); }

.em-mahjong.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f004.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f004.svg"), none; }

.em-mailbox {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4eb.png"); }

.em-mailbox.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4eb.svg"), none; }

.em-mailbox_closed {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ea.png"); }

.em-mailbox_closed.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ea.svg"), none; }

.em-mailbox_with_mail {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ec.png"); }

.em-mailbox_with_mail.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ec.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ec.svg"), none; }

.em-mailbox_with_no_mail {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ed.png"); }

.em-mailbox_with_no_mail.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ed.svg"), none; }

.em-male-artist {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f3a8.png"); }

.em-male-artist.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f3a8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f3a8.svg"), none; }

.em-male-astronaut {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f680.png"); }

.em-male-astronaut.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f680.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f680.svg"), none; }

.em-male-construction-worker {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f477-200d-2642-fe0f.png"); }

.em-male-construction-worker.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f477-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f477-200d-2642-fe0f.svg"), none; }

.em-male-cook {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f373.png"); }

.em-male-cook.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f373.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f373.svg"), none; }

.em-male-detective {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f575-fe0f-200d-2642-fe0f.png"); }

.em-male-detective.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f575-fe0f-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f575-fe0f-200d-2642-fe0f.svg"), none; }

.em-male-doctor {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-2695-fe0f.png"); }

.em-male-doctor.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-2695-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-2695-fe0f.svg"), none; }

.em-male-factory-worker {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f3ed.png"); }

.em-male-factory-worker.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f3ed.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f3ed.svg"), none; }

.em-male-farmer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f33e.png"); }

.em-male-farmer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f33e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f33e.svg"), none; }

.em-male-firefighter {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f692.png"); }

.em-male-firefighter.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f692.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f692.svg"), none; }

.em-male-guard {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f482-200d-2642-fe0f.png"); }

.em-male-guard.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f482-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f482-200d-2642-fe0f.svg"), none; }

.em-male-judge {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-2696-fe0f.png"); }

.em-male-judge.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-2696-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-2696-fe0f.svg"), none; }

.em-male-mechanic {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f527.png"); }

.em-male-mechanic.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f527.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f527.svg"), none; }

.em-male-office-worker {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f4bc.png"); }

.em-male-office-worker.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f4bc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f4bc.svg"), none; }

.em-male-pilot {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-2708-fe0f.png"); }

.em-male-pilot.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-2708-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-2708-fe0f.svg"), none; }

.em-male-police-officer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46e-200d-2642-fe0f.png"); }

.em-male-police-officer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46e-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f46e-200d-2642-fe0f.svg"), none; }

.em-male-scientist {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f52c.png"); }

.em-male-scientist.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f52c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f52c.svg"), none; }

.em-male-singer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f3a4.png"); }

.em-male-singer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f3a4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f3a4.svg"), none; }

.em-male-student {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f393.png"); }

.em-male-student.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f393.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f393.svg"), none; }

.em-male-teacher {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f3eb.png"); }

.em-male-teacher.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f3eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f3eb.svg"), none; }

.em-male-technologist {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f4bb.png"); }

.em-male-technologist.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f4bb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f4bb.svg"), none; }

.em-male_elf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dd-200d-2642-fe0f.png"); }

.em-male_elf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dd-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9dd-200d-2642-fe0f.svg"), none; }

.em-male_fairy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9da-200d-2642-fe0f.png"); }

.em-male_fairy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9da-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9da-200d-2642-fe0f.svg"), none; }

.em-male_genie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9de-200d-2642-fe0f.png"); }

.em-male_genie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9de-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9de-200d-2642-fe0f.svg"), none; }

.em-male_mage {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d9-200d-2642-fe0f.png"); }

.em-male_mage.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d9-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d9-200d-2642-fe0f.svg"), none; }

.em-male_sign {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2642.png"); }

.em-male_sign.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2642.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2642.svg"), none; }

.em-male_vampire {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9db-200d-2642-fe0f.png"); }

.em-male_vampire.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9db-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9db-200d-2642-fe0f.svg"), none; }

.em-male_zombie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9df-200d-2642-fe0f.png"); }

.em-male_zombie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9df-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9df-200d-2642-fe0f.svg"), none; }

.em-man {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468.png"); }

.em-man.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468.svg"), none; }

.em-man-biking {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b4-200d-2642-fe0f.png"); }

.em-man-biking.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b4-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b4-200d-2642-fe0f.svg"), none; }

.em-man-bouncing-ball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f9-fe0f-200d-2642-fe0f.png"); }

.em-man-bouncing-ball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f9-fe0f-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26f9-fe0f-200d-2642-fe0f.svg"), none; }

.em-man-bowing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f647-200d-2642-fe0f.png"); }

.em-man-bowing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f647-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f647-200d-2642-fe0f.svg"), none; }

.em-man-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f466.png"); }

.em-man-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f466.svg"), none; }

.em-man-boy-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f466-200d-1f466.png"); }

.em-man-boy-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f466-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f466-200d-1f466.svg"), none; }

.em-man-cartwheeling {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f938-200d-2642-fe0f.png"); }

.em-man-cartwheeling.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f938-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f938-200d-2642-fe0f.svg"), none; }

.em-man-facepalming {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f926-200d-2642-fe0f.png"); }

.em-man-facepalming.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f926-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f926-200d-2642-fe0f.svg"), none; }

.em-man-frowning {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64d-200d-2642-fe0f.png"); }

.em-man-frowning.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64d-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64d-200d-2642-fe0f.svg"), none; }

.em-man-gesturing-no {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f645-200d-2642-fe0f.png"); }

.em-man-gesturing-no.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f645-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f645-200d-2642-fe0f.svg"), none; }

.em-man-gesturing-ok {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f646-200d-2642-fe0f.png"); }

.em-man-gesturing-ok.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f646-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f646-200d-2642-fe0f.svg"), none; }

.em-man-getting-haircut {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f487-200d-2642-fe0f.png"); }

.em-man-getting-haircut.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f487-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f487-200d-2642-fe0f.svg"), none; }

.em-man-getting-massage {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f486-200d-2642-fe0f.png"); }

.em-man-getting-massage.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f486-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f486-200d-2642-fe0f.svg"), none; }

.em-man-girl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f467.png"); }

.em-man-girl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f467.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f467.svg"), none; }

.em-man-girl-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f467-200d-1f466.png"); }

.em-man-girl-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f467-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f467-200d-1f466.svg"), none; }

.em-man-girl-girl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f467-200d-1f467.png"); }

.em-man-girl-girl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f467-200d-1f467.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f467-200d-1f467.svg"), none; }

.em-man-golfing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cc-fe0f-200d-2642-fe0f.png"); }

.em-man-golfing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cc-fe0f-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3cc-fe0f-200d-2642-fe0f.svg"), none; }

.em-man-heart-man {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-2764-fe0f-200d-1f468.png"); }

.em-man-heart-man.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-2764-fe0f-200d-1f468.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-2764-fe0f-200d-1f468.svg"), none; }

.em-man-juggling {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f939-200d-2642-fe0f.png"); }

.em-man-juggling.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f939-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f939-200d-2642-fe0f.svg"), none; }

.em-man-kiss-man {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.png"); }

.em-man-kiss-man.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.svg"), none; }

.em-man-lifting-weights {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cb-fe0f-200d-2642-fe0f.png"); }

.em-man-lifting-weights.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cb-fe0f-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3cb-fe0f-200d-2642-fe0f.svg"), none; }

.em-man-man-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f468-200d-1f466.png"); }

.em-man-man-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f468-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f468-200d-1f466.svg"), none; }

.em-man-man-boy-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f468-200d-1f466-200d-1f466.png"); }

.em-man-man-boy-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f468-200d-1f466-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f468-200d-1f466-200d-1f466.svg"), none; }

.em-man-man-girl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f468-200d-1f467.png"); }

.em-man-man-girl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f468-200d-1f467.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f468-200d-1f467.svg"), none; }

.em-man-man-girl-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f468-200d-1f467-200d-1f466.png"); }

.em-man-man-girl-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f468-200d-1f467-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f468-200d-1f467-200d-1f466.svg"), none; }

.em-man-man-girl-girl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f468-200d-1f467-200d-1f467.png"); }

.em-man-man-girl-girl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f468-200d-1f467-200d-1f467.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f468-200d-1f467-200d-1f467.svg"), none; }

.em-man-mountain-biking {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b5-200d-2642-fe0f.png"); }

.em-man-mountain-biking.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b5-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b5-200d-2642-fe0f.svg"), none; }

.em-man-playing-handball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93e-200d-2642-fe0f.png"); }

.em-man-playing-handball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93e-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f93e-200d-2642-fe0f.svg"), none; }

.em-man-playing-water-polo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93d-200d-2642-fe0f.png"); }

.em-man-playing-water-polo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93d-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f93d-200d-2642-fe0f.svg"), none; }

.em-man-pouting {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64e-200d-2642-fe0f.png"); }

.em-man-pouting.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64e-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64e-200d-2642-fe0f.svg"), none; }

.em-man-raising-hand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64b-200d-2642-fe0f.png"); }

.em-man-raising-hand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64b-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64b-200d-2642-fe0f.svg"), none; }

.em-man-rowing-boat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a3-200d-2642-fe0f.png"); }

.em-man-rowing-boat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a3-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a3-200d-2642-fe0f.svg"), none; }

.em-man-running {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c3-200d-2642-fe0f.png"); }

.em-man-running.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c3-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c3-200d-2642-fe0f.svg"), none; }

.em-man-shrugging {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f937-200d-2642-fe0f.png"); }

.em-man-shrugging.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f937-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f937-200d-2642-fe0f.svg"), none; }

.em-man-surfing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c4-200d-2642-fe0f.png"); }

.em-man-surfing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c4-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c4-200d-2642-fe0f.svg"), none; }

.em-man-swimming {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ca-200d-2642-fe0f.png"); }

.em-man-swimming.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ca-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ca-200d-2642-fe0f.svg"), none; }

.em-man-tipping-hand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f481-200d-2642-fe0f.png"); }

.em-man-tipping-hand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f481-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f481-200d-2642-fe0f.svg"), none; }

.em-man-walking {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b6-200d-2642-fe0f.png"); }

.em-man-walking.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b6-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b6-200d-2642-fe0f.svg"), none; }

.em-man-wearing-turban {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f473-200d-2642-fe0f.png"); }

.em-man-wearing-turban.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f473-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f473-200d-2642-fe0f.svg"), none; }

.em-man-with-bunny-ears-partying {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46f-200d-2642-fe0f.png"); }

.em-man-with-bunny-ears-partying.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46f-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f46f-200d-2642-fe0f.svg"), none; }

.em-man-woman-boy, .em-family {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f469-200d-1f466.png"); }

.em-man-woman-boy.em-svg, .em-family.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f469-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f469-200d-1f466.svg"), none; }

.em-man-woman-boy-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f469-200d-1f466-200d-1f466.png"); }

.em-man-woman-boy-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f469-200d-1f466-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f469-200d-1f466-200d-1f466.svg"), none; }

.em-man-woman-girl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f469-200d-1f467.png"); }

.em-man-woman-girl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f469-200d-1f467.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f469-200d-1f467.svg"), none; }

.em-man-woman-girl-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f469-200d-1f467-200d-1f466.png"); }

.em-man-woman-girl-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f469-200d-1f467-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f469-200d-1f467-200d-1f466.svg"), none; }

.em-man-woman-girl-girl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f469-200d-1f467-200d-1f467.png"); }

.em-man-woman-girl-girl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f468-200d-1f469-200d-1f467-200d-1f467.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f468-200d-1f469-200d-1f467-200d-1f467.svg"), none; }

.em-man-wrestling {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93c-200d-2642-fe0f.png"); }

.em-man-wrestling.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93c-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f93c-200d-2642-fe0f.svg"), none; }

.em-man_climbing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d7-200d-2642-fe0f.png"); }

.em-man_climbing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d7-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d7-200d-2642-fe0f.svg"), none; }

.em-man_dancing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f57a.png"); }

.em-man_dancing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f57a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f57a.svg"), none; }

.em-man_in_business_suit_levitating {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f574.png"); }

.em-man_in_business_suit_levitating.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f574.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f574.svg"), none; }

.em-man_in_lotus_position {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d8-200d-2642-fe0f.png"); }

.em-man_in_lotus_position.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d8-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d8-200d-2642-fe0f.svg"), none; }

.em-man_in_steamy_room {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d6-200d-2642-fe0f.png"); }

.em-man_in_steamy_room.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d6-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d6-200d-2642-fe0f.svg"), none; }

.em-man_in_tuxedo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f935.png"); }

.em-man_in_tuxedo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f935.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f935.svg"), none; }

.em-man_with_gua_pi_mao {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f472.png"); }

.em-man_with_gua_pi_mao.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f472.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f472.svg"), none; }

.em-man_with_turban {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f473.png"); }

.em-man_with_turban.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f473.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f473.svg"), none; }

.em-mans_shoe, .em-shoe {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45e.png"); }

.em-mans_shoe.em-svg, .em-shoe.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f45e.svg"), none; }

.em-mantelpiece_clock {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f570.png"); }

.em-mantelpiece_clock.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f570.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f570.svg"), none; }

.em-maple_leaf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f341.png"); }

.em-maple_leaf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f341.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f341.svg"), none; }

.em-martial_arts_uniform {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f94b.png"); }

.em-martial_arts_uniform.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f94b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f94b.svg"), none; }

.em-mask {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f637.png"); }

.em-mask.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f637.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f637.svg"), none; }

.em-massage {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f486.png"); }

.em-massage.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f486.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f486.svg"), none; }

.em-meat_on_bone {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f356.png"); }

.em-meat_on_bone.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f356.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f356.svg"), none; }

.em-medal {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f396.png"); }

.em-medal.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f396.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f396.svg"), none; }

.em-medical_symbol, .em-staff_of_aesculapius {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2695.png"); }

.em-medical_symbol.em-svg, .em-staff_of_aesculapius.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2695.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2695.svg"), none; }

.em-mega {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e3.png"); }

.em-mega.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4e3.svg"), none; }

.em-melon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f348.png"); }

.em-melon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f348.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f348.svg"), none; }

.em-memo, .em-pencil {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4dd.png"); }

.em-memo.em-svg, .em-pencil.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4dd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4dd.svg"), none; }

.em-menorah_with_nine_branches {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f54e.png"); }

.em-menorah_with_nine_branches.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f54e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f54e.svg"), none; }

.em-mens {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b9.png"); }

.em-mens.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b9.svg"), none; }

.em-mermaid {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dc-200d-2640-fe0f.png"); }

.em-mermaid.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dc-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9dc-200d-2640-fe0f.svg"), none; }

.em-merman {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dc-200d-2642-fe0f.png"); }

.em-merman.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dc-200d-2642-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9dc-200d-2642-fe0f.svg"), none; }

.em-merperson {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dc.png"); }

.em-merperson.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9dc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9dc.svg"), none; }

.em-metro {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f687.png"); }

.em-metro.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f687.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f687.svg"), none; }

.em-microphone {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a4.png"); }

.em-microphone.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3a4.svg"), none; }

.em-microscope {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52c.png"); }

.em-microscope.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f52c.svg"), none; }

.em-middle_finger, .em-reversed_hand_with_middle_finger_extended {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f595.png"); }

.em-middle_finger.em-svg, .em-reversed_hand_with_middle_finger_extended.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f595.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f595.svg"), none; }

.em-milky_way {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30c.png"); }

.em-milky_way.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f30c.svg"), none; }

.em-minibus {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f690.png"); }

.em-minibus.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f690.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f690.svg"), none; }

.em-minidisc {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4bd.png"); }

.em-minidisc.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4bd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4bd.svg"), none; }

.em-mobile_phone_off {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f4.png"); }

.em-mobile_phone_off.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4f4.svg"), none; }

.em-money_mouth_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f911.png"); }

.em-money_mouth_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f911.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f911.svg"), none; }

.em-money_with_wings {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b8.png"); }

.em-money_with_wings.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4b8.svg"), none; }

.em-moneybag {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b0.png"); }

.em-moneybag.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4b0.svg"), none; }

.em-monkey {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f412.png"); }

.em-monkey.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f412.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f412.svg"), none; }

.em-monkey_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f435.png"); }

.em-monkey_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f435.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f435.svg"), none; }

.em-monorail {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69d.png"); }

.em-monorail.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f69d.svg"), none; }

.em-moon, .em-waxing_gibbous_moon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f314.png"); }

.em-moon.em-svg, .em-waxing_gibbous_moon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f314.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f314.svg"), none; }

.em-mortar_board {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f393.png"); }

.em-mortar_board.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f393.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f393.svg"), none; }

.em-mosque {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f54c.png"); }

.em-mosque.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f54c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f54c.svg"), none; }

.em-mostly_sunny, .em-sun_small_cloud {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f324.png"); }

.em-mostly_sunny.em-svg, .em-sun_small_cloud.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f324.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f324.svg"), none; }

.em-motor_boat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e5.png"); }

.em-motor_boat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6e5.svg"), none; }

.em-motor_scooter {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f5.png"); }

.em-motor_scooter.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6f5.svg"), none; }

.em-motorway {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e3.png"); }

.em-motorway.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6e3.svg"), none; }

.em-mount_fuji {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5fb.png"); }

.em-mount_fuji.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5fb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5fb.svg"), none; }

.em-mountain {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f0.png"); }

.em-mountain.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26f0.svg"), none; }

.em-mountain_bicyclist {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b5.png"); }

.em-mountain_bicyclist.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b5.svg"), none; }

.em-mountain_cableway {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a0.png"); }

.em-mountain_cableway.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a0.svg"), none; }

.em-mountain_railway {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69e.png"); }

.em-mountain_railway.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f69e.svg"), none; }

.em-mouse {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42d.png"); }

.em-mouse.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f42d.svg"), none; }

.em-mouse2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f401.png"); }

.em-mouse2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f401.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f401.svg"), none; }

.em-movie_camera {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a5.png"); }

.em-movie_camera.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3a5.svg"), none; }

.em-moyai {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5ff.png"); }

.em-moyai.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5ff.svg"), none; }

.em-mrs_claus, .em-mother_christmas {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f936.png"); }

.em-mrs_claus.em-svg, .em-mother_christmas.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f936.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f936.svg"), none; }

.em-muscle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4aa.png"); }

.em-muscle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4aa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4aa.svg"), none; }

.em-mushroom {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f344.png"); }

.em-mushroom.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f344.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f344.svg"), none; }

.em-musical_keyboard {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b9.png"); }

.em-musical_keyboard.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3b9.svg"), none; }

.em-musical_note {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b5.png"); }

.em-musical_note.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3b5.svg"), none; }

.em-musical_score {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3bc.png"); }

.em-musical_score.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3bc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3bc.svg"), none; }

.em-mute {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f507.png"); }

.em-mute.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f507.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f507.svg"), none; }

.em-nail_care {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f485.png"); }

.em-nail_care.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f485.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f485.svg"), none; }

.em-name_badge {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4db.png"); }

.em-name_badge.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4db.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4db.svg"), none; }

.em-national_park {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3de.png"); }

.em-national_park.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3de.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3de.svg"), none; }

.em-nauseated_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f922.png"); }

.em-nauseated_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f922.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f922.svg"), none; }

.em-necktie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f454.png"); }

.em-necktie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f454.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f454.svg"), none; }

.em-negative_squared_cross_mark {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/274e.png"); }

.em-negative_squared_cross_mark.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/274e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/274e.svg"), none; }

.em-nerd_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f913.png"); }

.em-nerd_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f913.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f913.svg"), none; }

.em-neutral_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f610.png"); }

.em-neutral_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f610.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f610.svg"), none; }

.em-new {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f195.png"); }

.em-new.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f195.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f195.svg"), none; }

.em-new_moon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f311.png"); }

.em-new_moon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f311.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f311.svg"), none; }

.em-new_moon_with_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31a.png"); }

.em-new_moon_with_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f31a.svg"), none; }

.em-newspaper {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f0.png"); }

.em-newspaper.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4f0.svg"), none; }

.em-ng {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f196.png"); }

.em-ng.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f196.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f196.svg"), none; }

.em-night_with_stars {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f303.png"); }

.em-night_with_stars.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f303.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f303.svg"), none; }

.em-nine {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/39-20e3.png"); }

.em-nine.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/39-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/39-20e3.svg"), none; }

.em-no_bell {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f515.png"); }

.em-no_bell.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f515.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f515.svg"), none; }

.em-no_bicycles {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b3.png"); }

.em-no_bicycles.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b3.svg"), none; }

.em-no_entry {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26d4.png"); }

.em-no_entry.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26d4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26d4.svg"), none; }

.em-no_entry_sign {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ab.png"); }

.em-no_entry_sign.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ab.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6ab.svg"), none; }

.em-no_good {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f645.png"); }

.em-no_good.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f645.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f645.svg"), none; }

.em-no_mobile_phones {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f5.png"); }

.em-no_mobile_phones.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4f5.svg"), none; }

.em-no_mouth {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f636.png"); }

.em-no_mouth.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f636.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f636.svg"), none; }

.em-no_pedestrians {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b7.png"); }

.em-no_pedestrians.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b7.svg"), none; }

.em-no_smoking {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ad.png"); }

.em-no_smoking.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ad.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6ad.svg"), none; }

.em-non-potable_water {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b1.png"); }

.em-non-potable_water.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b1.svg"), none; }

.em-nose {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f443.png"); }

.em-nose.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f443.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f443.svg"), none; }

.em-notebook {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d3.png"); }

.em-notebook.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4d3.svg"), none; }

.em-notebook_with_decorative_cover {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d4.png"); }

.em-notebook_with_decorative_cover.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4d4.svg"), none; }

.em-notes {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b6.png"); }

.em-notes.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3b6.svg"), none; }

.em-nut_and_bolt {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f529.png"); }

.em-nut_and_bolt.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f529.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f529.svg"), none; }

.em-o {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b55.png"); }

.em-o.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b55.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2b55.svg"), none; }

.em-o2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f17e.png"); }

.em-o2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f17e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f17e.svg"), none; }

.em-ocean {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30a.png"); }

.em-ocean.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f30a.svg"), none; }

.em-octagonal_sign {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6d1.png"); }

.em-octagonal_sign.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6d1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6d1.svg"), none; }

.em-octopus {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f419.png"); }

.em-octopus.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f419.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f419.svg"), none; }

.em-oden {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f362.png"); }

.em-oden.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f362.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f362.svg"), none; }

.em-office {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e2.png"); }

.em-office.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3e2.svg"), none; }

.em-oil_drum {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e2.png"); }

.em-oil_drum.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6e2.svg"), none; }

.em-ok {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f197.png"); }

.em-ok.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f197.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f197.svg"), none; }

.em-ok_hand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44c.png"); }

.em-ok_hand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f44c.svg"), none; }

.em-ok_woman {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f646.png"); }

.em-ok_woman.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f646.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f646.svg"), none; }

.em-old_key {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5dd.png"); }

.em-old_key.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5dd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5dd.svg"), none; }

.em-older_adult {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d3.png"); }

.em-older_adult.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d3.svg"), none; }

.em-older_man {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f474.png"); }

.em-older_man.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f474.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f474.svg"), none; }

.em-older_woman {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f475.png"); }

.em-older_woman.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f475.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f475.svg"), none; }

.em-om_symbol {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f549.png"); }

.em-om_symbol.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f549.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f549.svg"), none; }

.em-on {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51b.png"); }

.em-on.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f51b.svg"), none; }

.em-oncoming_automobile {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f698.png"); }

.em-oncoming_automobile.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f698.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f698.svg"), none; }

.em-oncoming_bus {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68d.png"); }

.em-oncoming_bus.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f68d.svg"), none; }

.em-oncoming_police_car {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f694.png"); }

.em-oncoming_police_car.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f694.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f694.svg"), none; }

.em-oncoming_taxi {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f696.png"); }

.em-oncoming_taxi.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f696.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f696.svg"), none; }

.em-one {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/31-20e3.png"); }

.em-one.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/31-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/31-20e3.svg"), none; }

.em-open_file_folder {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c2.png"); }

.em-open_file_folder.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4c2.svg"), none; }

.em-open_hands {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f450.png"); }

.em-open_hands.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f450.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f450.svg"), none; }

.em-open_mouth {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62e.png"); }

.em-open_mouth.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f62e.svg"), none; }

.em-ophiuchus {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26ce.png"); }

.em-ophiuchus.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26ce.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26ce.svg"), none; }

.em-orange_book {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d9.png"); }

.em-orange_book.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4d9.svg"), none; }

.em-orange_heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e1.png"); }

.em-orange_heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9e1.svg"), none; }

.em-orthodox_cross {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2626.png"); }

.em-orthodox_cross.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2626.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2626.svg"), none; }

.em-outbox_tray {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e4.png"); }

.em-outbox_tray.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4e4.svg"), none; }

.em-owl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f989.png"); }

.em-owl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f989.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f989.svg"), none; }

.em-ox {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f402.png"); }

.em-ox.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f402.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f402.svg"), none; }

.em-package {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e6.png"); }

.em-package.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4e6.svg"), none; }

.em-page_facing_up {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c4.png"); }

.em-page_facing_up.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4c4.svg"), none; }

.em-page_with_curl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c3.png"); }

.em-page_with_curl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4c3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4c3.svg"), none; }

.em-pager {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4df.png"); }

.em-pager.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4df.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4df.svg"), none; }

.em-palm_tree {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f334.png"); }

.em-palm_tree.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f334.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f334.svg"), none; }

.em-palms_up_together {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f932.png"); }

.em-palms_up_together.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f932.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f932.svg"), none; }

.em-pancakes {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95e.png"); }

.em-pancakes.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f95e.svg"), none; }

.em-panda_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43c.png"); }

.em-panda_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f43c.svg"), none; }

.em-paperclip {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ce.png"); }

.em-paperclip.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ce.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ce.svg"), none; }

.em-parking {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f17f.png"); }

.em-parking.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f17f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f17f.svg"), none; }

.em-part_alternation_mark {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/303d.png"); }

.em-part_alternation_mark.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/303d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/303d.svg"), none; }

.em-partly_sunny {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26c5.png"); }

.em-partly_sunny.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26c5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26c5.svg"), none; }

.em-partly_sunny_rain, .em-sun_behind_rain_cloud {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f326.png"); }

.em-partly_sunny_rain.em-svg, .em-sun_behind_rain_cloud.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f326.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f326.svg"), none; }

.em-passenger_ship {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f3.png"); }

.em-passenger_ship.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6f3.svg"), none; }

.em-passport_control {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c2.png"); }

.em-passport_control.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6c2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6c2.svg"), none; }

.em-peace_symbol {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/262e.png"); }

.em-peace_symbol.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/262e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/262e.svg"), none; }

.em-peach {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f351.png"); }

.em-peach.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f351.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f351.svg"), none; }

.em-peanuts {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95c.png"); }

.em-peanuts.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f95c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f95c.svg"), none; }

.em-pear {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f350.png"); }

.em-pear.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f350.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f350.svg"), none; }

.em-pencil2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/270f.png"); }

.em-pencil2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/270f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/270f.svg"), none; }

.em-penguin {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f427.png"); }

.em-penguin.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f427.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f427.svg"), none; }

.em-pensive {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f614.png"); }

.em-pensive.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f614.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f614.svg"), none; }

.em-performing_arts {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ad.png"); }

.em-performing_arts.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ad.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ad.svg"), none; }

.em-persevere {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f623.png"); }

.em-persevere.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f623.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f623.svg"), none; }

.em-person_climbing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d7.png"); }

.em-person_climbing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d7.svg"), none; }

.em-person_doing_cartwheel {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f938.png"); }

.em-person_doing_cartwheel.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f938.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f938.svg"), none; }

.em-person_frowning {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64d.png"); }

.em-person_frowning.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64d.svg"), none; }

.em-person_in_lotus_position {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d8.png"); }

.em-person_in_lotus_position.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d8.svg"), none; }

.em-person_in_steamy_room {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d6.png"); }

.em-person_in_steamy_room.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d6.svg"), none; }

.em-person_with_ball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f9.png"); }

.em-person_with_ball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26f9.svg"), none; }

.em-person_with_blond_hair {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f471.png"); }

.em-person_with_blond_hair.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f471.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f471.svg"), none; }

.em-person_with_headscarf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d5.png"); }

.em-person_with_headscarf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d5.svg"), none; }

.em-person_with_pouting_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64e.png"); }

.em-person_with_pouting_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64e.svg"), none; }

.em-phone, .em-telephone {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/260e.png"); }

.em-phone.em-svg, .em-telephone.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/260e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/260e.svg"), none; }

.em-pick {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26cf.png"); }

.em-pick.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26cf.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26cf.svg"), none; }

.em-pie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f967.png"); }

.em-pie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f967.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f967.svg"), none; }

.em-pig {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f437.png"); }

.em-pig.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f437.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f437.svg"), none; }

.em-pig2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f416.png"); }

.em-pig2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f416.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f416.svg"), none; }

.em-pig_nose {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43d.png"); }

.em-pig_nose.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f43d.svg"), none; }

.em-pill {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48a.png"); }

.em-pill.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f48a.svg"), none; }

.em-pineapple {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34d.png"); }

.em-pineapple.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f34d.svg"), none; }

.em-pisces {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2653.png"); }

.em-pisces.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2653.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2653.svg"), none; }

.em-pizza {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f355.png"); }

.em-pizza.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f355.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f355.svg"), none; }

.em-place_of_worship {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6d0.png"); }

.em-place_of_worship.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6d0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6d0.svg"), none; }

.em-point_down {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f447.png"); }

.em-point_down.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f447.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f447.svg"), none; }

.em-point_left {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f448.png"); }

.em-point_left.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f448.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f448.svg"), none; }

.em-point_right {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f449.png"); }

.em-point_right.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f449.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f449.svg"), none; }

.em-point_up {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/261d.png"); }

.em-point_up.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/261d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/261d.svg"), none; }

.em-point_up_2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f446.png"); }

.em-point_up_2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f446.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f446.svg"), none; }

.em-police_car {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f693.png"); }

.em-police_car.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f693.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f693.svg"), none; }

.em-poodle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f429.png"); }

.em-poodle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f429.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f429.svg"), none; }

.em-popcorn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37f.png"); }

.em-popcorn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f37f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f37f.svg"), none; }

.em-post_office {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e3.png"); }

.em-post_office.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3e3.svg"), none; }

.em-postal_horn {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ef.png"); }

.em-postal_horn.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ef.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ef.svg"), none; }

.em-postbox {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ee.png"); }

.em-postbox.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ee.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ee.svg"), none; }

.em-potable_water {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b0.png"); }

.em-potable_water.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b0.svg"), none; }

.em-potato {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f954.png"); }

.em-potato.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f954.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f954.svg"), none; }

.em-pouch {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45d.png"); }

.em-pouch.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f45d.svg"), none; }

.em-poultry_leg {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f357.png"); }

.em-poultry_leg.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f357.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f357.svg"), none; }

.em-pound {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b7.png"); }

.em-pound.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4b7.svg"), none; }

.em-pouting_cat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63e.png"); }

.em-pouting_cat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f63e.svg"), none; }

.em-pray {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64f.png"); }

.em-pray.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64f.svg"), none; }

.em-prayer_beads {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ff.png"); }

.em-prayer_beads.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ff.svg"), none; }

.em-pregnant_woman {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f930.png"); }

.em-pregnant_woman.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f930.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f930.svg"), none; }

.em-pretzel {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f968.png"); }

.em-pretzel.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f968.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f968.svg"), none; }

.em-prince {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f934.png"); }

.em-prince.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f934.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f934.svg"), none; }

.em-princess {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f478.png"); }

.em-princess.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f478.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f478.svg"), none; }

.em-printer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5a8.png"); }

.em-printer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5a8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5a8.svg"), none; }

.em-purple_heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49c.png"); }

.em-purple_heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f49c.svg"), none; }

.em-purse {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45b.png"); }

.em-purse.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f45b.svg"), none; }

.em-pushpin {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4cc.png"); }

.em-pushpin.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4cc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4cc.svg"), none; }

.em-put_litter_in_its_place {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ae.png"); }

.em-put_litter_in_its_place.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ae.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6ae.svg"), none; }

.em-question {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2753.png"); }

.em-question.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2753.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2753.svg"), none; }

.em-rabbit {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f430.png"); }

.em-rabbit.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f430.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f430.svg"), none; }

.em-rabbit2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f407.png"); }

.em-rabbit2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f407.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f407.svg"), none; }

.em-racehorse {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40e.png"); }

.em-racehorse.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f40e.svg"), none; }

.em-racing_car {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ce.png"); }

.em-racing_car.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ce.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ce.svg"), none; }

.em-racing_motorcycle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cd.png"); }

.em-racing_motorcycle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3cd.svg"), none; }

.em-radio {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4fb.png"); }

.em-radio.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4fb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4fb.svg"), none; }

.em-radio_button {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f518.png"); }

.em-radio_button.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f518.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f518.svg"), none; }

.em-radioactive_sign {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2622.png"); }

.em-radioactive_sign.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2622.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2622.svg"), none; }

.em-rage {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f621.png"); }

.em-rage.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f621.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f621.svg"), none; }

.em-railway_car {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f683.png"); }

.em-railway_car.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f683.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f683.svg"), none; }

.em-railway_track {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e4.png"); }

.em-railway_track.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6e4.svg"), none; }

.em-rain_cloud {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f327.png"); }

.em-rain_cloud.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f327.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f327.svg"), none; }

.em-rainbow {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f308.png"); }

.em-rainbow.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f308.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f308.svg"), none; }

.em-rainbow-flag {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f3-fe0f-200d-1f308.png"); }

.em-rainbow-flag.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f3-fe0f-200d-1f308.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3f3-fe0f-200d-1f308.svg"), none; }

.em-raised_back_of_hand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91a.png"); }

.em-raised_back_of_hand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f91a.svg"), none; }

.em-raised_hand_with_fingers_splayed {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f590.png"); }

.em-raised_hand_with_fingers_splayed.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f590.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f590.svg"), none; }

.em-raised_hands {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64c.png"); }

.em-raised_hands.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64c.svg"), none; }

.em-raising_hand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64b.png"); }

.em-raising_hand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64b.svg"), none; }

.em-ram {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40f.png"); }

.em-ram.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f40f.svg"), none; }

.em-ramen {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35c.png"); }

.em-ramen.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f35c.svg"), none; }

.em-rat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f400.png"); }

.em-rat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f400.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f400.svg"), none; }

.em-recycle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/267b.png"); }

.em-recycle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/267b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/267b.svg"), none; }

.em-red_circle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f534.png"); }

.em-red_circle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f534.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f534.svg"), none; }

.em-registered {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/ae.png"); }

.em-registered.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/ae.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/ae.svg"), none; }

.em-relaxed {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/263a.png"); }

.em-relaxed.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/263a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/263a.svg"), none; }

.em-relieved {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60c.png"); }

.em-relieved.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f60c.svg"), none; }

.em-reminder_ribbon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f397.png"); }

.em-reminder_ribbon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f397.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f397.svg"), none; }

.em-repeat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f501.png"); }

.em-repeat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f501.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f501.svg"), none; }

.em-repeat_one {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f502.png"); }

.em-repeat_one.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f502.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f502.svg"), none; }

.em-restroom {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6bb.png"); }

.em-restroom.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6bb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6bb.svg"), none; }

.em-revolving_hearts {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49e.png"); }

.em-revolving_hearts.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f49e.svg"), none; }

.em-rewind {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23ea.png"); }

.em-rewind.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23ea.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23ea.svg"), none; }

.em-rhinoceros {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98f.png"); }

.em-rhinoceros.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f98f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f98f.svg"), none; }

.em-ribbon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f380.png"); }

.em-ribbon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f380.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f380.svg"), none; }

.em-rice {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35a.png"); }

.em-rice.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f35a.svg"), none; }

.em-rice_ball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f359.png"); }

.em-rice_ball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f359.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f359.svg"), none; }

.em-rice_cracker {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f358.png"); }

.em-rice_cracker.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f358.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f358.svg"), none; }

.em-rice_scene {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f391.png"); }

.em-rice_scene.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f391.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f391.svg"), none; }

.em-right-facing_fist {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91c.png"); }

.em-right-facing_fist.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f91c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f91c.svg"), none; }

.em-right_anger_bubble {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5ef.png"); }

.em-right_anger_bubble.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5ef.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5ef.svg"), none; }

.em-ring {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48d.png"); }

.em-ring.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f48d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f48d.svg"), none; }

.em-robot_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f916.png"); }

.em-robot_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f916.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f916.svg"), none; }

.em-rocket {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f680.png"); }

.em-rocket.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f680.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f680.svg"), none; }

.em-rolled_up_newspaper {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5de.png"); }

.em-rolled_up_newspaper.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5de.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5de.svg"), none; }

.em-roller_coaster {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a2.png"); }

.em-roller_coaster.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3a2.svg"), none; }

.em-rolling_on_the_floor_laughing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f923.png"); }

.em-rolling_on_the_floor_laughing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f923.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f923.svg"), none; }

.em-rooster {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f413.png"); }

.em-rooster.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f413.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f413.svg"), none; }

.em-rose {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f339.png"); }

.em-rose.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f339.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f339.svg"), none; }

.em-rosette {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f5.png"); }

.em-rosette.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3f5.svg"), none; }

.em-rotating_light {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a8.png"); }

.em-rotating_light.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a8.svg"), none; }

.em-round_pushpin {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4cd.png"); }

.em-round_pushpin.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4cd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4cd.svg"), none; }

.em-rowboat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a3.png"); }

.em-rowboat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a3.svg"), none; }

.em-ru, .em-flag-ru {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f7-1f1fa.png"); }

.em-ru.em-svg, .em-flag-ru.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1f7-1f1fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1f7-1f1fa.svg"), none; }

.em-rugby_football {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c9.png"); }

.em-rugby_football.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c9.svg"), none; }

.em-runner, .em-running {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c3.png"); }

.em-runner.em-svg, .em-running.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c3.svg"), none; }

.em-running_shirt_with_sash {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3bd.png"); }

.em-running_shirt_with_sash.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3bd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3bd.svg"), none; }

.em-sa {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f202.png"); }

.em-sa.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f202.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f202.svg"), none; }

.em-sagittarius {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2650.png"); }

.em-sagittarius.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2650.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2650.svg"), none; }

.em-sake {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f376.png"); }

.em-sake.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f376.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f376.svg"), none; }

.em-sandal {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f461.png"); }

.em-sandal.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f461.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f461.svg"), none; }

.em-sandwich {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f96a.png"); }

.em-sandwich.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f96a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f96a.svg"), none; }

.em-santa {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f385.png"); }

.em-santa.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f385.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f385.svg"), none; }

.em-satellite {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f0.png"); }

.em-satellite.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6f0.svg"), none; }

.em-satellite_antenna {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e1.png"); }

.em-satellite_antenna.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4e1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4e1.svg"), none; }

.em-sauropod {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f995.png"); }

.em-sauropod.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f995.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f995.svg"), none; }

.em-saxophone {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b7.png"); }

.em-saxophone.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3b7.svg"), none; }

.em-scales {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2696.png"); }

.em-scales.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2696.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2696.svg"), none; }

.em-scarf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e3.png"); }

.em-scarf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9e3.svg"), none; }

.em-school {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3eb.png"); }

.em-school.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3eb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3eb.svg"), none; }

.em-school_satchel {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f392.png"); }

.em-school_satchel.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f392.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f392.svg"), none; }

.em-scissors {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2702.png"); }

.em-scissors.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2702.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2702.svg"), none; }

.em-scooter {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f4.png"); }

.em-scooter.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6f4.svg"), none; }

.em-scorpion {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f982.png"); }

.em-scorpion.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f982.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f982.svg"), none; }

.em-scorpius {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264f.png"); }

.em-scorpius.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/264f.svg"), none; }

.em-scream {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f631.png"); }

.em-scream.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f631.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f631.svg"), none; }

.em-scream_cat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f640.png"); }

.em-scream_cat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f640.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f640.svg"), none; }

.em-scroll {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4dc.png"); }

.em-scroll.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4dc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4dc.svg"), none; }

.em-seat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ba.png"); }

.em-seat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ba.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ba.svg"), none; }

.em-second_place_medal {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f948.png"); }

.em-second_place_medal.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f948.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f948.svg"), none; }

.em-secret {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/3299.png"); }

.em-secret.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/3299.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/3299.svg"), none; }

.em-see_no_evil {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f648.png"); }

.em-see_no_evil.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f648.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f648.svg"), none; }

.em-seedling {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f331.png"); }

.em-seedling.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f331.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f331.svg"), none; }

.em-selfie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f933.png"); }

.em-selfie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f933.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f933.svg"), none; }

.em-seven {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/37-20e3.png"); }

.em-seven.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/37-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/37-20e3.svg"), none; }

.em-shallow_pan_of_food {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f958.png"); }

.em-shallow_pan_of_food.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f958.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f958.svg"), none; }

.em-shamrock {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2618.png"); }

.em-shamrock.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2618.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2618.svg"), none; }

.em-shark {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f988.png"); }

.em-shark.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f988.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f988.svg"), none; }

.em-shaved_ice {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f367.png"); }

.em-shaved_ice.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f367.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f367.svg"), none; }

.em-sheep {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f411.png"); }

.em-sheep.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f411.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f411.svg"), none; }

.em-shell {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41a.png"); }

.em-shell.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f41a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f41a.svg"), none; }

.em-shield {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e1.png"); }

.em-shield.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6e1.svg"), none; }

.em-shinto_shrine {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26e9.png"); }

.em-shinto_shrine.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26e9.svg"), none; }

.em-ship {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a2.png"); }

.em-ship.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a2.svg"), none; }

.em-shirt, .em-tshirt {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f455.png"); }

.em-shirt.em-svg, .em-tshirt.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f455.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f455.svg"), none; }

.em-shopping_bags {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6cd.png"); }

.em-shopping_bags.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6cd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6cd.svg"), none; }

.em-shopping_trolley {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6d2.png"); }

.em-shopping_trolley.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6d2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6d2.svg"), none; }

.em-shower {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6bf.png"); }

.em-shower.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6bf.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6bf.svg"), none; }

.em-shrimp {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f990.png"); }

.em-shrimp.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f990.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f990.svg"), none; }

.em-shrug {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f937.png"); }

.em-shrug.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f937.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f937.svg"), none; }

.em-shushing_face, .em-face_with_finger_covering_closed_lips {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92b.png"); }

.em-shushing_face.em-svg, .em-face_with_finger_covering_closed_lips.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f92b.svg"), none; }

.em-signal_strength {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f6.png"); }

.em-signal_strength.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4f6.svg"), none; }

.em-six {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/36-20e3.png"); }

.em-six.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/36-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/36-20e3.svg"), none; }

.em-six_pointed_star {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52f.png"); }

.em-six_pointed_star.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f52f.svg"), none; }

.em-ski {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3bf.png"); }

.em-ski.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3bf.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3bf.svg"), none; }

.em-skier {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f7.png"); }

.em-skier.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26f7.svg"), none; }

.em-skin-tone-2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3fb.png"); }

.em-skin-tone-2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3fb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3fb.svg"), none; }

.em-skin-tone-3 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3fc.png"); }

.em-skin-tone-3.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3fc.svg"), none; }

.em-skin-tone-4 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3fd.png"); }

.em-skin-tone-4.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3fd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3fd.svg"), none; }

.em-skin-tone-5 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3fe.png"); }

.em-skin-tone-5.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3fe.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3fe.svg"), none; }

.em-skin-tone-6 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ff.png"); }

.em-skin-tone-6.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ff.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ff.svg"), none; }

.em-skull {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f480.png"); }

.em-skull.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f480.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f480.svg"), none; }

.em-skull_and_crossbones {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2620.png"); }

.em-skull_and_crossbones.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2620.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2620.svg"), none; }

.em-sled {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f7.png"); }

.em-sled.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6f7.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6f7.svg"), none; }

.em-sleeping {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f634.png"); }

.em-sleeping.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f634.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f634.svg"), none; }

.em-sleeping_accommodation {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6cc.png"); }

.em-sleeping_accommodation.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6cc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6cc.svg"), none; }

.em-sleepy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62a.png"); }

.em-sleepy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f62a.svg"), none; }

.em-sleuth_or_spy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f575.png"); }

.em-sleuth_or_spy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f575.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f575.svg"), none; }

.em-slightly_frowning_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f641.png"); }

.em-slightly_frowning_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f641.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f641.svg"), none; }

.em-slightly_smiling_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f642.png"); }

.em-slightly_smiling_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f642.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f642.svg"), none; }

.em-slot_machine {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b0.png"); }

.em-slot_machine.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3b0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3b0.svg"), none; }

.em-small_airplane {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e9.png"); }

.em-small_airplane.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6e9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6e9.svg"), none; }

.em-small_blue_diamond {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f539.png"); }

.em-small_blue_diamond.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f539.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f539.svg"), none; }

.em-small_orange_diamond {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f538.png"); }

.em-small_orange_diamond.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f538.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f538.svg"), none; }

.em-small_red_triangle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f53a.png"); }

.em-small_red_triangle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f53a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f53a.svg"), none; }

.em-small_red_triangle_down {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f53b.png"); }

.em-small_red_triangle_down.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f53b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f53b.svg"), none; }

.em-smile {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f604.png"); }

.em-smile.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f604.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f604.svg"), none; }

.em-smile_cat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f638.png"); }

.em-smile_cat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f638.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f638.svg"), none; }

.em-smiley {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f603.png"); }

.em-smiley.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f603.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f603.svg"), none; }

.em-smiley_cat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63a.png"); }

.em-smiley_cat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f63a.svg"), none; }

.em-smiling_imp {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f608.png"); }

.em-smiling_imp.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f608.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f608.svg"), none; }

.em-smirk {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60f.png"); }

.em-smirk.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f60f.svg"), none; }

.em-smirk_cat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63c.png"); }

.em-smirk_cat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f63c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f63c.svg"), none; }

.em-smoking {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ac.png"); }

.em-smoking.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ac.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6ac.svg"), none; }

.em-snail {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40c.png"); }

.em-snail.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f40c.svg"), none; }

.em-snake {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40d.png"); }

.em-snake.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f40d.svg"), none; }

.em-sneezing_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f927.png"); }

.em-sneezing_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f927.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f927.svg"), none; }

.em-snow_capped_mountain {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d4.png"); }

.em-snow_capped_mountain.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3d4.svg"), none; }

.em-snow_cloud {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f328.png"); }

.em-snow_cloud.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f328.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f328.svg"), none; }

.em-snowboarder {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c2.png"); }

.em-snowboarder.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c2.svg"), none; }

.em-snowflake {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2744.png"); }

.em-snowflake.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2744.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2744.svg"), none; }

.em-snowman {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2603.png"); }

.em-snowman.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2603.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2603.svg"), none; }

.em-snowman_without_snow {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26c4.png"); }

.em-snowman_without_snow.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26c4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26c4.svg"), none; }

.em-sob {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62d.png"); }

.em-sob.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f62d.svg"), none; }

.em-soccer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26bd.png"); }

.em-soccer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26bd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26bd.svg"), none; }

.em-socks {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e6.png"); }

.em-socks.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9e6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9e6.svg"), none; }

.em-soon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51c.png"); }

.em-soon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f51c.svg"), none; }

.em-sos {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f198.png"); }

.em-sos.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f198.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f198.svg"), none; }

.em-sound {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f509.png"); }

.em-sound.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f509.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f509.svg"), none; }

.em-space_invader {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47e.png"); }

.em-space_invader.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f47e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f47e.svg"), none; }

.em-spades {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2660.png"); }

.em-spades.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2660.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2660.svg"), none; }

.em-spaghetti {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35d.png"); }

.em-spaghetti.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f35d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f35d.svg"), none; }

.em-sparkle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2747.png"); }

.em-sparkle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2747.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2747.svg"), none; }

.em-sparkler {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f387.png"); }

.em-sparkler.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f387.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f387.svg"), none; }

.em-sparkles {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2728.png"); }

.em-sparkles.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2728.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2728.svg"), none; }

.em-sparkling_heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f496.png"); }

.em-sparkling_heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f496.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f496.svg"), none; }

.em-speak_no_evil {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64a.png"); }

.em-speak_no_evil.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64a.svg"), none; }

.em-speaker {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f508.png"); }

.em-speaker.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f508.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f508.svg"), none; }

.em-speaking_head_in_silhouette {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5e3.png"); }

.em-speaking_head_in_silhouette.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5e3.svg"), none; }

.em-speech_balloon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ac.png"); }

.em-speech_balloon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ac.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ac.svg"), none; }

.em-speedboat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a4.png"); }

.em-speedboat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a4.svg"), none; }

.em-spider {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f577.png"); }

.em-spider.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f577.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f577.svg"), none; }

.em-spider_web {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f578.png"); }

.em-spider_web.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f578.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f578.svg"), none; }

.em-spiral_calendar_pad {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5d3.png"); }

.em-spiral_calendar_pad.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5d3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5d3.svg"), none; }

.em-spiral_note_pad {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5d2.png"); }

.em-spiral_note_pad.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5d2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5d2.svg"), none; }

.em-spock-hand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f596.png"); }

.em-spock-hand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f596.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f596.svg"), none; }

.em-spoon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f944.png"); }

.em-spoon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f944.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f944.svg"), none; }

.em-sports_medal {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c5.png"); }

.em-sports_medal.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c5.svg"), none; }

.em-squid {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f991.png"); }

.em-squid.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f991.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f991.svg"), none; }

.em-stadium {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3df.png"); }

.em-stadium.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3df.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3df.svg"), none; }

.em-star {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b50.png"); }

.em-star.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b50.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2b50.svg"), none; }

.em-star-struck, .em-grinning_face_with_star_eyes {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f929.png"); }

.em-star-struck.em-svg, .em-grinning_face_with_star_eyes.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f929.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f929.svg"), none; }

.em-star2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31f.png"); }

.em-star2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f31f.svg"), none; }

.em-star_and_crescent {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/262a.png"); }

.em-star_and_crescent.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/262a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/262a.svg"), none; }

.em-star_of_david {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2721.png"); }

.em-star_of_david.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2721.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2721.svg"), none; }

.em-stars {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f320.png"); }

.em-stars.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f320.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f320.svg"), none; }

.em-station {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f689.png"); }

.em-station.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f689.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f689.svg"), none; }

.em-statue_of_liberty {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5fd.png"); }

.em-statue_of_liberty.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5fd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5fd.svg"), none; }

.em-steam_locomotive {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f682.png"); }

.em-steam_locomotive.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f682.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f682.svg"), none; }

.em-stew {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f372.png"); }

.em-stew.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f372.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f372.svg"), none; }

.em-stopwatch {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f1.png"); }

.em-stopwatch.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23f1.svg"), none; }

.em-straight_ruler {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4cf.png"); }

.em-straight_ruler.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4cf.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4cf.svg"), none; }

.em-strawberry {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f353.png"); }

.em-strawberry.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f353.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f353.svg"), none; }

.em-stuck_out_tongue {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61b.png"); }

.em-stuck_out_tongue.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f61b.svg"), none; }

.em-stuck_out_tongue_closed_eyes {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61d.png"); }

.em-stuck_out_tongue_closed_eyes.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f61d.svg"), none; }

.em-stuck_out_tongue_winking_eye {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61c.png"); }

.em-stuck_out_tongue_winking_eye.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f61c.svg"), none; }

.em-studio_microphone {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f399.png"); }

.em-studio_microphone.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f399.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f399.svg"), none; }

.em-stuffed_flatbread {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f959.png"); }

.em-stuffed_flatbread.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f959.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f959.svg"), none; }

.em-sun_with_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31e.png"); }

.em-sun_with_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f31e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f31e.svg"), none; }

.em-sunflower {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33b.png"); }

.em-sunflower.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f33b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f33b.svg"), none; }

.em-sunglasses {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60e.png"); }

.em-sunglasses.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f60e.svg"), none; }

.em-sunny {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2600.png"); }

.em-sunny.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2600.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2600.svg"), none; }

.em-sunrise {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f305.png"); }

.em-sunrise.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f305.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f305.svg"), none; }

.em-sunrise_over_mountains {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f304.png"); }

.em-sunrise_over_mountains.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f304.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f304.svg"), none; }

.em-surfer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c4.png"); }

.em-surfer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c4.svg"), none; }

.em-sushi {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f363.png"); }

.em-sushi.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f363.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f363.svg"), none; }

.em-suspension_railway {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69f.png"); }

.em-suspension_railway.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f69f.svg"), none; }

.em-sweat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f613.png"); }

.em-sweat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f613.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f613.svg"), none; }

.em-sweat_drops {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a6.png"); }

.em-sweat_drops.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4a6.svg"), none; }

.em-sweat_smile {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f605.png"); }

.em-sweat_smile.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f605.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f605.svg"), none; }

.em-sweet_potato {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f360.png"); }

.em-sweet_potato.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f360.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f360.svg"), none; }

.em-swimmer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ca.png"); }

.em-swimmer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ca.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ca.svg"), none; }

.em-symbols {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f523.png"); }

.em-symbols.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f523.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f523.svg"), none; }

.em-synagogue {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f54d.png"); }

.em-synagogue.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f54d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f54d.svg"), none; }

.em-syringe {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f489.png"); }

.em-syringe.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f489.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f489.svg"), none; }

.em-t-rex {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f996.png"); }

.em-t-rex.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f996.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f996.svg"), none; }

.em-table_tennis_paddle_and_ball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d3.png"); }

.em-table_tennis_paddle_and_ball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3d3.svg"), none; }

.em-taco {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32e.png"); }

.em-taco.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f32e.svg"), none; }

.em-tada {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f389.png"); }

.em-tada.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f389.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f389.svg"), none; }

.em-takeout_box {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f961.png"); }

.em-takeout_box.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f961.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f961.svg"), none; }

.em-tanabata_tree {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38b.png"); }

.em-tanabata_tree.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f38b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f38b.svg"), none; }

.em-tangerine {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34a.png"); }

.em-tangerine.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f34a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f34a.svg"), none; }

.em-taurus {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2649.png"); }

.em-taurus.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2649.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2649.svg"), none; }

.em-taxi {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f695.png"); }

.em-taxi.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f695.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f695.svg"), none; }

.em-tea {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f375.png"); }

.em-tea.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f375.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f375.svg"), none; }

.em-telephone_receiver {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4de.png"); }

.em-telephone_receiver.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4de.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4de.svg"), none; }

.em-telescope {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52d.png"); }

.em-telescope.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f52d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f52d.svg"), none; }

.em-tennis {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3be.png"); }

.em-tennis.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3be.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3be.svg"), none; }

.em-tent {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26fa.png"); }

.em-tent.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26fa.svg"), none; }

.em-the_horns, .em-sign_of_the_horns {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f918.png"); }

.em-the_horns.em-svg, .em-sign_of_the_horns.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f918.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f918.svg"), none; }

.em-thermometer {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f321.png"); }

.em-thermometer.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f321.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f321.svg"), none; }

.em-thinking_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f914.png"); }

.em-thinking_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f914.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f914.svg"), none; }

.em-third_place_medal {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f949.png"); }

.em-third_place_medal.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f949.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f949.svg"), none; }

.em-thought_balloon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ad.png"); }

.em-thought_balloon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ad.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ad.svg"), none; }

.em-three {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/33-20e3.png"); }

.em-three.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/33-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/33-20e3.svg"), none; }

.em-three_button_mouse {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5b1.png"); }

.em-three_button_mouse.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5b1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5b1.svg"), none; }

.em-thunder_cloud_and_rain {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26c8.png"); }

.em-thunder_cloud_and_rain.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26c8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26c8.svg"), none; }

.em-ticket {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ab.png"); }

.em-ticket.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ab.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ab.svg"), none; }

.em-tiger {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42f.png"); }

.em-tiger.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f42f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f42f.svg"), none; }

.em-tiger2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f405.png"); }

.em-tiger2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f405.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f405.svg"), none; }

.em-timer_clock {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f2.png"); }

.em-timer_clock.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/23f2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/23f2.svg"), none; }

.em-tired_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62b.png"); }

.em-tired_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f62b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f62b.svg"), none; }

.em-tm {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2122.png"); }

.em-tm.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2122.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2122.svg"), none; }

.em-toilet {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6bd.png"); }

.em-toilet.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6bd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6bd.svg"), none; }

.em-tokyo_tower {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5fc.png"); }

.em-tokyo_tower.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5fc.svg"), none; }

.em-tomato {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f345.png"); }

.em-tomato.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f345.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f345.svg"), none; }

.em-tongue {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f445.png"); }

.em-tongue.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f445.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f445.svg"), none; }

.em-top {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51d.png"); }

.em-top.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f51d.svg"), none; }

.em-tophat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a9.png"); }

.em-tophat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3a9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3a9.svg"), none; }

.em-tornado, .em-tornado_cloud {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32a.png"); }

.em-tornado.em-svg, .em-tornado_cloud.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f32a.svg"), none; }

.em-trackball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5b2.png"); }

.em-trackball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5b2.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5b2.svg"), none; }

.em-tractor {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69c.png"); }

.em-tractor.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f69c.svg"), none; }

.em-traffic_light {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a5.png"); }

.em-traffic_light.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a5.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a5.svg"), none; }

.em-train {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68b.png"); }

.em-train.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f68b.svg"), none; }

.em-train2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f686.png"); }

.em-train2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f686.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f686.svg"), none; }

.em-tram {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68a.png"); }

.em-tram.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f68a.svg"), none; }

.em-triangular_flag_on_post {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a9.png"); }

.em-triangular_flag_on_post.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a9.svg"), none; }

.em-triangular_ruler {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d0.png"); }

.em-triangular_ruler.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4d0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4d0.svg"), none; }

.em-trident {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f531.png"); }

.em-trident.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f531.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f531.svg"), none; }

.em-triumph {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f624.png"); }

.em-triumph.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f624.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f624.svg"), none; }

.em-trolleybus {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68e.png"); }

.em-trolleybus.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f68e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f68e.svg"), none; }

.em-trophy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c6.png"); }

.em-trophy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c6.svg"), none; }

.em-tropical_drink {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f379.png"); }

.em-tropical_drink.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f379.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f379.svg"), none; }

.em-tropical_fish {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f420.png"); }

.em-tropical_fish.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f420.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f420.svg"), none; }

.em-truck {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69a.png"); }

.em-truck.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f69a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f69a.svg"), none; }

.em-trumpet {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ba.png"); }

.em-trumpet.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ba.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ba.svg"), none; }

.em-tulip {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f337.png"); }

.em-tulip.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f337.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f337.svg"), none; }

.em-tumbler_glass {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f943.png"); }

.em-tumbler_glass.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f943.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f943.svg"), none; }

.em-turkey {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f983.png"); }

.em-turkey.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f983.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f983.svg"), none; }

.em-turtle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f422.png"); }

.em-turtle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f422.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f422.svg"), none; }

.em-tv {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4fa.png"); }

.em-tv.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4fa.svg"), none; }

.em-twisted_rightwards_arrows {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f500.png"); }

.em-twisted_rightwards_arrows.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f500.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f500.svg"), none; }

.em-two {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/32-20e3.png"); }

.em-two.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/32-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/32-20e3.svg"), none; }

.em-two_hearts {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f495.png"); }

.em-two_hearts.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f495.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f495.svg"), none; }

.em-two_men_holding_hands {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46c.png"); }

.em-two_men_holding_hands.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f46c.svg"), none; }

.em-two_women_holding_hands {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46d.png"); }

.em-two_women_holding_hands.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f46d.svg"), none; }

.em-u5272 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f239.png"); }

.em-u5272.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f239.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f239.svg"), none; }

.em-u5408 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f234.png"); }

.em-u5408.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f234.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f234.svg"), none; }

.em-u55b6 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f23a.png"); }

.em-u55b6.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f23a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f23a.svg"), none; }

.em-u6307 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f22f.png"); }

.em-u6307.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f22f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f22f.svg"), none; }

.em-u6708 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f237.png"); }

.em-u6708.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f237.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f237.svg"), none; }

.em-u6709 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f236.png"); }

.em-u6709.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f236.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f236.svg"), none; }

.em-u6e80 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f235.png"); }

.em-u6e80.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f235.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f235.svg"), none; }

.em-u7121 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f21a.png"); }

.em-u7121.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f21a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f21a.svg"), none; }

.em-u7533 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f238.png"); }

.em-u7533.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f238.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f238.svg"), none; }

.em-u7981 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f232.png"); }

.em-u7981.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f232.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f232.svg"), none; }

.em-u7a7a {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f233.png"); }

.em-u7a7a.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f233.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f233.svg"), none; }

.em-umbrella {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2602.png"); }

.em-umbrella.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2602.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2602.svg"), none; }

.em-umbrella_on_ground {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f1.png"); }

.em-umbrella_on_ground.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26f1.svg"), none; }

.em-umbrella_with_rain_drops {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2614.png"); }

.em-umbrella_with_rain_drops.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2614.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2614.svg"), none; }

.em-unamused {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f612.png"); }

.em-unamused.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f612.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f612.svg"), none; }

.em-underage {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51e.png"); }

.em-underage.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f51e.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f51e.svg"), none; }

.em-unicorn_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f984.png"); }

.em-unicorn_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f984.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f984.svg"), none; }

.em-unlock {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f513.png"); }

.em-unlock.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f513.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f513.svg"), none; }

.em-up {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f199.png"); }

.em-up.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f199.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f199.svg"), none; }

.em-upside_down_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f643.png"); }

.em-upside_down_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f643.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f643.svg"), none; }

.em-us, .em-flag-us {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1f8.png"); }

.em-us.em-svg, .em-flag-us.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f1fa-1f1f8.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f1fa-1f1f8.svg"), none; }

.em-v {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/270c.png"); }

.em-v.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/270c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/270c.svg"), none; }

.em-vampire {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9db.png"); }

.em-vampire.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9db.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9db.svg"), none; }

.em-vertical_traffic_light {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a6.png"); }

.em-vertical_traffic_light.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a6.svg"), none; }

.em-vhs {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4fc.png"); }

.em-vhs.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4fc.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4fc.svg"), none; }

.em-vibration_mode {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f3.png"); }

.em-vibration_mode.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4f3.svg"), none; }

.em-video_camera {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f9.png"); }

.em-video_camera.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4f9.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4f9.svg"), none; }

.em-video_game {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ae.png"); }

.em-video_game.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ae.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ae.svg"), none; }

.em-violin {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3bb.png"); }

.em-violin.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3bb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3bb.svg"), none; }

.em-virgo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264d.png"); }

.em-virgo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/264d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/264d.svg"), none; }

.em-volcano {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30b.png"); }

.em-volcano.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f30b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f30b.svg"), none; }

.em-volleyball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d0.png"); }

.em-volleyball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3d0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3d0.svg"), none; }

.em-vs {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f19a.png"); }

.em-vs.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f19a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f19a.svg"), none; }

.em-walking {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b6.png"); }

.em-walking.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b6.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b6.svg"), none; }

.em-waning_crescent_moon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f318.png"); }

.em-waning_crescent_moon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f318.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f318.svg"), none; }

.em-waning_gibbous_moon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f316.png"); }

.em-waning_gibbous_moon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f316.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f316.svg"), none; }

.em-warning {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26a0.png"); }

.em-warning.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26a0.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26a0.svg"), none; }

.em-wastebasket {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5d1.png"); }

.em-wastebasket.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5d1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5d1.svg"), none; }

.em-watch {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/231a.png"); }

.em-watch.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/231a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/231a.svg"), none; }

.em-water_buffalo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f403.png"); }

.em-water_buffalo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f403.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f403.svg"), none; }

.em-water_polo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93d.png"); }

.em-water_polo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f93d.svg"), none; }

.em-watermelon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f349.png"); }

.em-watermelon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f349.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f349.svg"), none; }

.em-wave {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44b.png"); }

.em-wave.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f44b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f44b.svg"), none; }

.em-waving_black_flag {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f4.png"); }

.em-waving_black_flag.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3f4.svg"), none; }

.em-waving_white_flag {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f3.png"); }

.em-waving_white_flag.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3f3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3f3.svg"), none; }

.em-wavy_dash {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/3030.png"); }

.em-wavy_dash.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/3030.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/3030.svg"), none; }

.em-waxing_crescent_moon {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f312.png"); }

.em-waxing_crescent_moon.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f312.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f312.svg"), none; }

.em-wc {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6be.png"); }

.em-wc.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6be.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6be.svg"), none; }

.em-weary {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f629.png"); }

.em-weary.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f629.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f629.svg"), none; }

.em-wedding {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f492.png"); }

.em-wedding.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f492.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f492.svg"), none; }

.em-weight_lifter {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cb.png"); }

.em-weight_lifter.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3cb.svg"), none; }

.em-whale {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f433.png"); }

.em-whale.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f433.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f433.svg"), none; }

.em-whale2 {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40b.png"); }

.em-whale2.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f40b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f40b.svg"), none; }

.em-wheel_of_dharma {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2638.png"); }

.em-wheel_of_dharma.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2638.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2638.svg"), none; }

.em-wheelchair {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/267f.png"); }

.em-wheelchair.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/267f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/267f.svg"), none; }

.em-white_check_mark {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2705.png"); }

.em-white_check_mark.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2705.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2705.svg"), none; }

.em-white_circle {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26aa.png"); }

.em-white_circle.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26aa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26aa.svg"), none; }

.em-white_flower {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ae.png"); }

.em-white_flower.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4ae.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4ae.svg"), none; }

.em-white_frowning_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2639.png"); }

.em-white_frowning_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2639.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2639.svg"), none; }

.em-white_large_square {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b1c.png"); }

.em-white_large_square.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/2b1c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/2b1c.svg"), none; }

.em-white_medium_small_square {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25fd.png"); }

.em-white_medium_small_square.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25fd.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/25fd.svg"), none; }

.em-white_medium_square {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25fb.png"); }

.em-white_medium_square.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25fb.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/25fb.svg"), none; }

.em-white_small_square {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25ab.png"); }

.em-white_small_square.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/25ab.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/25ab.svg"), none; }

.em-white_square_button {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f533.png"); }

.em-white_square_button.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f533.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f533.svg"), none; }

.em-wilted_flower {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f940.png"); }

.em-wilted_flower.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f940.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f940.svg"), none; }

.em-wind_blowing_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32c.png"); }

.em-wind_blowing_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f32c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f32c.svg"), none; }

.em-wind_chime {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f390.png"); }

.em-wind_chime.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f390.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f390.svg"), none; }

.em-wine_glass {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f377.png"); }

.em-wine_glass.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f377.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f377.svg"), none; }

.em-wink {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f609.png"); }

.em-wink.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f609.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f609.svg"), none; }

.em-wolf {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43a.png"); }

.em-wolf.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f43a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f43a.svg"), none; }

.em-woman {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469.png"); }

.em-woman.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469.svg"), none; }

.em-woman-biking {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b4-200d-2640-fe0f.png"); }

.em-woman-biking.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b4-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b4-200d-2640-fe0f.svg"), none; }

.em-woman-bouncing-ball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f9-fe0f-200d-2640-fe0f.png"); }

.em-woman-bouncing-ball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26f9-fe0f-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26f9-fe0f-200d-2640-fe0f.svg"), none; }

.em-woman-bowing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f647-200d-2640-fe0f.png"); }

.em-woman-bowing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f647-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f647-200d-2640-fe0f.svg"), none; }

.em-woman-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f466.png"); }

.em-woman-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f466.svg"), none; }

.em-woman-boy-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f466-200d-1f466.png"); }

.em-woman-boy-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f466-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f466-200d-1f466.svg"), none; }

.em-woman-cartwheeling {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f938-200d-2640-fe0f.png"); }

.em-woman-cartwheeling.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f938-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f938-200d-2640-fe0f.svg"), none; }

.em-woman-facepalming {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f926-200d-2640-fe0f.png"); }

.em-woman-facepalming.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f926-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f926-200d-2640-fe0f.svg"), none; }

.em-woman-frowning {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64d-200d-2640-fe0f.png"); }

.em-woman-frowning.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64d-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64d-200d-2640-fe0f.svg"), none; }

.em-woman-gesturing-no {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f645-200d-2640-fe0f.png"); }

.em-woman-gesturing-no.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f645-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f645-200d-2640-fe0f.svg"), none; }

.em-woman-gesturing-ok {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f646-200d-2640-fe0f.png"); }

.em-woman-gesturing-ok.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f646-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f646-200d-2640-fe0f.svg"), none; }

.em-woman-getting-haircut {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f487-200d-2640-fe0f.png"); }

.em-woman-getting-haircut.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f487-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f487-200d-2640-fe0f.svg"), none; }

.em-woman-getting-massage {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f486-200d-2640-fe0f.png"); }

.em-woman-getting-massage.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f486-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f486-200d-2640-fe0f.svg"), none; }

.em-woman-girl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f467.png"); }

.em-woman-girl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f467.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f467.svg"), none; }

.em-woman-girl-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f467-200d-1f466.png"); }

.em-woman-girl-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f467-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f467-200d-1f466.svg"), none; }

.em-woman-girl-girl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f467-200d-1f467.png"); }

.em-woman-girl-girl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f467-200d-1f467.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f467-200d-1f467.svg"), none; }

.em-woman-golfing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cc-fe0f-200d-2640-fe0f.png"); }

.em-woman-golfing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cc-fe0f-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3cc-fe0f-200d-2640-fe0f.svg"), none; }

.em-woman-heart-man {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2764-fe0f-200d-1f468.png"); }

.em-woman-heart-man.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2764-fe0f-200d-1f468.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-2764-fe0f-200d-1f468.svg"), none; }

.em-woman-heart-woman {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2764-fe0f-200d-1f469.png"); }

.em-woman-heart-woman.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2764-fe0f-200d-1f469.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-2764-fe0f-200d-1f469.svg"), none; }

.em-woman-juggling {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f939-200d-2640-fe0f.png"); }

.em-woman-juggling.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f939-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f939-200d-2640-fe0f.svg"), none; }

.em-woman-kiss-man {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.png"); }

.em-woman-kiss-man.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.svg"), none; }

.em-woman-kiss-woman {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.png"); }

.em-woman-kiss-woman.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.svg"), none; }

.em-woman-lifting-weights {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cb-fe0f-200d-2640-fe0f.png"); }

.em-woman-lifting-weights.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3cb-fe0f-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3cb-fe0f-200d-2640-fe0f.svg"), none; }

.em-woman-mountain-biking {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b5-200d-2640-fe0f.png"); }

.em-woman-mountain-biking.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b5-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b5-200d-2640-fe0f.svg"), none; }

.em-woman-playing-handball {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93e-200d-2640-fe0f.png"); }

.em-woman-playing-handball.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93e-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f93e-200d-2640-fe0f.svg"), none; }

.em-woman-playing-water-polo {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93d-200d-2640-fe0f.png"); }

.em-woman-playing-water-polo.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93d-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f93d-200d-2640-fe0f.svg"), none; }

.em-woman-pouting {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64e-200d-2640-fe0f.png"); }

.em-woman-pouting.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64e-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64e-200d-2640-fe0f.svg"), none; }

.em-woman-raising-hand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64b-200d-2640-fe0f.png"); }

.em-woman-raising-hand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f64b-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f64b-200d-2640-fe0f.svg"), none; }

.em-woman-rowing-boat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a3-200d-2640-fe0f.png"); }

.em-woman-rowing-boat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6a3-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6a3-200d-2640-fe0f.svg"), none; }

.em-woman-running {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c3-200d-2640-fe0f.png"); }

.em-woman-running.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c3-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c3-200d-2640-fe0f.svg"), none; }

.em-woman-shrugging {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f937-200d-2640-fe0f.png"); }

.em-woman-shrugging.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f937-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f937-200d-2640-fe0f.svg"), none; }

.em-woman-surfing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c4-200d-2640-fe0f.png"); }

.em-woman-surfing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3c4-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3c4-200d-2640-fe0f.svg"), none; }

.em-woman-swimming {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ca-200d-2640-fe0f.png"); }

.em-woman-swimming.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f3ca-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f3ca-200d-2640-fe0f.svg"), none; }

.em-woman-tipping-hand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f481-200d-2640-fe0f.png"); }

.em-woman-tipping-hand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f481-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f481-200d-2640-fe0f.svg"), none; }

.em-woman-walking {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b6-200d-2640-fe0f.png"); }

.em-woman-walking.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6b6-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6b6-200d-2640-fe0f.svg"), none; }

.em-woman-wearing-turban {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f473-200d-2640-fe0f.png"); }

.em-woman-wearing-turban.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f473-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f473-200d-2640-fe0f.svg"), none; }

.em-woman-with-bunny-ears-partying {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46f-200d-2640-fe0f.png"); }

.em-woman-with-bunny-ears-partying.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f46f-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f46f-200d-2640-fe0f.svg"), none; }

.em-woman-woman-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f469-200d-1f466.png"); }

.em-woman-woman-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f469-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f469-200d-1f466.svg"), none; }

.em-woman-woman-boy-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f469-200d-1f466-200d-1f466.png"); }

.em-woman-woman-boy-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f469-200d-1f466-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f469-200d-1f466-200d-1f466.svg"), none; }

.em-woman-woman-girl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f469-200d-1f467.png"); }

.em-woman-woman-girl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f469-200d-1f467.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f469-200d-1f467.svg"), none; }

.em-woman-woman-girl-boy {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f469-200d-1f467-200d-1f466.png"); }

.em-woman-woman-girl-boy.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f469-200d-1f467-200d-1f466.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f469-200d-1f467-200d-1f466.svg"), none; }

.em-woman-woman-girl-girl {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f469-200d-1f467-200d-1f467.png"); }

.em-woman-woman-girl-girl.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f469-200d-1f469-200d-1f467-200d-1f467.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f469-200d-1f469-200d-1f467-200d-1f467.svg"), none; }

.em-woman-wrestling {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93c-200d-2640-fe0f.png"); }

.em-woman-wrestling.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93c-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f93c-200d-2640-fe0f.svg"), none; }

.em-woman_climbing {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d7-200d-2640-fe0f.png"); }

.em-woman_climbing.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d7-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d7-200d-2640-fe0f.svg"), none; }

.em-woman_in_lotus_position {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d8-200d-2640-fe0f.png"); }

.em-woman_in_lotus_position.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d8-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d8-200d-2640-fe0f.svg"), none; }

.em-woman_in_steamy_room {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d6-200d-2640-fe0f.png"); }

.em-woman_in_steamy_room.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9d6-200d-2640-fe0f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9d6-200d-2640-fe0f.svg"), none; }

.em-womans_clothes {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45a.png"); }

.em-womans_clothes.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f45a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f45a.svg"), none; }

.em-womans_hat {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f452.png"); }

.em-womans_hat.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f452.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f452.svg"), none; }

.em-womens {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ba.png"); }

.em-womens.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f6ba.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f6ba.svg"), none; }

.em-world_map {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5fa.png"); }

.em-world_map.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f5fa.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f5fa.svg"), none; }

.em-worried {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61f.png"); }

.em-worried.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f61f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f61f.svg"), none; }

.em-wrench {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f527.png"); }

.em-wrench.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f527.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f527.svg"), none; }

.em-wrestlers {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93c.png"); }

.em-wrestlers.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f93c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f93c.svg"), none; }

.em-writing_hand {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/270d.png"); }

.em-writing_hand.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/270d.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/270d.svg"), none; }

.em-x {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/274c.png"); }

.em-x.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/274c.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/274c.svg"), none; }

.em-yellow_heart {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49b.png"); }

.em-yellow_heart.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f49b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f49b.svg"), none; }

.em-yen {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b4.png"); }

.em-yen.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4b4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4b4.svg"), none; }

.em-yin_yang {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/262f.png"); }

.em-yin_yang.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/262f.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/262f.svg"), none; }

.em-yum {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60b.png"); }

.em-yum.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f60b.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f60b.svg"), none; }

.em-zany_face, .em-grinning_face_with_one_large_and_one_small_eye {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92a.png"); }

.em-zany_face.em-svg, .em-grinning_face_with_one_large_and_one_small_eye.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f92a.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f92a.svg"), none; }

.em-zap {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26a1.png"); }

.em-zap.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/26a1.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/26a1.svg"), none; }

.em-zebra_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f993.png"); }

.em-zebra_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f993.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f993.svg"), none; }

.em-zero {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/30-20e3.png"); }

.em-zero.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/30-20e3.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/30-20e3.svg"), none; }

.em-zipper_mouth_face {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f910.png"); }

.em-zipper_mouth_face.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f910.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f910.svg"), none; }

.em-zombie {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9df.png"); }

.em-zombie.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f9df.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f9df.svg"), none; }

.em-zzz {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a4.png"); }

.em-zzz.em-svg {
  background: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/72x72/1f4a4.png");
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f4a4.svg"), none; }

.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
  border-radius: 4px; }

.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden; }

.ql-container.ql-disabled .ql-editor ul[data-checked] > li::before {
  pointer-events: none; }

.ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%; }

.ql-clipboard p {
  margin: 0;
  padding: 0; }

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 8px 12px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word; }
  .ql-editor .Emoji {
    padding: 10px;
    border: 0;
    border-style: none;
    outline: 0;
    width: 22px;
    height: 22px;
    vertical-align: bottom; }

.ql-editor > * {
  cursor: text; }

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9; }

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: italic;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  max-width: 210px;
  white-space: pre;
  text-overflow: ellipsis; }

.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
  clear: both;
  content: '';
  display: table; }

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 28px; }

.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%; }

.ql-snow.ql-toolbar button:active:hover,
.ql-snow .ql-toolbar button:active:hover {
  outline: none; }

.ql-snow .ql-hidden {
  display: none; }

.ql-snow .ql-out-bottom,
.ql-snow .ql-out-top {
  visibility: hidden; }

.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px); }

.ql-snow .ql-tooltip a {
  cursor: pointer;
  text-decoration: none; }

.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px); }

.ql-snow .ql-formats {
  display: inline-block;
  vertical-align: middle; }

.ql-snow .ql-formats:after {
  clear: both;
  content: '';
  display: table; }

.ql-snow .ql-stroke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2; }

.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: #444;
  stroke-miterlimit: 10;
  stroke-width: 2; }

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #444; }

.ql-snow .ql-empty {
  fill: none; }

.ql-snow .ql-even {
  fill-rule: evenodd; }

.ql-snow .ql-thin,
.ql-snow .ql-stroke.ql-thin {
  stroke-width: 1; }

.ql-snow .ql-transparent {
  opacity: 0.4; }

.ql-snow .ql-direction svg:last-child {
  display: none; }

.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline; }

.ql-snow .ql-direction.ql-active svg:first-child {
  display: none; }

.ql-toolbar.ql-snow {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px; }

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px; }

.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent; }

.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px; }

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #ccc; }

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #ccc; }

.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: #000; }

.ql-container.ql-snow {
  border: 1px solid #ccc; }

.ql-toolbar {
  display: none; }

.quill-mention.Channel {
  background: white !important;
  color: #1264a3 !important;
  padding: 0 2px 1px;
  border-radius: 3px;
  text-decoration: none;
  line-height: 22px;
  position: relative;
  font-size: 15px;
  font-size: 1.5rem;
  cursor: pointer; }
  .quill-mention.Channel:hover {
    text-decoration: underline;
    background: white !important;
    color: #1264a3 !important; }

.quill-mention.User {
  background-color: #e8f5fa;
  color: #1264a3;
  padding: 0 2px 1px;
  border-radius: 3px;
  text-decoration: none;
  line-height: 22px;
  position: relative;
  font-weight: 500;
  font-size: 15px;
  font-size: 1.5rem;
  cursor: pointer; }
  .quill-mention.User:hover {
    background-color: #ddf0f8;
    color: #0b4c8c; }

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: normal;
  font-size: 15px;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  max-width: 210px;
  white-space: pre;
  text-overflow: ellipsis; }

/* Templates */
html {
  font-size: 62.5%;
  height: 100% !important;
  background: #fff;
  overflow-x: hidden;
  -webkit-font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }
  html * {
    font-family: "Karla", sans-serif;
    outline: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  html *::before,
  html *::after {
    box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }

body {
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  position: relative;
  min-height: 100%; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

body.Patterns {
  background-image: url(./../../images/background.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: contain; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

b {
  font-weight: 500; }

.App {
  display: block;
  max-width: 584px;
  margin: 0 auto;
  height: auto;
  min-height: 100vh;
  position: relative; }
  .App .Main {
    display: flex;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .App .Main .Frame {
      width: 100%;
      height: auto;
      background: white;
      min-height: calc(100vh - 40px);
      padding-top: 24px; }
      .App .Main .Frame .Page {
        width: 100%;
        min-height: 300px;
        padding: 0 24px 36px 24px; }
        .App .Main .Frame .Page .Content {
          position: relative; }
          .App .Main .Frame .Page .Content .Block {
            padding: 24px 12px 0 12px; }
    .App .Main .Footer {
      height: 40px;
      width: 100%;
      background: white; }

.Image {
  width: 96px;
  height: 96px;
  border-radius: 100%;
  margin: 60px 0 16px 0;
  -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  -khtml-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  -moz-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  -ms-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  -o-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); }

select {
  background: white;
  border: 1px solid #e4e4e4;
  padding: 8px 12px;
  border-radius: 4px;
  color: #444;
  font-size: 14px;
  font-size: 1.4rem; }

fieldset {
  border: none;
  margin: 0;
  padding: 0; }

.Time_Tip {
  background: #f9f9f9;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 2px 4px;
  position: absolute;
  top: 44px;
  left: 0px;
  width: 210px;
  text-align: center; }
  .Time_Tip p {
    margin: 0;
    font-size: 12px !important; }

.Command {
  font-family: monospace;
  color: #cc0c4c;
  margin-right: 4px; }

.Popover {
  position: absolute;
  will-change: transform;
  width: 138px;
  -webkit-transform: scale(0);
  -khtml-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all .3s cubic-bezier(0.4,0.0,0.2,1);
  -khtml-transition: all .3s cubic-bezier(0.4,0.0,0.2,1);
  -moz-transition: all .3s cubic-bezier(0.4,0.0,0.2,1);
  -ms-transition: all .3s cubic-bezier(0.4,0.0,0.2,1);
  -o-transition: all .3s cubic-bezier(0.4,0.0,0.2,1);
  transition: all .3s cubic-bezier(0.4,0.0,0.2,1);
  opacity: 0;
  transform-origin: top right;
  height: auto;
  background: rgba(18, 18, 18, 0.95);
  padding: 0;
  top: 48px;
  border-radius: 6px;
  display: block;
  border: 1px solid #e4e4e4;
  right: 0;
  -webkit-box-shadow: 0 0.125rem 0.625rem 0 rgba(18,18,18,.15);
  -khtml-box-shadow: 0 0.125rem 0.625rem 0 rgba(18,18,18,.15);
  -moz-box-shadow: 0 0.125rem 0.625rem 0 rgba(18,18,18,.15);
  -ms-box-shadow: 0 0.125rem 0.625rem 0 rgba(18,18,18,.15);
  -o-box-shadow: 0 0.125rem 0.625rem 0 rgba(18,18,18,.15);
  box-shadow: 0 0.125rem 0.625rem 0 rgba(18,18,18,.15);
  z-index: 1;
  padding: 12px 0; }
  .Popover .Popover__Item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
    padding: 2px 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none; }
    .Popover .Popover__Item .Popover__Item__Text {
      font-size: 14px;
      font-size: 1.4rem;
      color: #888;
      line-height: 1.9;
      width: 100%;
      text-decoration: none;
      margin: 0; }
    .Popover .Popover__Item .Nav--Selected {
      font-weight: 500;
      color: white; }
    .Popover .Popover__Item:hover {
      background-color: rgba(255, 255, 255, 0.15); }

.Popover.MessageMenu {
  width: 160px; }

.DateTime__Container {
  position: relative;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: wrap; }
  .DateTime__Container p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-wrap: wrap; }

.InformationNotice {
  padding: 8px 8px 8px 36px;
  font-size: 12px;
  font-size: 1.2rem;
  position: relative;
  line-height: 18px; }
  .InformationNotice svg {
    position: absolute;
    left: 10px;
    top: 8px; }

.Info {
  margin-top: 16px;
  padding: 8px 12px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 6px;
  background-color: #fdfdfd;
  border: 1px solid #e4e4e4;
  border-left: 4px solid #3be2a7 !important;
  align-items: center;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);;
  -khtml-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);;
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);;
  -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);;
  -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);; }
  .Info .ActionLink {
    text-decoration: none;
    cursor: pointer;
    line-height: 28px;
    border-radius: 4px;
    user-select: none;
    font-weight: 500;
    border: 1px solid #3be2a7;
    padding: 4px 8px;
    -webkit-transition: all 0.2s ease-in;
    -khtml-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in; }
    .Info .ActionLink:hover, .Info .ActionLink:focus {
      background: #3be2a7;
      color: #121212;
      border-color: #26ad7d; }
  .Info div {
    width: 100%; }
  .Info .Info__Desc p {
    font-size: 13px;
    font-size: 1.3rem;
    margin: 0; }
    .Info .Info__Desc p:not(.NoTextTruncate) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .Info .Info__Desc .SlackCommand {
    font-size: 13px;
    font-size: 1.3rem; }
  .Info .Info__Desc .TextLoop {
    pointer-events: none;
    user-select: none; }
  .Info .Info__Actions {
    margin-left: auto; }

.Info--Error {
  margin: 0;
  margin-bottom: 16px;
  border-left-color: #ed143d !important; }

q {
  font-size: 22px;
  font-size: 2.2rem;
  padding: 4px;
  font-family: auto; }

body::-webkit-scrollbar {
  width: 8px; }

strong {
  font-weight: 500; }

/* Track */
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px; }

/* Handle */
body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #d4d4d4;
  -webkit-box-shadow: inset 0 0 6px rgba(22, 22, 22, 0.5); }

body::-webkit-scrollbar-thumb:window-inactive {
  background: #dddddd; }

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #5a44ff; }

::selection {
  color: white;
  background: #5a44ff; }

.TabsPanel {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
  justify-content: center; }
  .TabsPanel:before {
    border-top: 1px solid #e4e4e4;
    position: absolute;
    left: 0;
    z-index: 0;
    content: "";
    width: 100%;
    display: block; }
  .TabsPanel .TabsPanel__Inner {
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .TabsPanel .TabsPanel__Inner .Tab {
      display: block;
      background: #f9f9f9;
      color: #586069;
      font-size: 14px;
      font-size: 1.4rem;
      border: 1px solid #e4e4e4;
      border-left: none;
      padding: 6px 14px;
      margin: 0;
      cursor: pointer;
      user-select: none; }
      .TabsPanel .TabsPanel__Inner .Tab:hover, .TabsPanel .TabsPanel__Inner .Tab:focus {
        background: white;
        border-color: #e1e4e8;
        color: #586069; }
    .TabsPanel .TabsPanel__Inner .Tab__First {
      border-left: 1px solid #e4e4e4;
      border-top-left-radius: 160px;
      border-bottom-left-radius: 160px; }
    .TabsPanel .TabsPanel__Inner .Tab__Last {
      border-top-right-radius: 160px;
      border-bottom-right-radius: 160px; }
    .TabsPanel .TabsPanel__Inner .Tab.Selected {
      background: #051d39;
      border-color: #051d39;
      color: white; }
      .TabsPanel .TabsPanel__Inner .Tab.Selected:hover, .TabsPanel .TabsPanel__Inner .Tab.Selected:focus {
        background: #051d39;
        border-color: #051d39;
        color: white; }

ul.Ticks {
  margin: 8px 0;
  padding: 0; }
  ul.Ticks li {
    font-size: 14px;
    font-size: 1.4rem;
    list-style: none;
    color: #444;
    margin: 14px 0;
    position: relative;
    padding-left: 24px; }
    ul.Ticks li:before {
      content: "✔";
      position: absolute;
      color: #5a44ff;
      margin-right: 10px;
      left: 0px;
      top: 0;
      background: rgba(90, 68, 255, 0.07);
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      border-radius: 100%; }

.Form {
  display: block;
  padding: 12px 0;
  width: 100%; }
  .Form .FormGroup {
    display: flex;
    flex-direction: column; }
    .Form .FormGroup .FormGroup__Label {
      width: 100%;
      display: block;
      text-align: left;
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: 500;
      margin: 0;
      margin: 4px 0 8px; }
    .Form .FormGroup .FormGroup__Input {
      width: 100%;
      resize: none;
      min-height: 100px;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      padding: 8px;
      font-size: 14px;
      font-size: 1.4rem; }
  .Form .SettingsSectionBlock {
    margin-bottom: 30px; }
    .Form .SettingsSectionBlock .SettingsSubTitle {
      margin-top: 0;
      font-size: 16px;
      font-size: 1.6rem; }
    .Form .SettingsSectionBlock label {
      font-size: 14px !important; }
  .Form h5 {
    color: #545454;
    font-size: 14px;
    font-size: 1.4rem;
    margin: 12px 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #f5f5f5;
  margin: 16px 0;
  padding: 0; }

.FullScreenProgressBar {
  position: fixed !important;
  top: 0 !important;
  margin: 0;
  z-index: 10; }
  .FullScreenProgressBar .Progress {
    margin: 0; }

@media all and (max-width: 720px) {
  .Frame {
    padding-top: 18px !important; }
  body {
    background-image: none !important;
    background: white; } }

@media all and (max-width: 490px) {
  .Frame {
    padding-top: 0 !important; }
  .Paragraph--Normal, .Popup__Overlay .Popup__Container .ProPricingDetails p, .Subscription__Profile .Subscription__Profile_Details .Subscription__Profile_Details--Name {
    font-size: 13px;
    font-size: 1.3rem; }
  ul.Ticks li {
    font-size: 13px;
    font-size: 1.3rem; } }

@media all and (max-width: 416px) {
  .Page {
    padding: 0 16px 36px 16px !important; } }

@media all and (max-width: 452px) {
  .Time_Tip {
    width: 200px; }
    .Time_Tip p {
      margin: 0;
      font-size: 10px !important; } }

.LoginLogo {
  max-width: 400px;
  width: 100%;
  height: 266px;
  margin-bottom: 12px;
  max-height: 266px;
  background-image: url(./../../images/hero_400.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

.NoMessages {
  display: flex;
  width: 100%;
  padding: 24px;
  border: 1px solid #3be2a7;
  border-radius: 8px;
  background: #eefff9;
  min-height: 200px;
  height: auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0; }
  .NoMessages .SendContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 12px; }
    .NoMessages .SendContainer .SendContainer__Block {
      display: inline-block; }
    .NoMessages .SendContainer .Logo {
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      background-position: center;
      background-size: contain;
      background-image: url(../../images/logo_black_white_80.png); }
    .NoMessages .SendContainer .Avatar {
      width: 64px;
      height: 64px;
      border-radius: 8px;
      margin: 0 4px; }
    .NoMessages .SendContainer .ChannelName {
      font-size: 20px;
      font-size: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px; }
  .NoMessages .NoMessages__Image__Container {
    display: flex;
    flex: 1; }
  .NoMessages .NoMessages__Desc__Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center; }
    .NoMessages .NoMessages__Desc__Container .NoMessages__Logo {
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      background-size: contain;
      background-image: url(../../images/logo_black_white_80.png); }
    .NoMessages .NoMessages__Desc__Container .NoMessages__Text {
      margin-top: 6px;
      text-align: center;
      max-width: 300px; }
    .NoMessages .NoMessages__Desc__Container .NoMessages__Text.MaxWidth--100 {
      max-width: 100%; }
    .NoMessages .NoMessages__Desc__Container .Text {
      text-align: center;
      font-size: 16px;
      font-size: 1.6rem;
      width: 100%; }
      .NoMessages .NoMessages__Desc__Container .Text span {
        font-size: 16px;
        font-size: 1.6rem; }
  .NoMessages .NoMessages__Desc__Container.Left {
    align-items: flex-start; }

.NoMessages.Clear {
  border: none;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: white; }

.NoMessages.Minimal {
  border: 1px solid #e4e4e4;
  background: #fdfdfd; }

.MessageContainer {
  padding: 0 2px;
  width: 100%;
  position: relative; }
  .MessageContainer .Message {
    display: flex;
    position: relative;
    flex-direction: row;
    background-color: #fdfdfd;
    border: 1px solid #e4e4e4;
    border-radius: 6px;
    width: 100%;
    -webkit-transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    padding: 4px;
    height: auto;
    margin-bottom: 10px;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -khtml-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03); }
    .MessageContainer .Message:last-child {
      margin-bottom: 0px; }
    .MessageContainer .Message:hover, .MessageContainer .Message:focus {
      background-color: #f3f3f3; }
      .MessageContainer .Message:hover .DeleteButton, .MessageContainer .Message:focus .DeleteButton {
        display: block; }
    .MessageContainer .Message .MessageProgressBar {
      width: 100%;
      position: absolute;
      top: -5px;
      left: 0; }
    .MessageContainer .Message .Message__Avatar {
      display: flex;
      flex-direction: column;
      padding: 16px 8px; }
      .MessageContainer .Message .Message__Avatar:empty {
        width: 48px;
        margin: 8px;
        height: 48px;
        background-color: #dfdfdf;
        border-radius: 8px;
        -webkit-animation: fade 1000ms infinite;
        -khtml-animation: fade 1000ms infinite;
        -moz-animation: fade 1000ms infinite;
        -ms-animation: fade 1000ms infinite;
        -o-animation: fade 1000ms infinite;
        animation: fade 1000ms infinite; }
      .MessageContainer .Message .Message__Avatar .Message__Avatar--User {
        width: 48px;
        height: 48px;
        border-radius: 8px; }
      .MessageContainer .Message .Message__Avatar .Message__Avatar--Channel {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-size: 32px;
        font-size: 3.2rem;
        color: #fff;
        background: #121212; }
    .MessageContainer .Message .Message__Body {
      flex: 1;
      padding: 16px 8px; }
      .MessageContainer .Message .Message__Body .Message_To {
        color: #444;
        text-align: left;
        font-size: 14px;
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 6px; }
        .MessageContainer .Message .Message__Body .Message_To:empty {
          border-radius: 16px;
          background-color: #dfdfdf;
          height: 12px;
          margin-top: 4px;
          width: 145px;
          -webkit-animation: fade 1000ms infinite;
          -khtml-animation: fade 1000ms infinite;
          -moz-animation: fade 1000ms infinite;
          -ms-animation: fade 1000ms infinite;
          -o-animation: fade 1000ms infinite;
          animation: fade 1000ms infinite;
          -webkit-animation-delay: 200ms;
          -khtml-animation-delay: 200ms;
          -moz-animation-delay: 200ms;
          -ms-animation-delay: 200ms;
          -o-animation-delay: 200ms;
          animation-delay: 200ms; }
      .MessageContainer .Message .Message__Body .Message_Content {
        color: #444;
        text-align: left;
        line-height: 1.5;
        word-break: break-word;
        font-size: 14px;
        font-size: 1.4rem; }
      .MessageContainer .Message .Message__Body .MessageFileBlock .FileBlock {
        width: 270px;
        margin-top: 10px;
        background: rgba(90, 68, 255, 0.06);
        border-color: rgba(189, 189, 189, 0.3); }
      .MessageContainer .Message .Message__Body .Message_Attrs {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        padding-top: 10px; }
        .MessageContainer .Message .Message__Body .Message_Attrs .Message_Attrs--Section {
          max-width: 50%;
          width: 50%; }
          .MessageContainer .Message .Message__Body .Message_Attrs .Message_Attrs--Section:first-child {
            padding: 0 16px 0 0; }
          .MessageContainer .Message .Message__Body .Message_Attrs .Message_Attrs--Section:last-child {
            padding: 0 0; }
          .MessageContainer .Message .Message__Body .Message_Attrs .Message_Attrs--Section h5 {
            font-size: 14px;
            font-size: 1.4rem;
            font-weight: 500;
            margin: 0px 0 6px;
            color: #444; }
          .MessageContainer .Message .Message__Body .Message_Attrs .Message_Attrs--Section p {
            margin: 0 0 4px 0;
            font-size: 14px;
            font-size: 1.4rem; }
      .MessageContainer .Message .Message__Body .GroupIcon {
        display: inline-block;
        width: 30px;
        z-index: 1;
        height: 30px;
        margin-left: -8px;
        position: relative;
        -webkit-transition: transform 0.1s ease-in;
        -khtml-transition: transform 0.1s ease-in;
        -moz-transition: transform 0.1s ease-in;
        -ms-transition: transform 0.1s ease-in;
        -o-transition: transform 0.1s ease-in;
        transition: transform 0.1s ease-in;
        cursor: pointer; }
        .MessageContainer .Message .Message__Body .GroupIcon .GroupIcon__Avatar {
          width: 100%;
          height: 100%;
          border-radius: 8px; }
        .MessageContainer .Message .Message__Body .GroupIcon .GroupIcon__Tooltip {
          display: none;
          position: absolute;
          bottom: -26px;
          left: -34px;
          width: 100px;
          font-weight: normal;
          text-align: center;
          color: #444;
          background-color: white;
          border-radius: 4px;
          font-size: 10px;
          font-size: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
          -khtml-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
          -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
          -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
          -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
          padding: 4px 8px; }
        .MessageContainer .Message .Message__Body .GroupIcon:first-child {
          margin-left: 0; }
        .MessageContainer .Message .Message__Body .GroupIcon:hover {
          -webkit-transform: scale(1.2);
          -khtml-transform: scale(1.2);
          -moz-transform: scale(1.2);
          -ms-transform: scale(1.2);
          -o-transform: scale(1.2);
          transform: scale(1.2); }
          .MessageContainer .Message .Message__Body .GroupIcon:hover .GroupIcon__Tooltip {
            display: block; }
      .MessageContainer .Message .Message__Body .GroupIcon__More {
        padding-left: 8px;
        font-size: 12px;
        font-size: 1.2rem; }
      .MessageContainer .Message .Message__Body .Message__Error__Notification {
        margin: 24px 0 8px 0;
        border-radius: 6px;
        border: 1px solid #e4e4e4;
        border-left: 4px solid #ed143d !important;
        font-size: 10px;
        font-size: 1rem;
        -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
        -khtml-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
        -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
        -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
        -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
        padding: 4px 8px; }
    .MessageContainer .Message .Message__Paused {
      position: absolute;
      right: 40px;
      top: 13px;
      user-select: none;
      border: 1px solid #ea384d;
      color: #ea384d; }
      .MessageContainer .Message .Message__Paused svg {
        stroke: #ea384d; }
    .MessageContainer .Message .Message__Time {
      width: 100%;
      margin-top: 20px;
      font-size: 13px;
      font-size: 1.3rem; }
    .MessageContainer .Message .OptionsButton {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-radius: 100%;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      top: 14px;
      padding: 2px;
      right: 12px;
      align-items: center;
      justify-content: center;
      -webkit-transition: all 0.2s ease-in;
      -khtml-transition: all 0.2s ease-in;
      -moz-transition: all 0.2s ease-in;
      -ms-transition: all 0.2s ease-in;
      -o-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in;
      cursor: pointer; }
      .MessageContainer .Message .OptionsButton svg {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none; }
      .MessageContainer .Message .OptionsButton .Popover {
        top: 24px; }
      .MessageContainer .Message .OptionsButton:hover, .MessageContainer .Message .OptionsButton:focus {
        background-color: #e0e0e0; }
        .MessageContainer .Message .OptionsButton:hover .Popover, .MessageContainer .Message .OptionsButton:focus .Popover {
          opacity: 1;
          -webkit-transform: scale(1);
          -khtml-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          transform: scale(1); }
  .MessageContainer .Message.IsDeleting {
    -webkit-filter: grayscale(1);
    -khtml-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -ms-filter: grayscale(1);
    -o-filter: grayscale(1);
    filter: grayscale(1);
    pointer-events: none !important; }
  .MessageContainer .Message__Discard {
    height: 22px;
    width: 22px;
    position: absolute;
    right: -6px;
    top: -10px;
    min-width: 22px;
    stroke: #ed143d;
    fill: #fff;
    cursor: pointer; }
    .MessageContainer .Message__Discard:hover, .MessageContainer .Message__Discard:focus {
      fill: #ed143d;
      stroke: white; }

.Message_Content p {
  font-size: 15px;
  font-size: 1.5rem; }

.Message_Content code {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 12px;
  white-space: normal;
  color: #e01e5a;
  padding: 2px 3px 1px;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  tab-size: 4;
  -moz-tab-size: 4;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  -o-tab-size: 4;
  -webkit-tab-size: 4;
  border-radius: 3px; }
  .Message_Content code a {
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    font-size: 12px !important;
    line-height: 12px !important;
    white-space: normal;
    text-decoration: none !important;
    color: #e01e5a !important;
    padding: 2px 3px 1px;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    tab-size: 4; }
    .Message_Content code a:hover, .Message_Content code a:visited {
      color: #e01e5a !important; }
    .Message_Content code a:hover {
      text-decoration: underline !important; }

.Message_Content pre {
  border-radius: 4px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 18px;
  white-space: pre-wrap;
  word-break: normal;
  margin: 8px 0 4px 0;
  user-select: text;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: block;
  tab-size: 4;
  background: #f8f8f8;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
  padding: 8px; }
  .Message_Content pre a {
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    font-size: 12px !important;
    line-height: 12px !important;
    white-space: normal;
    text-decoration: none !important;
    color: #333333 !important;
    padding: 2px 3px 1px;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    tab-size: 4; }
    .Message_Content pre a:hover, .Message_Content pre a:visited {
      color: #333333 !important; }
    .Message_Content pre a:hover {
      text-decoration: underline !important; }

.Message_Content blockquote {
  position: relative;
  margin: 0;
  padding: 0 0 0 16px;
  line-height: 22px;
  border: none; }
  .Message_Content blockquote:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 4px;
    background: #e3e4e6;
    content: ""; }

.Message_Content .Link,
.Message_Content .Channel {
  overflow-wrap: break-word;
  color: #08c;
  text-decoration: none;
  user-select: text;
  line-height: 22px;
  font-size: 15px;
  font-size: 1.5rem;
  cursor: pointer; }
  .Message_Content .Link:visited,
  .Message_Content .Channel:visited {
    color: #1264a3; }
  .Message_Content .Link:hover,
  .Message_Content .Channel:hover {
    color: #0b4c8c;
    text-decoration: underline; }

.Message_Content .User {
  background: #e8f5fa;
  color: #1264a3;
  padding: 0 2px 1px;
  border-radius: 3px;
  text-decoration: none;
  line-height: 22px;
  position: relative;
  font-size: 15px;
  font-size: 1.5rem;
  cursor: pointer; }
  .Message_Content .User:hover {
    background: #ddf0f8;
    color: #0b4c8c; }
    .Message_Content .User:hover .UserNameTooltip {
      -webkit-transform: scale(1);
      -khtml-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
      opacity: 1; }
  .Message_Content .User .UserNameTooltip {
    opacity: 0;
    -webkit-transform: scale(0);
    -khtml-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    position: absolute;
    top: -44px;
    right: -50px;
    width: 160px;
    z-index: 2;
    font-weight: 500;
    height: 36px;
    text-align: center;
    color: #444;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -khtml-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -webkit-transition: all 0.1s ease-in;
    -khtml-transition: all 0.1s ease-in;
    -moz-transition: all 0.1s ease-in;
    -ms-transition: all 0.1s ease-in;
    -o-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
    padding: 8px 8px; }
    .Message_Content .User .UserNameTooltip img {
      width: 24px;
      height: 24px;
      min-width: 24px;
      border-radius: 8px;
      margin-right: 4px; }
    .Message_Content .User .UserNameTooltip span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 180px; }

.Message_Content .Markdown--Br {
  display: block;
  height: 2px; }

.Message_Content .Emoji {
  display: inline-block; }

.Message_Content b {
  font-weight: 500; }

.Message_Content .SlackCommand {
  background: #fcf5de;
  font-weight: 500;
  padding: 0 2px 1px;
  line-height: 22px;
  font-size: 15px;
  font-size: 1.5rem;
  color: #1d1c1d;
  border-radius: 3px; }

.IsUsersLoading .User {
  visibility: hidden !important; }

.MessageComposerParent {
  display: block;
  width: 100%; }
  .MessageComposerParent .FileBlock {
    width: 100%;
    min-height: 40px;
    height: auto;
    display: flex;
    border: 1px solid #ccc;
    margin-bottom: 12px;
    border-radius: 4px;
    padding: 8px; }
    .MessageComposerParent .FileBlock .MessageProgressBlock {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center; }
      .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Progress {
        width: 40px;
        height: 40px;
        color: #5a44ff;
        font-size: 16px;
        font-size: 1.6rem;
        align-items: center;
        justify-content: center;
        display: flex;
        min-width: 40px; }
      .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 280px;
        margin-right: 6px; }
        .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Desc .MessageProgressBar {
          border-radius: 60px;
          overflow: hidden;
          margin: 6px 4px 6px 8px; }
          .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Desc .MessageProgressBar span {
            display: block; }
          .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Desc .MessageProgressBar .Infinite {
            animation-name: alternator;
            animation-duration: 750ms;
            animation-direction: alternate;
            animation-iteration-count: infinite; }
          .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Desc .MessageProgressBar .MessageBar {
            background: rgba(0, 0, 0, 0.075); }
            .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Desc .MessageProgressBar .MessageBar .MessageProgress {
              width: 0%;
              -webkit-transition: all 0.5s ease-in-out;
              -khtml-transition: all 0.5s ease-in-out;
              -moz-transition: all 0.5s ease-in-out;
              -ms-transition: all 0.5s ease-in-out;
              -o-transition: all 0.5s ease-in-out;
              transition: all 0.5s ease-in-out;
              background: #75b800;
              color: #fff;
              padding: 4px; }
        .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Desc .MessageProgressBlock__Desc--Title {
          font-size: 14px;
          font-size: 1.4rem;
          color: #444;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0 4px 0px 8px;
          text-align: left; }
        .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Desc .MessageProgressBlock__Desc--Message {
          font-size: 14px;
          font-size: 1.4rem;
          color: #444;
          margin: 4px 4px 4px 8px;
          text-align: left; }
      .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Close {
        height: 22px;
        width: 22px;
        min-width: 22px;
        margin-left: auto;
        stroke: #ed143d;
        fill: #fff;
        cursor: pointer; }
        .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Close:hover, .MessageComposerParent .FileBlock .MessageProgressBlock .MessageProgressBlock__Close:focus {
          fill: #ed143d;
          stroke: white; }
    .MessageComposerParent .FileBlock .MessageProgressBlock.MessageProgressBlock--Error {
      align-items: flex-start; }
      .MessageComposerParent .FileBlock .MessageProgressBlock.MessageProgressBlock--Error .MessageProgressBlock__Desc--Title {
        margin: 8px 4px 0px 8px; }
      .MessageComposerParent .FileBlock .MessageProgressBlock.MessageProgressBlock--Error .Button {
        margin-left: 8px; }
    .MessageComposerParent .FileBlock .MessageCompletedBlock {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center; }
      .MessageComposerParent .FileBlock .MessageCompletedBlock .MessageCompletedBlock__Icon {
        width: 36px;
        height: 36px;
        min-width: 36px; }
      .MessageComposerParent .FileBlock .MessageCompletedBlock .MessageCompletedBlock__Desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 200px;
        width: 100%; }
        .MessageComposerParent .FileBlock .MessageCompletedBlock .MessageCompletedBlock__Desc .MessageCompletedBlock__Desc--Title {
          font-size: 14px;
          font-size: 1.4rem;
          color: #444;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0 0px 0px 8px;
          text-align: left; }
        .MessageComposerParent .FileBlock .MessageCompletedBlock .MessageCompletedBlock__Desc .MessageCompletedBlock__Desc--SubTitle {
          font-size: 12px;
          font-size: 1.2rem;
          color: #444;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          margin: 0 0px 0 8px; }
      .MessageComposerParent .FileBlock .MessageCompletedBlock .MessageCompletedBlock__Close {
        height: 22px;
        width: 22px;
        min-width: 22px;
        margin-left: auto;
        stroke: #ed143d;
        fill: #fff;
        cursor: pointer; }
        .MessageComposerParent .FileBlock .MessageCompletedBlock .MessageCompletedBlock__Close:hover, .MessageComposerParent .FileBlock .MessageCompletedBlock .MessageCompletedBlock__Close:focus {
          fill: #ed143d;
          stroke: white; }
  .MessageComposerParent .FileBlock--Error {
    border-color: #ed143d;
    background-color: #ff81280d !important; }
  .MessageComposerParent .MessageComposer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; }
    .MessageComposerParent .MessageComposer.IsFilesSupported .ql-editor {
      padding-left: 38px; }
    .MessageComposerParent .MessageComposer .UserSelect {
      position: absolute;
      height: auto;
      bottom: 100%;
      border-radius: 4px;
      left: 0;
      width: 100%;
      overflow: hidden;
      -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15);
      -khtml-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15);
      -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15);
      -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15); }
      .MessageComposerParent .MessageComposer .UserSelect .UserListDescription {
        font-size: 12px;
        font-size: 1.2rem;
        color: #444;
        padding: 4px 16px;
        background: #ebebeb;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
      .MessageComposerParent .MessageComposer .UserSelect .UserList {
        list-style: none;
        margin: 0;
        padding: 0%;
        width: 100%;
        max-height: 250px;
        overflow: auto;
        border: none;
        height: auto; }
        .MessageComposerParent .MessageComposer .UserSelect .UserList .UserItem {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 6px 16px;
          background: white;
          cursor: pointer;
          border-bottom: 1px solid #ececec;
          color: #444;
          font-size: 14px;
          font-size: 1.4rem; }
          .MessageComposerParent .MessageComposer .UserSelect .UserList .UserItem .MainItem {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: 0; }
          .MessageComposerParent .MessageComposer .UserSelect .UserList .UserItem:hover {
            background: #ebe9ff; }
          .MessageComposerParent .MessageComposer .UserSelect .UserList .UserItem:last-child {
            border-bottom: none; }
          .MessageComposerParent .MessageComposer .UserSelect .UserList .UserItem img {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            margin-right: 12px; }
          .MessageComposerParent .MessageComposer .UserSelect .UserList .UserItem .SecondaryLabel {
            color: #b6b6b6;
            padding-left: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: 0; }
          .MessageComposerParent .MessageComposer .UserSelect .UserList .UserItem .AppTag {
            background: #f1f1f1;
            color: #908f8f;
            border-radius: 4px;
            font-size: 10px;
            padding: 2px 4px;
            margin-left: 4px;
            font-weight: 500; }
        .MessageComposerParent .MessageComposer .UserSelect .UserList .UserItem.Selected {
          background-color: #5a44ff;
          color: white; }
          .MessageComposerParent .MessageComposer .UserSelect .UserList .UserItem.Selected .SecondaryLabel {
            color: #e6e6e6; }
          .MessageComposerParent .MessageComposer .UserSelect .UserList .UserItem.Selected .AppTag {
            background: #7c6bf7;
            color: #c7c7c7;
            border-radius: 4px;
            font-size: 10px;
            padding: 2px 4px;
            margin-left: 4px;
            font-weight: 500; }
    .MessageComposerParent .MessageComposer .FileSelect {
      position: absolute;
      height: auto;
      bottom: 100%;
      border-radius: 4px;
      left: 0;
      width: 100%;
      overflow: hidden;
      -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15);
      -khtml-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15);
      -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15);
      -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.15); }
      .MessageComposerParent .MessageComposer .FileSelect .FileListDescription {
        font-size: 12px;
        font-size: 1.2rem;
        color: #444;
        padding: 4px 16px;
        background: #ebebeb;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
      .MessageComposerParent .MessageComposer .FileSelect .FileList {
        list-style: none;
        margin: 0;
        padding: 0%;
        width: 100%;
        max-height: 250px;
        overflow: auto;
        border: none;
        height: auto; }
        .MessageComposerParent .MessageComposer .FileSelect .FileList .FileItem {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 6px 8px;
          position: relative;
          user-select: none;
          background: white;
          cursor: pointer;
          border-bottom: 1px solid #ececec;
          color: #444;
          font-size: 14px;
          font-size: 1.4rem;
          overflow: hidden; }
          .MessageComposerParent .MessageComposer .FileSelect .FileList .FileItem svg {
            margin-right: 8px; }
          .MessageComposerParent .MessageComposer .FileSelect .FileList .FileItem:hover {
            background: #ebe9ff; }
          .MessageComposerParent .MessageComposer .FileSelect .FileList .FileItem input[type="file"] {
            left: 0;
            top: 0;
            height: 32px;
            width: 100%;
            cursor: pointer;
            position: absolute;
            opacity: 0;
            user-select: none; }
      .MessageComposerParent .MessageComposer .FileSelect .Notice {
        background: #f9f9f9;
        padding: 8px 8px 8px 36px;
        font-size: 11px;
        font-size: 1.1rem;
        position: relative;
        line-height: 14px; }
        .MessageComposerParent .MessageComposer .FileSelect .Notice svg {
          position: absolute;
          left: 10px;
          top: 5px; }
    .MessageComposerParent .MessageComposer .ql-editor {
      padding-right: 38px; }
    .MessageComposerParent .MessageComposer .quill {
      width: 100%; }
    .MessageComposerParent .MessageComposer .MessageComposer__At {
      height: 100%;
      width: 38px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      right: 0; }
      .MessageComposerParent .MessageComposer .MessageComposer__At button {
        min-height: 40px;
        line-height: 22px;
        cursor: pointer;
        color: #929292;
        -webkit-transition: all 0.1s ease-in;
        -khtml-transition: all 0.1s ease-in;
        -moz-transition: all 0.1s ease-in;
        -ms-transition: all 0.1s ease-in;
        -o-transition: all 0.1s ease-in;
        transition: all 0.1s ease-in;
        -webkit-transform-origin: bottom center;
        -khtml-transform-origin: bottom center;
        -moz-transform-origin: bottom center;
        -ms-transform-origin: bottom center;
        -o-transform-origin: bottom center;
        transform-origin: bottom center;
        user-select: none;
        font-size: 22px;
        font-size: 2.2rem; }
        .MessageComposerParent .MessageComposer .MessageComposer__At button:hover {
          color: #5a44ff;
          -webkit-transform: scale(1.1);
          -khtml-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -o-transform: scale(1.1);
          transform: scale(1.1); }
    .MessageComposerParent .MessageComposer .MessageComposer__Files {
      height: 100%;
      width: 38px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      left: 0;
      z-index: 1; }
      .MessageComposerParent .MessageComposer .MessageComposer__Files button {
        min-height: 36px;
        cursor: pointer;
        color: #929292;
        -webkit-transition: all 0.1s ease-in;
        -khtml-transition: all 0.1s ease-in;
        -moz-transition: all 0.1s ease-in;
        -ms-transition: all 0.1s ease-in;
        -o-transition: all 0.1s ease-in;
        transition: all 0.1s ease-in;
        -webkit-transform-origin: bottom center;
        -khtml-transform-origin: bottom center;
        -moz-transform-origin: bottom center;
        -ms-transform-origin: bottom center;
        -o-transform-origin: bottom center;
        transform-origin: bottom center;
        user-select: none; }
        .MessageComposerParent .MessageComposer .MessageComposer__Files button svg {
          -webkit-transform: rotate(-45deg);
          -khtml-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg); }
        .MessageComposerParent .MessageComposer .MessageComposer__Files button:hover {
          -webkit-transform: scale(1.1);
          -khtml-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -o-transform: scale(1.1);
          transform: scale(1.1); }
          .MessageComposerParent .MessageComposer .MessageComposer__Files button:hover svg {
            stroke: #5a44ff; }
  .MessageComposerParent .MessageComposer__Help {
    display: block;
    pointer-events: none;
    user-select: none;
    font-size: 11px;
    font-size: 1.1rem;
    width: 100%;
    text-align: right;
    color: #7c7c7c;
    line-height: 11px;
    margin: 8px 0; }
    .MessageComposerParent .MessageComposer__Help strong {
      font-weight: 500; }
    .MessageComposerParent .MessageComposer__Help .Block {
      background-color: #f7f7f9;
      border: 1px solid #e1e1e8;
      border-radius: 2px;
      margin-right: 3px; }

.PageProgressBar {
  position: absolute;
  top: -4px;
  width: 100%;
  left: 0; }

@media all and (max-width: 490px) {
  .Message_Attrs {
    flex-direction: column-reverse;
    margin-top: 8px !important; }
    .Message_Attrs .Message_Attrs--Section {
      width: 100% !important;
      max-width: 100% !important; }
      .Message_Attrs .Message_Attrs--Section:first-child h5 {
        margin-top: 12px !important; } }

@media all and (max-width: 440px) {
  .NoMessages__Desc__Container .NoMessages__Logo {
    width: 70px !important;
    height: 70px !important; }
  .NoMessages__Desc__Container .NoMessages__Text {
    font-size: 15px !important; }
  .NoMessages__Desc__Container .Text {
    font-size: 14px !important; }
    .NoMessages__Desc__Container .Text span {
      font-size: 14px !important; }
  .NoMessages__Desc__Container .SendContainer .ChannelName {
    font-size: 16px !important;
    max-width: 120px; }
  .NoMessages__Desc__Container .SendContainer .Avatar {
    width: 48px;
    height: 48px; }
  .NoMessages__Desc__Container .SendContainer .Logo {
    width: 48px;
    height: 48px; }
  .MessageComposer__Help {
    font-size: 10px !important; } }

@media all and (max-width: 416px) {
  .MessageContainer .Message {
    padding-bottom: 10px;
    flex-direction: column; }
    .MessageContainer .Message .Message__Body {
      padding: 6px 12px 12px 12px; }
      .MessageContainer .Message .Message__Body .Message_To {
        text-align: center;
        padding-bottom: 8px;
        border-bottom: 1px solid #e8e7e7;
        margin-bottom: 8px; }
        .MessageContainer .Message .Message__Body .Message_To .Vertical--Center {
          justify-content: center; }
        .MessageContainer .Message .Message__Body .Message_To:empty {
          margin: 4px auto 20px auto; }
    .MessageContainer .Message .Message__Avatar {
      align-items: center;
      padding-bottom: 0; }
      .MessageContainer .Message .Message__Avatar:empty {
        margin: 8px auto; }
    .MessageContainer .Message .MessageFileBlock .FileBlock {
      margin-top: 12px !important;
      width: 100% !important; } }

.Welcome, .NotFound {
  width: 100%;
  height: auto;
  padding-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .Welcome .Hero, .NotFound .Hero {
    max-width: 440px;
    width: 100%;
    height: 246px;
    margin-bottom: 12px;
    max-height: 266px;
    background-image: url(./../../images/welcome.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .Welcome .Heading, .NotFound .Heading {
    text-align: center; }
  .Welcome .Text, .NotFound .Text {
    text-align: justify; }
  .Welcome .Form .Row, .NotFound .Form .Row {
    padding: 8px 0;
    text-align: left; }
  .Welcome .Form .Container, .NotFound .Form .Container {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    margin-bottom: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .Welcome .Form .Container input, .NotFound .Form .Container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0; }
    .Welcome .Form .Container .Checkmark, .NotFound .Form .Container .Checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #f8f8f8;
      border: 1px solid #ebe9e9;
      border-radius: 4px; }
      .Welcome .Form .Container .Checkmark:after, .NotFound .Form .Container .Checkmark:after {
        left: 8px;
        top: 4px;
        width: 5px;
        height: 12px;
        border: solid #ebe9e9;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }
    .Welcome .Form .Container:hover input ~ .Checkmark, .NotFound .Form .Container:hover input ~ .Checkmark {
      background-color: #eefff9;
      border: 1px solid #e4e4e4; }
    .Welcome .Form .Container input:checked ~ .Checkmark, .NotFound .Form .Container input:checked ~ .Checkmark {
      background-color: #eefff9;
      border-color: #3be2a7; }
      .Welcome .Form .Container input:checked ~ .Checkmark:after, .NotFound .Form .Container input:checked ~ .Checkmark:after {
        left: 8px;
        top: 4px;
        width: 5px;
        height: 12px;
        border: solid #3be2a7;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }
    .Welcome .Form .Container input:indeterminate ~ .Checkmark, .NotFound .Form .Container input:indeterminate ~ .Checkmark {
      background-color: #eefff9;
      border-color: #3be2a7; }
      .Welcome .Form .Container input:indeterminate ~ .Checkmark:after, .NotFound .Form .Container input:indeterminate ~ .Checkmark:after {
        content: "—";
        color: #3be2a7;
        font-weight: bold;
        top: -3px;
        left: 3px;
        width: 5px;
        height: 12px;
        border-width: 0;
        border: solid transparent;
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg); }
  .Welcome .Form .Checkmark:after, .NotFound .Form .Checkmark:after {
    content: "";
    position: absolute; }
  .Welcome .Form .Row.Submit, .NotFound .Form .Row.Submit {
    padding: 16px 0; }
  .Welcome .Form label, .NotFound .Form label {
    font-weight: 400;
    line-height: 1.5;
    color: #444;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 0;
    cursor: pointer; }
  .Welcome .Form a, .NotFound .Form a {
    color: #5a44ff;
    text-decoration: none;
    cursor: pointer; }
    .Welcome .Form a:hover, .Welcome .Form a:focus, .NotFound .Form a:hover, .NotFound .Form a:focus {
      text-decoration: underline; }

.Welcome.Disabled {
  -webkit-filter: grayscale(1);
  -khtml-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
  filter: grayscale(1);
  pointer-events: none;
  user-select: none; }

.NotFound {
  padding-top: 0px; }
  .NotFound .Hero {
    max-width: 460px;
    width: 100%;
    height: 300px;
    margin-bottom: 12px;
    max-height: 300px;
    background-image: url(./../../images/404.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  .NotFound .Text {
    text-align: center; }

@media all and (max-width: 460px) {
  .Welcome .Heading {
    font-size: 26px !important; }
  .Welcome .Text {
    font-size: 14px !important; }
  .Welcome .Form .Row {
    padding: 8px 0;
    text-align: left; }
  .Welcome .Form .Row.Submit {
    padding: 16px 0; }
  .Welcome .Form label {
    font-size: 14px !important; }
  .Welcome .Form a {
    color: #5a44ff;
    text-decoration: none;
    cursor: pointer; }
    .Welcome .Form a:hover, .Welcome .Form a:focus {
      text-decoration: underline; } }

.Cheatsheet .Section {
  height: auto;
  width: 100%; }
  .Cheatsheet .Section .SubTitle {
    text-align: left;
    text-align: center;
    width: 100%;
    color: #121212;
    font-size: 16px;
    font-size: 1.6rem; }
  .Cheatsheet .Section .SubTitle span {
    color: #cc0c4c;
    background-color: #f9f9f9;
    border: 1px solid #bdbdbd;
    padding: 4px;
    font-size: 16px;
    font-size: 1.6rem;
    border-radius: 4px; }
  .Cheatsheet .Section .Demo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; }
    .Cheatsheet .Section .Demo .DemoDescription {
      width: 100%;
      align-self: flex-start;
      height: 100%; }
    .Cheatsheet .Section .Demo .CommandDescription {
      color: #444;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.8; }
    .Cheatsheet .Section .Demo .Code {
      font-weight: bold;
      padding: 2px 4px;
      margin: 0 4px;
      background-color: #eefff9;
      border: 1px solid #3be2a7;
      border-radius: 4px; }
    .Cheatsheet .Section .Demo .Seperator {
      display: block;
      width: 100%;
      height: 12px; }
    .Cheatsheet .Section .Demo .Hightlight {
      font-weight: bold;
      padding: 2px 4px;
      margin: 0 4px;
      background-color: #ffffc1;
      border: 1px solid #b8b8b8;
      border-radius: 4px; }
    .Cheatsheet .Section .Demo .DescSubTitle {
      font-size: 14px;
      font-size: 1.4rem;
      color: #696969; }
    .Cheatsheet .Section .Demo .Commands {
      background-color: #fdfdfd;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      padding: 8px;
      color: #444;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 2; }
      .Cheatsheet .Section .Demo .Commands .Command {
        font-family: monospace;
        color: #cc0c4c;
        margin-right: 4px; }

.Cheatsheet .CheatSheet__ButtonPanel {
  width: 100%;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.PricesContainer {
  display: flex;
  align-items: center;
  margin: 16px 0;
  justify-content: center; }
  .PricesContainer .PriceBlock {
    flex: 1;
    margin: 8px;
    height: auto;
    border: 1px solid #e4e4e4;
    align-items: center;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);;
    -khtml-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);;
    -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);;
    -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);;
    -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);;
    padding: 12px 24px;
    background: #f1f0ff;
    position: relative;
    border-radius: 6px; }
    .PricesContainer .PriceBlock .PriceBlock__Title {
      font-size: 16px;
      font-size: 1.6rem;
      margin: 6px 0 0 0;
      text-align: center; }
    .PricesContainer .PriceBlock .PriceBlock__Price {
      font-size: 32px;
      font-size: 3.2rem;
      font-weight: 500;
      margin: 0;
      padding: 12px 0 12px 0;
      text-align: center; }
      .PricesContainer .PriceBlock .PriceBlock__Price .PriceBlock__Price--Label {
        color: #888;
        font-weight: normal;
        font-size: 13px;
        font-size: 1.3rem; }
    .PricesContainer .PriceBlock .PriceBlock__Cancel {
      color: #888;
      text-align: center;
      margin: 6px 0;
      font-weight: normal;
      font-size: 13px;
      font-size: 1.3rem; }
    .PricesContainer .PriceBlock .PriceBlock__Profit {
      position: absolute;
      background: #3be2a7;
      color: white;
      text-align: center;
      font-weight: 500;
      padding: 4px 6px;
      width: 90px;
      border-radius: 6px;
      top: -12px;
      border: 1px solid #1bb725;
      font-size: 12px;
      font-size: 1.2rem;
      left: 50%;
      margin-left: -45px; }

.Subscription {
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .Subscription .Subscription__Columns {
    display: flex;
    width: 100%;
    max-width: 360px;
    margin-bottom: 24px; }
    .Subscription .Subscription__Columns .Subscription__Columns--Block {
      flex: 1; }
      .Subscription .Subscription__Columns .Subscription__Columns--Block h4 {
        font-size: 14px;
        font-size: 1.4rem;
        margin: 0; }
      .Subscription .Subscription__Columns .Subscription__Columns--Block:nth-child(2) {
        text-align: right;
        margin-left: auto; }
        .Subscription .Subscription__Columns .Subscription__Columns--Block:nth-child(2) p {
          text-align: right; }

.Subscription__Profile .Subscription__Profile__Image__Wrapper .Profile__Image {
  width: 48px;
  height: 48px;
  z-index: 2;
  border-radius: 8px; }

.Subscription__Profile .Subscription__Profile_Details .Subscription__Profile_Details--Name {
  font-weight: 500;
  padding-left: 12px; }

.Subscription__Profile .Subscription__Profile_Details .Subscription__Profile_Details--Plan {
  padding: 4px 12px; }

@media all and (max-width: 416px) {
  .PricesContainer .PriceBlock {
    padding: 12px 12px; } }

/* Overrides */
.Toastify .Toastify__toast-container {
  z-index: 9999;
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
  padding: 8px;
  line-height: 1.5; }

.Toastify .Toastify__toast-container--bottom-center {
  bottom: 2em;
  left: 50%;
  margin-left: -160px; }

.Toastify .Toastify__toast {
  border-radius: 8px;
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr; }
  .Toastify .Toastify__toast .Toastify__toast-body {
    margin: auto 0;
    -webkit-flex: 1 1;
    flex: 1 1; }
  .Toastify .Toastify__toast .Toastify__close-button {
    display: none; }
  .Toastify .Toastify__toast .Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    z-index: 9999;
    opacity: 0.7;
    background-color: rgba(255, 255, 255, 0.7);
    -webkit-transform-origin: left;
    transform-origin: left; }

@-webkit-keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0); } }

@keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0); } }
  .Toastify .Toastify__toast .Toastify__progress-bar--animated {
    -webkit-animation: Toastify__trackProgress linear 1 forwards;
    animation: Toastify__trackProgress linear 1 forwards; }

.Toastify .Toastify__toast--error {
  background: #ed143d; }

.Toastify .Toastify__toast--success {
  background: #2eb384; }

.ql-editor p,
.ql-editor div {
  font-size: 15px !important; }

.ql-editor strong {
  font-weight: 500; }

.ql-editor code {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 12px;
  white-space: normal;
  color: #e01e5a;
  padding: 2px 3px 1px;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  tab-size: 4;
  -moz-tab-size: 4;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  -o-tab-size: 4;
  -webkit-tab-size: 4;
  border-radius: 3px; }
  .ql-editor code * {
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important; }
  .ql-editor code strong {
    font-weight: normal !important; }
  .ql-editor code s {
    text-decoration: none !important; }
  .ql-editor code i {
    font-style: normal !important; }
  .ql-editor code a {
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    font-size: 12px !important;
    line-height: 12px !important;
    white-space: normal;
    text-decoration: none !important;
    color: #e01e5a !important;
    padding: 2px 3px 1px;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    tab-size: 4; }
    .ql-editor code a:hover, .ql-editor code a:visited {
      color: #e01e5a !important; }
    .ql-editor code a:hover {
      text-decoration: underline !important; }

.ql-editor pre {
  border-radius: 4px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 18px;
  white-space: pre-wrap;
  word-break: normal;
  margin: 8px 0 4px 0;
  user-select: text;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: block;
  tab-size: 4;
  background: #f8f8f8;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
  padding: 8px; }
  .ql-editor pre * {
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important; }
  .ql-editor pre strong {
    font-weight: normal !important; }
  .ql-editor pre s {
    text-decoration: none !important; }
  .ql-editor pre i {
    font-style: normal !important; }
  .ql-editor pre a {
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    font-size: 12px !important;
    line-height: 12px !important;
    white-space: normal;
    text-decoration: none !important;
    color: #333333 !important;
    padding: 2px 3px 1px;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    tab-size: 4; }
    .ql-editor pre a:hover, .ql-editor pre a:visited {
      color: #333333 !important; }
    .ql-editor pre a:hover {
      text-decoration: underline !important; }

.ql-editor blockquote {
  position: relative;
  margin: 4px 0 0 0;
  padding: 0 0 0 16px;
  line-height: 22px;
  border: none; }
  .ql-editor blockquote:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 4px;
    background: #e3e4e6;
    content: ""; }

.ql-editor a,
.ql-editor .Channel {
  overflow-wrap: break-word;
  color: #08c;
  text-decoration: none;
  user-select: text;
  line-height: 22px;
  font-size: 15px;
  font-size: 1.5rem;
  cursor: pointer; }
  .ql-editor a:visited,
  .ql-editor .Channel:visited {
    color: #1264a3; }
  .ql-editor a:hover,
  .ql-editor .Channel:hover {
    color: #0b4c8c;
    text-decoration: underline; }

.ql-editor .User {
  background: #e8f5fa;
  color: #1264a3;
  padding: 0 2px 1px;
  border-radius: 3px;
  text-decoration: none;
  line-height: 22px;
  position: relative;
  font-size: 15px;
  font-size: 1.5rem;
  cursor: pointer; }
  .ql-editor .User:hover {
    background: #ddf0f8;
    color: #0b4c8c; }
    .ql-editor .User:hover .UserNameTooltip {
      -webkit-transform: scale(1);
      -khtml-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
      opacity: 1; }
  .ql-editor .User .UserNameTooltip {
    opacity: 0;
    -webkit-transform: scale(0);
    -khtml-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    position: absolute;
    top: -44px;
    right: -50px;
    width: 160px;
    z-index: 2;
    font-weight: 500;
    height: 36px;
    text-align: center;
    color: #444;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -khtml-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -o-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);
    -webkit-transition: all 0.1s ease-in;
    -khtml-transition: all 0.1s ease-in;
    -moz-transition: all 0.1s ease-in;
    -ms-transition: all 0.1s ease-in;
    -o-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
    padding: 8px 8px; }
    .ql-editor .User .UserNameTooltip img {
      width: 24px;
      height: 24px;
      min-width: 24px;
      border-radius: 8px;
      margin-right: 4px; }
    .ql-editor .User .UserNameTooltip span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 180px; }

.ql-editor .Markdown--Br {
  display: block;
  height: 2px; }

.ql-editor .Emoji {
  display: inline-block; }

.ql-editor b {
  font-weight: 500; }

.ql-editor .SlackCommand {
  background: #fcf5de;
  font-weight: 500;
  padding: 0 2px 1px;
  line-height: 22px;
  font-size: 15px;
  font-size: 1.5rem;
  color: #1d1c1d;
  border-radius: 3px; }

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #5a44ff !important;
  border: 1px double #5a44ff !important; }

.DayPicker_weekHeader_li {
  user-select: none !important; }

.SingleDatePickerInput {
  border: 0 !important;
  cursor: pointer; }
  .SingleDatePickerInput .DateInput {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f0ff;
    border: 1px solid #5a44ff;
    margin: 0;
    cursor: pointer;
    width: auto;
    min-width: 100px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
    .SingleDatePickerInput .DateInput .DateInput_input {
      padding: 0px 6px;
      height: 40px;
      cursor: pointer;
      font-size: 14px;
      background-color: transparent;
      font-weight: 500;
      border: 1px solid #5a44ff;
      color: #5a44ff;
      text-align: center;
      border: 0; }
  .SingleDatePickerInput .DateInput_fang {
    bottom: 43px !important; }
  .SingleDatePickerInput .SingleDatePicker_picker {
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0, 0, 0, 0);
    -khtml-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0, 0, 0, 0);
    -ms-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0, 0, 0, 0);
    -o-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0, 0, 0, 0);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0, 0, 0, 0);
    bottom: 54px !important; }

.select__control {
  cursor: pointer !important; }

.select__indicator-separator {
  display: none; }

.select__dropdown-indicator {
  padding: 4px 6px 4px 4px !important; }

.select__value-container {
  padding: 2px 4px !important; }

.select__indicator path {
  fill: #5a44ff69 !important; }

.select__multi-value__label {
  color: #333333;
  margin-left: 2px;
  margin-right: 2px; }
  .select__multi-value__label .AppTag {
    background: #f1f1f1;
    color: #908f8f;
    border-radius: 4px;
    font-size: 10px;
    padding: 2px 4px;
    margin-left: 4px;
    font-weight: 500; }
  .select__multi-value__label .CustomSelectValue__Avatar {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 8px;
    margin: 0 6px 0 2px; }
  .select__multi-value__label .Message__Avatar--Channel {
    width: 24px;
    height: 24px;
    display: flex;
    min-width: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 22px;
    font-size: 2.2rem;
    margin: 0 6px 0 2px;
    color: #fff;
    background: #121212; }
  .select__multi-value__label .CustomSelectValue__Text {
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0; }

.CustomSelectValue {
  color: #333333;
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  display: flex;
  align-items: center; }
  .CustomSelectValue .AppTag {
    background: #f1f1f1;
    color: #908f8f;
    border-radius: 4px;
    font-size: 10px;
    padding: 2px 4px;
    margin-left: 4px;
    font-weight: 500; }
  .CustomSelectValue .CustomSelectValue__Avatar {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 8px;
    margin: 0 6px 0 2px; }
  .CustomSelectValue .Message__Avatar--Channel {
    width: 24px;
    height: 24px;
    display: flex;
    min-width: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 22px;
    font-size: 2.2rem;
    margin: 0 6px 0 2px;
    color: #fff;
    background: #121212; }
  .CustomSelectValue .CustomSelectValue__Text {
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0; }

.To_Whom .select__option,
.To_Whom .select__multi-value__label {
  display: flex !important;
  align-items: center !important;
  padding: 4px 6px !important; }
  .To_Whom .select__option .CustomSelectValue__Avatar,
  .To_Whom .select__multi-value__label .CustomSelectValue__Avatar {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 8px;
    margin: 0 6px 0 2px; }
  .To_Whom .select__option .AppTag,
  .To_Whom .select__multi-value__label .AppTag {
    background: #f1f1f1;
    color: #908f8f;
    border-radius: 4px;
    font-size: 10px;
    padding: 2px 4px;
    margin-left: 4px;
    font-weight: 500; }
  .To_Whom .select__option .Message__Avatar--Channel,
  .To_Whom .select__multi-value__label .Message__Avatar--Channel {
    width: 24px;
    height: 24px;
    display: flex;
    min-width: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 22px;
    font-size: 2.2rem;
    margin: 0 6px 0 2px;
    color: #fff;
    background: #121212; }
  .To_Whom .select__option .CustomSelectValue__Text,
  .To_Whom .select__multi-value__label .CustomSelectValue__Text {
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0; }

.To_Whom .select__option--is-selected .CustomSelectValue__Text {
  color: white !important; }

.To_Whom .select__option--is-selected .AppTag {
  background: #d5d2ff !important;
  color: #696969; }

.MultiSelect .select__multi-value {
  background: #f8f8f8;
  border-radius: 6px;
  border: 1px solid #e4e4e4; }
  .MultiSelect .select__multi-value .CustomSelectValue__Text {
    font-size: 14px;
    font-size: 1.4rem; }

.MultiSelect .select__multi-value__remove:hover {
  background-color: transparent !important; }

.SelectLeftBorder {
  border: none; }
  .SelectLeftBorder:hover .select__control {
    border: 1px solid #5a44ff;
    border-radius: 4px; }
  .SelectLeftBorder .select__control {
    border: 1px solid #5a44ff;
    border-radius: 4px; }
