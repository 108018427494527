@media all and (max-width: 460px) {
    .Welcome {

        .Heading {
          font-size: 26px !important;
        }

        .Text {           
            font-size: 14px !important;
        }

        .Form {
            .Row {
                padding: 8px 0;
                text-align: left;
            }

            .Row.Submit {
                padding: 16px 0;
            }

            label {
                font-size: 14px !important;
            }

            a {
                color: $primaryBackgroundColor;
                text-decoration: none;
                cursor: pointer;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }

            }
        }

    }
}
