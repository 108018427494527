@media all and (max-width: 520px) {
    .Footer {
        .Footer__Links {
            a {
                @include font-size(13);
                margin: 0 6px;
            }
        }
    }
}

@media all and (max-width: 430px) {
  .Footer {
      .Footer__Links {
          a {
              @include font-size(12);
              margin: 0 4px;
          }
      }
  }
}
