.Cheatsheet {

    .Section {
        height: auto;
        width: 100%;

        .SubTitle {
            text-align: left;
            text-align: center;
            width: 100%;
            color: $headingColor;
            @include font-size(16);
        }

        .SubTitle span {
            color: #cc0c4c;
            // font-family: monospace;
            background-color: #f9f9f9;
            border: 1px solid #bdbdbd;
            padding: 4px;
            @include font-size(16);
            border-radius: 4px;
        }

        .Demo {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .DemoDescription {
                width: 100%;
                align-self: flex-start;
                height: 100%;
            }

            .CommandDescription {
                color: $paragraphColor;
                @include font-size(14);
                line-height: 1.8;
            }

            .Code {
                // font-family: monospace;
                font-weight: bold;
                padding: 2px 4px;
                margin: 0 4px;
                background-color: #eefff9;
                border: 1px solid #3be2a7;
                border-radius: 4px;
            }

            .Seperator {
                display: block;
                width: 100%;
                height: 12px;
            }

            .Hightlight {
                // font-family: monospace;
                font-weight: bold;
                padding: 2px 4px;
                margin: 0 4px;
                background-color: #ffffc1;
                border: 1px solid rgb(184, 184, 184);
                border-radius: 4px;
            }

            .DescSubTitle {
                @include font-size(14);
                color: #696969;
            }

            .Commands {
                background-color: #fdfdfd;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
                padding: 8px;
                color: $paragraphColor;
                @include font-size(14);
                line-height: 2;

                .Command {
                    font-family: monospace;
                    color: rgb(204, 12, 76);
                    margin-right: 4px;
                }

            }
        }
    }

    .CheatSheet__ButtonPanel {
        width: 100%;
        margin: 12px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
  
}

@import "../_media-queries/_cheatsheet";