 .ql-container {
     box-sizing: border-box;
     font-family: Helvetica, Arial, sans-serif;
     font-size: 13px;
     height: 100%;
     margin: 0px;
     position: relative;
     border-radius: 4px;
 }

 .ql-container.ql-disabled .ql-tooltip {
     visibility: hidden;
 }

 .ql-container.ql-disabled .ql-editor ul[data-checked]>li::before {
     pointer-events: none;
 }

 .ql-clipboard {
     left: -100000px;
     height: 1px;
     overflow-y: hidden;
     position: absolute;
     top: 50%;
 }

 .ql-clipboard p {
     margin: 0;
     padding: 0;
 }

 .ql-editor {
     box-sizing: border-box;
     line-height: 1.42;
     height: 100%;
     outline: none;
     overflow-y: auto;
     padding: 8px 12px;
     tab-size: 4;
     -moz-tab-size: 4;
     text-align: left;
     white-space: pre-wrap;
     word-wrap: break-word;

     .Emoji {
         padding: 10px;
         border: 0;
         border-style: none;
         outline: 0;
         width: 22px;
         height: 22px;
         vertical-align: bottom;
     }
 }

 .ql-editor>* {
     cursor: text;
 }

 .ql-editor p,
 .ql-editor ol,
 .ql-editor ul,
 .ql-editor pre,
 .ql-editor blockquote {
     margin: 0;
     padding: 0;
     counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
 }

 .ql-editor.ql-blank::before {
     color: rgba(0, 0, 0, 0.6);
     content: attr(data-placeholder);
     font-style: italic;
     pointer-events: none;
     position: absolute;
     overflow: hidden;
     max-width: 210px;
     white-space: pre;
     text-overflow: ellipsis;
 }

 .ql-snow.ql-toolbar:after,
 .ql-snow .ql-toolbar:after {
     clear: both;
     content: '';
     display: table;
 }

 .ql-snow.ql-toolbar button,
 .ql-snow .ql-toolbar button {
     background: none;
     border: none;
     cursor: pointer;
     display: inline-block;
     float: left;
     height: 24px;
     padding: 3px 5px;
     width: 28px;
 }

 .ql-snow.ql-toolbar button svg,
 .ql-snow .ql-toolbar button svg {
     float: left;
     height: 100%;
 }

 .ql-snow.ql-toolbar button:active:hover,
 .ql-snow .ql-toolbar button:active:hover {
     outline: none;
 }

 .ql-snow .ql-hidden {
     display: none;
 }

 .ql-snow .ql-out-bottom,
 .ql-snow .ql-out-top {
     visibility: hidden;
 }

 .ql-snow .ql-tooltip {
     position: absolute;
     transform: translateY(10px);
 }

 .ql-snow .ql-tooltip a {
     cursor: pointer;
     text-decoration: none;
 }

 .ql-snow .ql-tooltip.ql-flip {
     transform: translateY(-10px);
 }

 .ql-snow .ql-formats {
     display: inline-block;
     vertical-align: middle;
 }

 .ql-snow .ql-formats:after {
     clear: both;
     content: '';
     display: table;
 }

 .ql-snow .ql-stroke {
     fill: none;
     stroke: #444;
     stroke-linecap: round;
     stroke-linejoin: round;
     stroke-width: 2;
 }

 .ql-snow .ql-stroke-miter {
     fill: none;
     stroke: #444;
     stroke-miterlimit: 10;
     stroke-width: 2;
 }

 .ql-snow .ql-fill,
 .ql-snow .ql-stroke.ql-fill {
     fill: #444;
 }

 .ql-snow .ql-empty {
     fill: none;
 }

 .ql-snow .ql-even {
     fill-rule: evenodd;
 }

 .ql-snow .ql-thin,
 .ql-snow .ql-stroke.ql-thin {
     stroke-width: 1;
 }

 .ql-snow .ql-transparent {
     opacity: 0.4;
 }

 .ql-snow .ql-direction svg:last-child {
     display: none;
 }

 .ql-snow .ql-direction.ql-active svg:last-child {
     display: inline;
 }

 .ql-snow .ql-direction.ql-active svg:first-child {
     display: none;
 }

 .ql-toolbar.ql-snow {
     border: 1px solid rgb(216, 216, 216);
     box-sizing: border-box;
     font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
     padding: 8px;
 }

 .ql-toolbar.ql-snow .ql-formats {
     margin-right: 15px;
 }

 .ql-toolbar.ql-snow .ql-picker-label {
     border: 1px solid transparent;
 }

 .ql-toolbar.ql-snow .ql-picker-options {
     border: 1px solid transparent;
     box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
 }

 .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
     border-color: #ccc;
 }

 .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
     border-color: #ccc;
 }

 .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
 .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
     border-color: #000;
 }

 .ql-container.ql-snow {
     border: 1px solid #ccc;
 }

 .ql-toolbar {
     display: none;
 }

 .quill-mention {}

 .quill-mention.Channel {
     background: white !important;
     color: #1264a3 !important;
     padding: 0 2px 1px;
     border-radius: 3px;
     text-decoration: none;
     line-height: 22px;
     position: relative;
     @include font-size(15);
     cursor: pointer;

     &:hover {
         text-decoration: underline;
         background: white !important;
         color: #1264a3 !important;
     }
 }

 .quill-mention.User {
     background-color: #e8f5fa;
     color: #1264a3;
     padding: 0 2px 1px;
     border-radius: 3px;
     text-decoration: none;
     line-height: 22px;
     position: relative;
     font-weight: 500;
     @include font-size(15);
     cursor: pointer;

     &:hover {
         background-color: #ddf0f8;
         color: #0b4c8c;
     }

 }

 .ql-editor.ql-blank::before {
     color: rgba(0, 0, 0, 0.6);
     content: attr(data-placeholder);
     font-style: normal;
     font-size: 15px;
     pointer-events: none;
     position: absolute;
     overflow: hidden;
     max-width: 210px;
     white-space: pre;
     text-overflow: ellipsis;
 }