.Welcome, .NotFound {
    width: 100%;
    height: auto;
    padding-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .Hero {
        max-width: 440px;
        width: 100%;
        height: 246px;
        margin-bottom: 12px;
        max-height: 266px;
        background-image: url(./../../images/welcome.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .Heading {
        text-align: center;
    }

    .Text {
        text-align: justify;
    }

    .Form {
        .Row {
            padding: 8px 0;
            text-align: left;
        }

        .Container {
            display: block;
            position: relative;
            cursor: pointer;
            padding-left: 35px;
            margin-bottom: 12px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .Checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #f8f8f8;
                border: 1px solid #ebe9e9;
                border-radius: 4px;

                &:after {
                    left: 8px;
                    top: 4px;
                    width: 5px;
                    height: 12px;
                    border: solid #ebe9e9;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }

            &:hover {
                input~.Checkmark {
                    background-color: $lighter-green;
                    border: 1px solid #e4e4e4;                   
                }
            }

            input:checked~.Checkmark {
                background-color: $lighter-green;
                border-color: $dark-green;

                &:after {
                    left: 8px;
                    top: 4px;
                    width: 5px;
                    height: 12px;
                    border: solid $dark-green;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }


            input:indeterminate~.Checkmark {
                background-color: $lighter-green;
                border-color: $dark-green;

                &:after {
                    content: "—";
                    color: $dark-green;
                    font-weight: bold;
                    top: -3px;
                    left: 3px;
                    width: 5px;
                    height: 12px;
                    border-width: 0;
                    border: solid transparent;
                    -webkit-transform: rotate(0deg);
                    -ms-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
            }

        }

        .Checkmark {
            &:after {
                content: "";
                position: absolute;
            }
        }

        .Row.Submit {
            padding: 16px 0;
        }

        label {
            font-weight: 400;
            line-height: 1.5;
            color: $paragraphColor;
            @include font-size(16);
            padding: 0;
            cursor: pointer;
        }

        a {
            color: $primaryBackgroundColor;
            text-decoration: none;
            cursor: pointer;

            &:hover,
            &:focus {
                text-decoration: underline;
            }

        }
    }

}

.Welcome.Disabled{
  @include css3-prefix('filter', grayscale(1));
  pointer-events: none;
  user-select: none;
}

.NotFound{
  padding-top: 0px;
  .Hero{
    max-width: 460px;
    width: 100%;
    height: 300px; 
    margin-bottom: 12px;
    max-height : 300px;
    background-image: url(./../../images/404.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .Text{
    text-align: center;
  }

}


@import "../_media-queries/_welcome";