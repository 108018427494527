@media all and (max-width: 452px) {

    .Popup__Overlay {
        padding: 0;

        .Popup__Container {
            margin: 12px;

            .Popup__Head {

                h3 {
                    margin: 0;
                    font-size: 18px !important;
                }

            }

            .Popup__Body {

                padding: 0px !important;
                min-height: 200px !important;

                .Popup__Action__Item {

                    .MessageArea {
                        min-width: 314px !important;
                    }

                    .Button {
                        font-size: 13px !important;
                        padding: 12px !important;
                        min-width: 100px;
                    }

                    .Popup__Action__Item__Title {
                        font-size: 14px !important;
                    }

                }

                .Margin-Right--12 {
                    margin-right: 6px !important;
                }

                .SingleDatePickerInput {
                    .DateInput {
                        .DateInput_input {
                            font-size: 13px !important;
                        }
                    }
                }

            }

            .Popup__Footer {
                .Button {
                    font-size: 14px !important;
                    padding: 8px !important;
                }
            }
        }
    }

}

@media all and (max-width: 570px) {

    .Popup__Overlay {
        padding: 0;

        .Popup__Container {
            margin: 12px;

            .Popup__Head {

                h3 {
                    margin: 0;
                    font-size: 18px !important;
                }

            }

            .Popup__Body {

                padding-left: 12px;

                .Popup__Action__Item {

                    .MessageArea {
                        min-width: 347px;
                    }

                    .Popup__Action__Item__Title {
                        font-size: 15px !important;
                    }
                }

            }
        }
    }


}