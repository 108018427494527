.FullScreenLoader {
  width: 100%;
  height: 100vh;
  background: $page-background;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  padding-top: $page-top-padding;

  .FullScreenLoader__Container {
    max-width: $page-width;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    width: 100%;
  }
}

.LoadingSpinner {
  @include css3-prefix("animation-duration", 1.2s);
  @include css3-prefix("animation-iteration-count", infinite);
  @include css3-prefix("animation-name", rotate-forever);
  @include css3-prefix("animation-timing-function", linear);
  height: 34px;
  width: 34px;
  border: 8px solid $spinner;
  border-right-color: transparent;
  border-radius: 100%;
}

.FullScreenErrorPopup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  background: white;
  display: flex;
  justify-content: center;

  .FullScreenErrorPopup__Container {
    max-width: $page-width;
    padding: $page-top-padding 24px 0 24px;
  }
}

.Popup__Overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  visibility: hidden;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  @include css3-prefix("transition", all 0.3s cubic-bezier(0.12, 0.64, 0.58, 1));
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 5;
  overflow-y: auto;

  .Popup__Container {
    max-width: 544px;
    margin: 30px 0;
    @include css3-prefix("box-shadow", "0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149)");
    width: 100%;
    @include css3-prefix("transform", scale(0));
    @include css3-prefix("transition", transform 0.3s cubic-bezier(0.12, 0.64, 0.58, 1));
    height: auto;
    background: white;
    border-radius: 8px;
    padding: 16px;

    .Popup__Head {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 16px 0;

      .New__Message {
        background-image: url(./../../images/plus_icon.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 32px;
        height: 22px;
        min-width: 22px;
        margin-right: 10px;
      }

      h3 {
        margin: 0;
        @include font-size(20);
      }
    }

    .Popup__Body {
      min-height: 300px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 48px;
      max-width: 402px;
      width: 100%;

      .To_Whom,
      .When {
        width: 100%;
      }

      .Item {
        margin-top: 16px;
        width: 100%;
      }

      .Popup__Action__Item {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        position: relative;

        .Button__Panel {
          padding-left: 16px;
          width: 100%;
          @include css3-prefix("transition", all 0.3s ease-out);

          .ScheduleButton__ProBadge {
            position: absolute;
            left: 2px;
            z-index: 2;
            top: -6px;
          }
        }

        .Button__Panel {
          // z-index: 1;
        }

        .Button__Panel.Hide {
          opacity: 0;
          @include css3-prefix("transform", scale(0));
        }

        .Button__Panel.Show {
          opacity: 1;
          @include css3-prefix("transform", scale(1));
        }

        .Popup__Action__Item__Title {
          @include css3-prefix("transition", color 0.3s ease-out);
          @include font-size(16);
          font-weight: 500;
          color: $paragraphColor;
          user-select: none;
          padding: 6px 0;
        }

        .Popup__Action__Item__Content {
          padding: 4px 0;
          @include css3-prefix("transition", all 0.3s ease-out);
          position: relative;
          width: 100%;
        }

        &:first-child {
          margin-top: 0;
        }

        .MessageArea {
          border: 1px solid $light-gray;
          border-radius: 4px;
          resize: none;
          width: 100%;
          min-width: 100%;
          width: 100%;
          font-family: $font-OS;
          font-weight: 400;
          line-height: 1.5;
          padding: 10px;
          height: 70px;
          color: $paragraphColor;
          @include font-size(14);
        }
      }
    }

    .Popup__Body.Alert {
      min-height: auto !important;
      max-width: 100% !important;
    }

    .Popup__Body.Modal {
      margin: 0;
      padding: 16px 24px;
      width: 100%;
      max-width: 100%;
    }

    .ProPricingDetails {
      margin: 12px 24px;
      padding-left: 12px;
      border-left: 4px solid $dark-green;
      p {
        @extend .Paragraph--Normal;
        margin: 2px 0;
      }
    }

    .Popup__Body.Hide {
      display: none !important;
    }

    .Popup__Body.Summary {
      max-width: 100% !important;
      min-height: 220px !important;
      padding: 0 24px;
    }

    .Popup__Footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 30px;
      width: 100%;
    }
  }

  .SubscriptionCancelPopup {
    max-width: 450px;
    .Popup__Body {
      min-height: 150px;
      .SubscriptionCancelPopup__SubTitle {
        width: 100%;
        text-align: left;
      }
    }
  }
}

.Input__Group {
  display: flex;
  justify-content: center;
  align-content: flex-start;

  .Left__Radius {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .Clear__Icon {
    height: 39px;
    width: 40px;
    margin-left: 6px;
    stroke: $spinner;
    cursor: pointer;

    &:hover,
    &:focus {
      fill: $spinner;
      stroke: white;
    }
  }
}

.Time__Search,
.TimeZone,
.Middle__Select,
.LeftEdge__Select,
.RightEdge__Select,
.Repeat__Select,
.User__Search {
  border: 1px solid $primaryBackgroundColor;
  padding: 4px 8px;
  color: $paragraphColor;
  @include font-size(14);
  border-top-right-radius: 4px;
  border-left: none;
  border-bottom-right-radius: 4px;
  min-height: 40px;
  height: 40px;
  width: 100%;

  .select__control {
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:hover,
    &:focus {
      border: none;
      border-radius: 0;
      border-color: $primaryBackgroundColor;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .select__menu {
    width: 100%;
    right: 0px;

    .select__option {
      @include text-truncate;
      @include font-size(14);
      width: 100%;
    }

    .select__option--is-focused {
      background-color: $darklight-blue;
    }

    .select__option--is-selected {
      background-color: $primaryBackgroundColor;
      color: white;
    }
  }
}

.User__Filter{
  min-height: 40px;
  height: auto;
}

.User__Search,
.Middle__Select,
.LeftEdge__Select,
.RightEdge__Select,
.Repeat__Select {
  padding: 0;
  width: 100%;
}

.LeftEdge__Select {
  border-top-left-radius: 4px;
  border-left: 1px solid $primaryBackgroundColor;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  .select__control {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:hover,
    &:focus {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.TimeZone {
  border-top-left-radius: 4px;
  border-left: 1px solid $primaryBackgroundColor;
  border-bottom-left-radius: 4px;
  padding: 0;

  .select__control {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &:hover,
    &:focus {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

.Middle__Select {
  border-top-right-radius: 0px;
  border-left: none;
  border-bottom-right-radius: 0px;
  padding: 0;

  .select__control {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    &:hover,
    &:focus {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.Popup__Overlay.Open {
  opacity: 1;
  visibility: visible;

  .Popup__Container {
    @include css3-prefix("transform", scale(1));
  }
}

.Model--Open {
  overflow-y: hidden;
}

@import "../_media-queries/_modals";
