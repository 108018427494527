.LoginLogo{
  max-width: 400px;  
  width: 100%;
  height: 266px;
  margin-bottom: 12px;
  max-height: 266px;
  background-image: url(./../../images/hero_400.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@import "../_media-queries/_auth";