.PricesContainer {
  display: flex;
  align-items: center;
  margin: 16px 0;
  justify-content: center;
  .PriceBlock {
    flex: 1;
    margin: 8px;
    height: auto;
    border: 1px solid $light-gray;
    align-items: center;
    @include css3-prefix("box-shadow", "0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03);");
    padding: 12px 24px;
    background: $light-blue;
    position: relative;
    border-radius: 6px;
    .PriceBlock__Title {
      @include font-size(16);
      margin: 6px 0 0 0;
      text-align: center;
    }
    .PriceBlock__Price {
      @include font-size(32);
      font-weight: 500;
      margin: 0;
      padding: 12px 0 12px 0;
      text-align: center;
      .PriceBlock__Price--Label {
        color: $popover-gray;
        font-weight: normal;
        @include font-size(13);
      }
    }

    .PriceBlock__Cancel {
      color: $popover-gray;
      text-align: center;
      margin: 6px 0;
      font-weight: normal;
      @include font-size(13);
    }
    .PriceBlock__Profit {
      position: absolute;
      background: $dark-green;
      color: white;
      text-align: center;
      font-weight: 500;
      padding: 4px 6px;
      width: 90px;
      border-radius: 6px;
      top: -12px;
      border: 1px solid #1bb725;
      @include font-size(12);
      left: 50%;
      margin-left: -45px;
    }
  }
}

.Subscription {
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .Subscription__Columns {
    display: flex;
    width: 100%;
    max-width: 360px;
    margin-bottom: 24px;
    .Subscription__Columns--Block {
      flex: 1;
      h4 {
        @include font-size(14);
        margin: 0;
      }

      &:nth-child(2) {
        text-align: right;
        margin-left: auto;
        p {
          text-align: right;
        }
      }
    }
  }
}

.Subscription__Profile {
  .Subscription__Profile__Image__Wrapper {
    .Profile__Image {
      width: 48px;
      height: 48px;
      z-index: 2;
      border-radius: 8px;
    }
  }
  .Subscription__Profile_Details {
    .Subscription__Profile_Details--Name {
        @extend .Paragraph--Normal;
        font-weight: 500;
        padding-left: 12px;
    }
    .Subscription__Profile_Details--Plan{
      padding: 4px 12px;
    }
  }
}

@import "../_media-queries/_billing";
