@-webkit-keyframes rotate-forever {
    0% {
        @include css3-prefix('transform', rotate(0deg));
    }

    100% {
        @include css3-prefix('transform', rotate(360deg));
    }
}

@-moz-keyframes rotate-forever {
    0% {
        @include css3-prefix('transform', rotate(0deg));
    }

    100% {
        @include css3-prefix('transform', rotate(360deg));
    }
}

@keyframes rotate-forever {
    0% {
        @include css3-prefix('transform', rotate(0deg));
    }

    100% {
        @include css3-prefix('transform', rotate(360deg));
    }
}


.SlideIn-appear {
    opacity: 0;
    @include css3-prefix('transform', translateX(-10px));
}

.SlideIn-appear.SlideIn-appear-active {
    opacity: 1;
    @include css3-prefix('transform', translateX(0px));
    @include css3-prefix('transition', 'opacity .4s cubic-bezier(0.12, 0.64, 0.58, 1), transform .4s cubic-bezier(0.12, 0.64, 0.58, 1)');
}

.SlideIn-enter {
  opacity: 0;
  @include css3-prefix('transform', translateX(-10px));
}

.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  @include css3-prefix('transform', translateX(0px));
  @include css3-prefix('transition', 'opacity .9s cubic-bezier(0.12, 0.64, 0.58, 1), transform .9s cubic-bezier(0.12, 0.64, 0.58, 1)');
}

.SlideIn-leave {
    opacity: 1;
    @include css3-prefix('transform', translateX(0px));
}

.SlideIn-leave.SlideIn-leave-active {
    opacity: 0;
    @include css3-prefix('transform', translateX(-10px));
    @include css3-prefix('transition', 'transform .9s cubic-bezier(0.12, 0.64, 0.58, 1)');  
}


.FadeIn-appear {
  opacity: 0;
}

.FadeIn-appear.FadeIn-appear-active {
  opacity: 1;
  @include css3-prefix('transition', opacity .3s ease-in);  
}

.FadeIn-leave {
  opacity: 1;
}

.FadeIn-leave.FadeIn-leave-active {
  opacity: 0;
  @include css3-prefix('transition', opacity .3s ease-out);  
}


/* This approximates the ease-in-out-bounce animation from easings.net, which would require a plug-in to use*/
@keyframes bounce {
    0% {
        transform: translateX(0px);
        timing-function: ease-in;
    }

    37% {
        transform: translateX(5px);
        timing-function: ease-out;
    }

    55% {
        transform: translateX(-5px);
        timing-function: ease-in;
    }

    73% {
        transform: translateX(4px);
        timing-function: ease-out;
    }

    82% {
        transform: translateX(-4px);
        timing-function: ease-in;
    }

    91% {
        transform: translateX(2px);
        timing-function: ease-out;
    }

    96% {
        transform: translateX(-2px);
        timing-function: ease-in;
    }

    100% {
        transform: translateX(0px);
        timing-function: ease-in;
    }
}

@-webkit-keyframes bounce {
    0% {
        transform: translateX(0px);
        timing-function: ease-in;
    }

    37% {
        transform: translateX(5px);
        timing-function: ease-out;
    }

    55% {
        transform: translateX(-5px);
        timing-function: ease-in;
    }

    73% {
        transform: translateX(4px);
        timing-function: ease-out;
    }

    82% {
        transform: translateX(-4px);
        timing-function: ease-in;
    }

    91% {
        transform: translateX(2px);
        timing-function: ease-out;
    }

    96% {
        transform: translateX(-2px);
        timing-function: ease-in;
    }

    100% {
        transform: translateX(0px);
        timing-function: ease-in;
    }
}

@-moz-keyframes bounce {
    0% {
        transform: translateX(0px);
        timing-function: ease-in;
    }

    37% {
        transform: translateX(5px);
        timing-function: ease-out;
    }

    55% {
        transform: translateX(-5px);
        timing-function: ease-in;
    }

    73% {
        transform: translateX(4px);
        timing-function: ease-out;
    }

    82% {
        transform: translateX(-4px);
        timing-function: ease-in;
    }

    91% {
        transform: translateX(2px);
        timing-function: ease-out;
    }

    96% {
        transform: translateX(-2px);
        timing-function: ease-in;
    }

    100% {
        transform: translateX(0px);
        timing-function: ease-in;
    }
}

@-webkit-keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.4;
    }

    to {
        opacity: 1.0;
    }
}

@-moz-keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.4;
    }

    to {
        opacity: 1.0;
    }
}

@keyframes fade {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.4;
    }

    to {
        opacity: 1.0;
    }
}



.Progress {
    position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color: $darklight-blue;
    border-radius: 2px;
    background-clip: padding-box;
    margin: 0.5rem 0 1rem 0;
    overflow: hidden;
}

.Progress .Indeterminate {
    background-color: $spinner;
}

.Progress .Indeterminate:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.Progress .Indeterminate:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }

    60% {
        left: 100%;
        right: -90%;
    }

    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }

    60% {
        left: 100%;
        right: -90%;
    }

    100% {
        left: 100%;
        right: -90%;
    }
}

@-webkit-keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }

    60% {
        left: 107%;
        right: -8%;
    }

    100% {
        left: 107%;
        right: -8%;
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }

    60% {
        left: 107%;
        right: -8%;
    }

    100% {
        left: 107%;
        right: -8%;
    }
}

@keyframes alternator {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
    margin-left: 100%;
  }
}