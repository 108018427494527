@media all and (max-width: 490px) {
  .Message_Attrs {
    flex-direction: column-reverse;
    margin-top: 8px !important;
    .Message_Attrs--Section {
      width: 100% !important;
      max-width: 100% !important;
      &:first-child {
        h5 {
          margin-top: 12px !important;
        }
      }
    }
  }
}

@media all and (max-width: 440px) {
  .NoMessages__Desc__Container {
    .NoMessages__Logo {
      width: 70px !important;
      height: 70px !important;
    }

    .NoMessages__Text {
      font-size: 15px !important;
    }

    .Text {
      font-size: 14px !important;

      span {
        font-size: 14px !important;
      }
    }

    .SendContainer {
      .ChannelName {
        font-size: 16px !important;
        max-width: 120px;
      }

      .Avatar {
        width: 48px;
        height: 48px;
      }

      .Logo {
        width: 48px;
        height: 48px;
      }
    }
  }

  .MessageComposer__Help {
    font-size: 10px !important;
  }
}

@media all and (max-width: 416px) {
  .MessageContainer .Message {
    padding-bottom: 10px;
    flex-direction: column;
    .Message__Body{
        padding: 6px 12px 12px 12px;
        .Message_To{
            text-align: center;
            padding-bottom: 8px;
            border-bottom: 1px solid #e8e7e7;
            margin-bottom: 8px;
            .Vertical--Center{
                justify-content: center;
            }
            &:empty{
                margin: 4px auto 20px auto;
            }
        }
    }
    .Message__Avatar{
        align-items: center;
        padding-bottom:  0;
        &:empty{
            margin: 8px auto;
        }
    }
    .MessageFileBlock {
      .FileBlock {
        margin-top: 12px !important;
        width: 100% !important;
      }
    }
  }
}
