@media all and (max-width: 720px) {
    .Frame {
        padding-top: 18px !important;
    }
    body{
      background-image: none !important;
      background: white;
    }
}

@media all and (max-width: 490px) {
  .Frame {
      padding-top: 0 !important;
  }
  .Paragraph--Normal {
    @include font-size(13);   
  }

  ul.Ticks{
    li{
      @include font-size(13);   
    }
  }
  
}

@media all and (max-width: 416px) {
  .Page {
    padding: 0 16px 36px 16px !important;
  }
}

@media all and (max-width: 452px) {
    .Time_Tip {
        width: 200px;

        p {
            margin: 0;
            font-size: 10px !important;
        }
    }

}