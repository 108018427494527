.Header {
  width: 100%;
  height: auto;
  position: sticky;
  top: 0px;
  z-index: 2;
  padding-top: 12px;
  background-color: rgba(255, 255, 255, 0.96);

  .Header__Controls {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .NewMessage {
      @include css3-prefix("box-shadow", "0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149)");
      -webkit-font-smoothing: antialiased;
      @include font-size(16);
      align-items: center;
      background-color: #fff;
      background-image: none;
      user-select: none;
      @include css3-prefix("transition", "box-shadow .08s linear, min-width .15s cubic-bezier(0.4,0.0,0.2,1)");
      border-radius: 24px;
      color: #3c4043;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      font-weight: 500;
      height: 48px;
      min-width: 48px;
      padding: 0 24px 0 0;
      cursor: pointer;
      text-transform: none;

      &:before {
        background-image: url(./../../images/plus_icon.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 32px;
        content: "";
        display: block;
        height: 48px;
        min-width: 48px;
      }

      &:hover,
      &:focus {
        @include css3-prefix("box-shadow", "0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149)");
        background-color: #fafafb;
      }
    }

    .Profile {
      height: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      position: relative;
      margin-left: auto;
      padding: 0;

      .Profile__Image__Wrapper {
        position: relative;
        width: 48px;
        height: 48px;
        @include css3-prefix("transition", opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1));

        .Profile__Image {
          width: 48px;
          height: 48px;
          z-index: 2;
          border-radius: 8px;
        }

        .PlanTag {
          padding: 2px 4px;
          border-radius: 8px;
          font-weight: 500;
          letter-spacing: 2px;
          position: absolute;
          width: 100%;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          text-align: center;
          bottom: 0;
          @include font-size(10);
        }

        .PlanTag.Free {
          background: rgba(59, 226, 167, 0.9);
          color: $paragraphColor;
        }

        .PlanTag.Pro {
            background: $proColor;
            color: $paragraphColor;
        }
      }

      .Down__Arrow {
        z-index: 2;
        @include css3-prefix("transition", transform 0.3s cubic-bezier(0.4, 0, 0.2, 1));
      }

      .Profile_Details {
        display: flex;
        z-index: 2;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 0 12px;

        .Profile_Details--Name {
          @include font-size(16);
          line-height: 1.5;
          color: $headingColor;
          @include text-truncate;
          max-width: 140px;
          font-weight: 500;
          user-select: none;
          @include css3-prefix("transition", color 0.3s linear);
        }

        .Profile_Details--Plan {
          @include font-size(14);
          line-height: 1.5;
          max-width: 140px;
          @include text-truncate;
          user-select: none;
          color: $paragraphColor;
          text-transform: capitalize;
          @include css3-prefix("transition", color 0.3s linear);
        }
      }

      &:hover {
        .Profile__Image__Wrapper {
          opacity: 0.6;
        }

        .Profile_Details--Name {
          color: $hover-gray;
        }
        .Profile_Details--Plan {
          color: $hover-gray;
        }
        .Down__Arrow {
          @include css3-prefix("transform", "rotate(-180deg)");
        }
      }

      &:hover {
        .Popover {
          opacity: 1;
          @include css3-prefix("transform", scale(1));
        }
      }
    }
  }

  .Navigation {
    display: flex;
    position: relative;
    padding: 30px 0 18px 0;

    a {
      @include font-size(16);
      color: $hover-gray;
      text-transform: capitalize;
      text-decoration: none;
      user-select: none;
      cursor: pointer;
      margin: 0 12px;
      display: flex;
      align-items: center;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        text-decoration: underline;
      }

      svg {
        stroke: $hover-gray;
        margin-right: 4px;
      }
    }

    a.Nav--Selected {
      color: $headingColor;
      font-weight: 500;
      svg {
        stroke: $headingColor;
        stroke-width: 2px;
      }
    }

    .SettingsLink {
      margin-left: auto;
      margin-right: 0;
    }
  }
}

@import "../_media-queries/_header";
