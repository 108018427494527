.Toastify {
  .Toastify__toast-container {
    z-index: 9999;
    position: fixed;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    color: #fff;
    @include font-size(14);
    text-align: center;
    padding: 8px;
    line-height: 1.5;
  }

  .Toastify__toast-container--bottom-center {
    bottom: 2em;
    left: 50%;
    margin-left: -160px;
  }

  .Toastify__toast {
    border-radius: 8px;
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;

    .Toastify__toast-body {
      margin: auto 0;
      -webkit-flex: 1 1;
      flex: 1 1;
    }

    .Toastify__close-button {
      // color: #fff;
      // font-weight: bold;
      // font-size: 14px;
      // background: transparent;
      // outline: none;
      // border: none;
      // padding: 0;
      // cursor: pointer;
      // opacity: 0.7;
      // margin-top: -6px;
      // transition: 0.3s ease;
      // -webkit-align-self: flex-start;
      // align-self: flex-start;
      display: none;
    }

    .Toastify__progress-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      z-index: 9999;
      opacity: 0.7;
      background-color: rgba(255, 255, 255, 0.7);
      -webkit-transform-origin: left;
      transform-origin: left;
    }

    @-webkit-keyframes Toastify__trackProgress {
      0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }

      100% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }
    }

    @keyframes Toastify__trackProgress {
      0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }

      100% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }
    }

    .Toastify__progress-bar--animated {
      -webkit-animation: Toastify__trackProgress linear 1 forwards;
      animation: Toastify__trackProgress linear 1 forwards;
    }
  }

  .Toastify__toast--error {
    background: $errorColor;
  }

  .Toastify__toast--success {
    background: #2eb384;
  }
}

.ql-editor {
  p,
  div {
    font-size: 15px !important;
  }

  strong {
    font-weight: 500;
  }

  code {
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    @include font-size(12);
    line-height: 12px;
    white-space: normal;
    color: #e01e5a;
    padding: 2px 3px 1px;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    tab-size: 4;
    -moz-tab-size: 4;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
    -o-tab-size: 4;
    -webkit-tab-size: 4;
    border-radius: 3px;

    * {
      font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    }

    strong {
      font-weight: normal !important;
    }

    s {
      text-decoration: none !important;
    }

    i {
      font-style: normal !important;
    }

    a {
      font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
      font-size: 12px !important;
      line-height: 12px !important;
      white-space: normal;
      text-decoration: none !important;
      color: #e01e5a !important;
      padding: 2px 3px 1px;
      -webkit-font-variant-ligatures: none;
      font-variant-ligatures: none;
      tab-size: 4;

      &:hover,
      &:visited {
        color: #e01e5a !important;
      }

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  pre {
    border-radius: 4px;
    @include font-size(12);
    line-height: 18px;
    white-space: pre-wrap;
    word-break: normal;
    margin: 8px 0 4px 0;
    user-select: text;
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: block;
    tab-size: 4;
    background: #f8f8f8;
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    padding: 8px;

    * {
      font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
    }

    strong {
      font-weight: normal !important;
    }

    s {
      text-decoration: none !important;
    }

    i {
      font-style: normal !important;
    }

    a {
      font-family: Monaco, Menlo, Consolas, "Courier New", monospace !important;
      font-size: 12px !important;
      line-height: 12px !important;
      white-space: normal;
      text-decoration: none !important;
      color: rgb(51, 51, 51) !important;
      padding: 2px 3px 1px;
      -webkit-font-variant-ligatures: none;
      font-variant-ligatures: none;
      tab-size: 4;

      &:hover,
      &:visited {
        color: rgb(51, 51, 51) !important;
      }

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  blockquote {
    position: relative;
    margin: 4px 0 0 0;
    padding: 0 0 0 16px;
    line-height: 22px;
    border: none;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 4px;
      background: #e3e4e6;
      content: "";
    }
  }

  a,
  .Channel {
    overflow-wrap: break-word;
    color: #08c;
    text-decoration: none;
    user-select: text;
    line-height: 22px;
    @include font-size(15);
    cursor: pointer;

    &:visited {
      color: #1264a3;
    }

    &:hover {
      color: #0b4c8c;
      text-decoration: underline;
    }
  }

  .User {
    background: #e8f5fa;
    color: #1264a3;
    padding: 0 2px 1px;
    border-radius: 3px;
    text-decoration: none;
    line-height: 22px;
    position: relative;
    @include font-size(15);
    cursor: pointer;

    &:hover {
      background: #ddf0f8;
      color: #0b4c8c;

      .UserNameTooltip {
        @include css3-prefix("transform", scale(1));
        opacity: 1;
      }
    }

    .UserNameTooltip {
      opacity: 0;
      @include css3-prefix("transform", scale(0));
      position: absolute;
      top: -44px;
      right: -50px;
      width: 160px;
      z-index: 2;
      font-weight: 500;
      height: 36px;
      text-align: center;
      color: $paragraphColor;
      background-color: rgba(0, 0, 0, 0.9);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      @include css3-prefix("box-shadow", "0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.03)");
      @include css3-prefix("transition", "all 0.1s ease-in");
      padding: 8px 8px;

      img {
        width: 24px;
        height: 24px;
        min-width: 24px;
        border-radius: 8px;
        margin-right: 4px;
      }

      span {
        @include text-truncate;
        width: 180px;
      }
    }
  }

  .Markdown--Br {
    display: block;
    height: 2px;
  }

  .Emoji {
    display: inline-block;
  }

  b {
    font-weight: 500;
  }

  .SlackCommand {
    background: #fcf5de;
    font-weight: 500;
    padding: 0 2px 1px;
    line-height: 22px;
    @include font-size(15);
    color: #1d1c1d;
    border-radius: 3px;
  }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $primaryBackgroundColor !important;
  border: 1px double $primaryBackgroundColor !important;
}

.DayPicker_weekHeader_li {
  user-select: none !important;
}

.SingleDatePickerInput {
  border: 0 !important;
  cursor: pointer;

  .DateInput {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f0ff;
    border: 1px solid $primaryBackgroundColor;
    margin: 0;
    cursor: pointer;
    width: auto;
    min-width: 100px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    .DateInput_input {
      padding: 0px 6px;
      height: 40px;
      cursor: pointer;
      font-size: 14px;
      background-color: transparent;
      font-weight: 500;
      border: 1px solid #5a44ff;
      color: #5a44ff;
      text-align: center;
      border: 0;
    }
  }

  .DateInput_fang {
    bottom: 43px !important;
  }

  .SingleDatePicker_picker {
    @include css3-prefix("box-shadow", "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0, 0, 0, 0)");
    bottom: 54px !important;
  }
}

.select__control {
  cursor: pointer !important;
}

.select__indicator-separator {
  display: none;
}

.select__dropdown-indicator {
  padding: 4px 6px 4px 4px !important;
}

.select__value-container {
  padding: 2px 4px !important;
}

.select__indicator {
  path {
    fill: #5a44ff69 !important;
  }
}

.select__multi-value__label {
  color: hsl(0, 0%, 20%);
  margin-left: 2px;
  margin-right: 2px;

  .AppTag {
    background: #f1f1f1;
    color: #908f8f;
    border-radius: 4px;
    font-size: 10px;
    padding: 2px 4px;
    margin-left: 4px;
    font-weight: 500;
  }

  .CustomSelectValue__Avatar {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 8px;
    margin: 0 6px 0 2px;
  }

  .Message__Avatar--Channel {
    width: 24px;
    height: 24px;
    display: flex;
    min-width: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    @include font-size(22);
    margin: 0 6px 0 2px;
    color: $primaryForeColor;
    background: $headingColor;
  }

  .CustomSelectValue__Text {
    color: hsl(0, 0%, 20%);
    @include text-truncate;
    min-width: 0;
  }
}

.CustomSelectValue {
  color: hsl(0, 0%, 20%);
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .AppTag {
    background: #f1f1f1;
    color: #908f8f;
    border-radius: 4px;
    font-size: 10px;
    padding: 2px 4px;
    margin-left: 4px;
    font-weight: 500;
  }

  .CustomSelectValue__Avatar {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 8px;
    margin: 0 6px 0 2px;
  }

  .Message__Avatar--Channel {
    width: 24px;
    height: 24px;
    display: flex;
    min-width: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    @include font-size(22);
    margin: 0 6px 0 2px;
    color: $primaryForeColor;
    background: $headingColor;
  }

  .CustomSelectValue__Text {
    color: hsl(0, 0%, 20%);
    @include text-truncate;
    min-width: 0;
  }
}

.To_Whom {
  .select__option,
  .select__multi-value__label {
    display: flex !important;
    align-items: center !important;
    padding: 4px 6px !important;

    .CustomSelectValue__Avatar {
      width: 24px;
      height: 24px;
      min-width: 24px;
      border-radius: 8px;
      margin: 0 6px 0 2px;
    }

    .AppTag {
      background: #f1f1f1;
      color: #908f8f;
      border-radius: 4px;
      font-size: 10px;
      padding: 2px 4px;
      margin-left: 4px;
      font-weight: 500;
    }

    .Message__Avatar--Channel {
      width: 24px;
      height: 24px;
      display: flex;
      min-width: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      @include font-size(22);
      margin: 0 6px 0 2px;
      color: $primaryForeColor;
      background: $headingColor;
    }

    .CustomSelectValue__Text {
      color: hsl(0, 0%, 20%);
      @include text-truncate;
      min-width: 0;
    }
  }
  .select__option--is-selected {
    .CustomSelectValue__Text {
      color: white !important;
    }
    .AppTag {
      background: #d5d2ff !important;
      color: #696969;
    }
  }
}

.MultiSelect {
  .select__multi-value {
    background: #f8f8f8;
    border-radius: 6px;
    border: 1px solid #e4e4e4;
    .CustomSelectValue__Text {
      @include font-size(14);
    }
  }
  .select__multi-value__remove {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.SelectLeftBorder {
  border: none;
  &:hover {
    .select__control {
      border: 1px solid #5a44ff;
      border-radius: 4px;
    }
  }
  .select__control {
    border: 1px solid #5a44ff;
    border-radius: 4px;
  }
}
