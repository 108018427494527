$base-font-size: 62.5%;
$font-OS: "Karla", sans-serif;

$page-width: 584px;
$page-top-padding: 110px;
$page-background: #fff;
$spinner: #5a44ff;

$headingColor: #121212;
$paragraphColor: #444;

$primaryBackgroundColor: #5a44ff;
$primaryForeColor: #fff;

$secondaryBackgroundColor: #fff;
$secondaryForeColor: #444;

$errorColor: #ed143d;
$red: #ea384d;
$errorBackgroundColor: #ff81280d;
$light-red: #fff1f1;
$light-gray: #e4e4e4;
$light-white: #fdfdfd;
$lighter-gray: #fdfdfd;
$separator-gray: #efe8e8;
$light-orange: #f9ebd1;
$orangeColor: #fda73f;
$darkOrange: #fda73f;
$darkerOrange: #e49637;
$hover-gray: #929292;
$popover-gray: #888;
$proColor: rgba(255, 214, 68, 0.9);

$light-green: #3ae2a6;
$lighter-green: #eefff9;
$dark-green: #3be2a7;
$darker-green: #34bf8e;
$light-blue: #f1f0ff;
$darklight-blue: #ebe9ff;
$lighter-red: #ffb3c1;
$message-notice-background: #f9f9f9;
