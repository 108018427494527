@media all and (max-width: 490px) {
    .Header {

        .Header__Controls {

            .NewMessage {
                width: 48px;
                color: transparent;
                min-width: 48px;
            }

            .Profile_Details--Name {
                font-size: 14px !important;
            }

            .Profile_Details--Plan {
                font-size: 12px !important;
            }

        }

    }
}

@media all and (max-width: 416px) {
    .Navigation {
        text-align: center;
        a {
          font-size: 14px !important;
          margin: 0 6px;
          &:first-child{
            margin: 0 6px;
          }
        }
    }
}

@media all and (max-width: 370px) {
  .Navigation {
      text-align: center;
      a {
        font-size: 14px !important;
        // margin: 0 10px !important;
        &:first-child{
          margin: 0 6px 0 0 !important;
        }
        &:last-child{
          // margin: 0 0 0 6px !important;
        }
      }
  }
}