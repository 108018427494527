.Button {
    display: inline-block;
    padding: 8px 24px;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    text-decoration: none;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 0;
    min-width: 100px;
    @include border-radius(4px);
    @include css3-prefix('user-select', 'none');
    @include css3-prefix('transition', 'background-color .15s,box-shadow .15s,color .15s,opacity .15s');
    @include text-truncate;
    @include font-size(16);
}

.Button:disabled{
    cursor: not-allowed;
    opacity: .75;
    pointer-events: none;
}

button:disabled {
  cursor: not-allowed;
  opacity: .6;
  pointer-events: none;
}

.Button--Primary {
    background-color: $primaryBackgroundColor;
    color: $primaryForeColor;

    &:hover,
    &:focus {
        @include css3-prefix('box-shadow', 'inset 0 -6.25rem 0 hsla(0,0%,100%,.1)');
    }
    .ButtonLoader{
      border: 2px solid $light-blue;
      border-right-color: transparent;
    }
}

.Button--Secondary {
  
    background-color: $secondaryBackgroundColor;
    color: $secondaryForeColor;
    border: 1px solid $light-gray;

    &:hover,
    &:focus {
        @include css3-prefix('box-shadow', 'inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(234, 234, 234, 0.32)');
    }
}

.Button--Dangerous {
  
  background-color: $secondaryBackgroundColor;
  color: $errorColor;
  border: 1px solid $errorColor;

  &:hover,
  &:focus {
      @include css3-prefix('box-shadow', 'inset 0 0 0 0.0625rem rgba(255, 255, 255, 0.2), inset 0 -6.25rem 0 rgba(255, 179, 193, 0.32)');
  }

  .ButtonLoader{
    border: 2px solid $errorColor !important;
    border-right-color: transparent !important;
  }

}

.Group__Button{
  background-color: transparent;
  font-weight: 500;
  border: 1px solid $light-gray;   
  color: $hover-gray;   
  @include font-size(14);
  padding: 10px 12px;

  &:hover, &:focus{
    border: 1px solid $primaryBackgroundColor;   
    color: $primaryBackgroundColor;   
  } 

}

.Loader__Wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.Group__Button.Selected{
  background-color: $light-blue;
  font-weight: 500;
  border: 1px solid $primaryBackgroundColor;   
  color: $primaryBackgroundColor;   
}

.Button--Light{
  font-size: 15px;
  font-weight: normal;
}

.ButtonLoader {
  @include css3-prefix('animation-duration', 1.2s);
  @include css3-prefix('animation-iteration-count', infinite);
  @include css3-prefix('animation-name', rotate-forever);
  @include css3-prefix('animation-timing-function', linear);
  height: 16px;
  width: 16px;
  margin-right: 6px;
  border: 2px solid $spinner;
  border-right-color: transparent;
  border-radius: 100%;
}

